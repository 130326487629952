import React, { useEffect, useState } from 'react';
import {
  CheckOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Form, Row, Select } from 'antd';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import {
  addBulkInvestors,
  investorErrors,
  getBulkInvestorTemplate,
} from '../../redux/actions/Investors';
const { Panel } = Collapse;

export const AddBulkInvestors = (props) => {
  const { error, responseMessage } = props;
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    if (responseMessage !== '' && responseMessage !== false) {
      props.history.push('/home/investors');
    }
  }, [responseMessage]);

  const addBulkInvestors = () => {
    props.addBulkInvestors(selectedOption,file);
  };

  const options = [
    { value: 'RETAIL', label: 'Retail Investor' },
    { value: 'INSTITUTIONAL', label: 'Institutional Investor' },
  ];


  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const getBulkInvestorsTemplate = () => {
    props.getBulkInvestorTemplate(selectedOption);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {error && error !== '' && error.length !== 0 ? (
          <Alert type="error" closable message={error}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <div className="app-area">
        <Form>
          <StyledToolbar
            module="investors"
            mode="bulk"
            onSubmit={() => {
              if (file) {
                addBulkInvestors(file, selectedOption);
              }
            }}
          />
          <Collapse
            expandIconPosition="end"
            defaultActiveKey={[1]}
            collapsible="disabled"
          >
            <Panel key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Select Investor Type:" name="investor_type">
                    <Select
                      options={options}
                      value={selectedOption}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Download .XLSX Template File for the Bulk Adding Investors"
                    name="document_attachment"
                  >
                    <Button
                      type="primary"
                      className="btn text-white bg-darkblue fw-12 fw-6"
                      onClick={getBulkInvestorsTemplate}
                      disabled={selectedOption === null}
                    >
                      <DownloadOutlined />
                      Download File
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Select File" name="document_attachment">
                    <input
                      type="file"
                      name="file"
                      accept=".xlsx"
                      onChange={(val) => setFile(val.target.files[0])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
            <Button
              className="btn-cancel mr-2"
              onClick={() => {
                props.history.push('/home/investors');
              }}
            >
              <CloseCircleOutlined />
              Cancel
            </Button>
            <Button
              className="btn-save"
              onClick={addBulkInvestors}
              disabled={!file || selectedOption === null}
            >
              <CheckOutlined />
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, responseMessage } = state.investors;
  return { error, responseMessage };
};

const mapDispatchToProps = {
  addBulkInvestors,
  investorErrors,
  getBulkInvestorTemplate,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkInvestors);
