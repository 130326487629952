import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { useHistory } from 'react-router-dom';
import {
  GET_LEAD_DATA,
  GET_OPEN_TASKS_DATA,
  GET_TOP_EMPLOYEE_EMAIL_DATA,
  GET_LEADS_BY_SOURCE_DATA,
  GET_NUM_CLOSED_DEALS,
  GET_DEAL_DEACTIVATE_REASON_DATA,
  GET_AVG_DEAL_CLOSING_RATE,
  GET_DEALS_WON_DATA,
  GET_AVG_REVENUE_DATA,
  GET_NUM_INVESTORS_DATA,
} from '../constants/CrmDashboard';

import { service } from '../../service';

import {
  setLeadData,
  setOpenTasksData,
  setTopEmployeeEmailData,
  setLeadsBySourceData,
  setNumClosedDeals,
  setDealDeactivateReasonData,
  setAvgDealClosingRateData,
  setDealWonData,
  setAvgRevenueData,
  setNumInvestorsData,
} from '../actions/CrmDashboard';

export function* getLeadData() {
  yield takeEvery(GET_LEAD_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setLeadData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getOpenTasksData() {
  yield takeEvery(GET_OPEN_TASKS_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setOpenTasksData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getLeadsBySourceData() {
  yield takeEvery(GET_LEADS_BY_SOURCE_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setLeadsBySourceData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getNumClosedDeals() {
  yield takeEvery(GET_NUM_CLOSED_DEALS, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setNumClosedDeals({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getTopEmployeeEmailData() {
  yield takeEvery(GET_TOP_EMPLOYEE_EMAIL_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setTopEmployeeEmailData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getDealDeactivateReasonData() {
  yield takeEvery(GET_DEAL_DEACTIVATE_REASON_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(
          setDealDeactivateReasonData({ url: url, data: response.data })
        );
      }
    } catch (error) {}
  });
}

export function* getDealWonDataSaga() {
  yield takeEvery(GET_DEALS_WON_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setDealWonData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getAvgRevenueData() {
  yield takeEvery(GET_AVG_REVENUE_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setAvgRevenueData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getNumInvestorsData() {
  yield takeEvery(GET_NUM_INVESTORS_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setNumInvestorsData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* getAvgDealClosingRateData() {
  yield takeEvery(GET_AVG_DEAL_CLOSING_RATE, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setAvgDealClosingRateData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(getLeadData),
    fork(getOpenTasksData),
    fork(getLeadsBySourceData),
    fork(getNumClosedDeals),
    fork(getTopEmployeeEmailData),
    fork(getDealDeactivateReasonData),
    fork(getAvgDealClosingRateData),
    fork(getDealWonDataSaga),
    fork(getAvgRevenueData),
    fork(getNumInvestorsData),
  ]);
}
