import {
  ADD_CAMPAIGN, CAMPAIGN_DETAILS, DELETE_CAMPAIGN, GET_CAMPAIGNS, LOADING, REFRESH, RESPONSE_MESSAGE, SET_CAMPAIGNS_DATA, UPDATE_CAMPAIGN_DETAILS, UPDATE_ERRORS
} from '../constants/Campaigns';

export const getCampaigns = (url, offset, bool) => {
  return {
    type: GET_CAMPAIGNS,
    payload: {
      url,
      offset,
      bool,
    },
  };
};

export const setCampaignsData = (data) => {
  return {
    type: SET_CAMPAIGNS_DATA,
    payload: data,
  };
};

export const loading = (bool) => {
  return {
    type: LOADING,
    payload: bool,
  };
};

export const addCampaign = (data) => {
  return {
    type: ADD_CAMPAIGN,
    payload: data,
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const updateErrors = (error) => {
  return {
    type: UPDATE_ERRORS,
    payload: error,
  };
};

export const deleteCampaign = (id, bool) => {
  return {
    type: DELETE_CAMPAIGN,
    payload: {
      id,
      bool,
    },
  };
};

export const refresh = (bool) => {
  return {
    type: REFRESH,
    payload: bool,
  };
};

export const getCampaignDetails = (url) => {
  return {
    type: CAMPAIGN_DETAILS,
    url,
  };
};

export const updateCampaignDetails = (data) => {
  return {
    type: UPDATE_CAMPAIGN_DETAILS,
    data,
  };
};

