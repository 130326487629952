import {
  ADD_CAMPAIGN_TEMPLATE_SERVER,
  ADD_TEMPLATE,
  DELETE_TEMPLATE,
  GET_TEMPLATE_SERVER,
  SET_CAMPAIGN_TEMPLATES_STORE,
  UPDATE_CAMPAIGN_TEMPLATE_SERVER,
  UPDATE_TEMPLATE,
} from '../constants/EmailTemplates';

export const addTemplateAction = (data) => {
  return {
    type: ADD_TEMPLATE,
    payload: data,
  };
};

export const updateTemplateAction = (data) => {
  return {
    type: UPDATE_TEMPLATE,
    payload: data,
  };
};

export const deleteTemplateAction = (data, history) => {
  return {
    type: DELETE_TEMPLATE,
    payload: data,
    history: history,
  };
};

export const getTemplatesServerAction = (data) => {
  return {
    type: GET_TEMPLATE_SERVER,
    payload: data,
  };
};

export const setCampaignTemplatesAction = (data) => {
  return {
    type: SET_CAMPAIGN_TEMPLATES_STORE,
    payload: data,
  };
};

export const addCampaignTemplateServerAction = (data, history) => {
  return {
    type: ADD_CAMPAIGN_TEMPLATE_SERVER,
    payload: data,
    history: history,
  };
};

export const updateCampaignTemplateAction = (data, history) => {
  return {
    type: UPDATE_CAMPAIGN_TEMPLATE_SERVER,
    payload: data,
    history: history,
  };
};
