import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { connect } from 'react-redux';
import { getAutoSearchData } from '../../../redux/actions/Common';

const AutoSearch = (props) => {
  const {
    apiUrl,
    apiParam,
    common,
    keyId,
    onValueChange,
    placeholder = '',
    labelIds = [],
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (!!apiUrl && !!apiParam) {
      const param = apiParam + '=';
      props.getAutoSearchData(apiUrl, param);
    }
  }, [apiUrl, apiParam]);

  useEffect(() => {
    const transformElements = (elements) => {
      return elements.map((ele) => {
        let label = '';
        labelIds.forEach((id) => {
          label += ele[id] + ' ';
        });
        label = label.trim();
        return {
          label: `${label}`,
          value: ele[keyId],
          key: ele[keyId],
          obj: ele,
        };
      });
    };

    if (!!common && !!common.autosearch && !!common.autosearch[apiUrl]) {
      setOptions(transformElements(common.autosearch[apiUrl]));
    }
  }, [common, apiUrl]);

  const onChange = (data) => {
    if (onValueChange) {
      onValueChange(data);
    }
  };

  return (
    <>
      <Select
        size="large"
        placeholder={placeholder ? placeholder : ''}
        mode="multiple"
        onChange={onChange}
        options={options}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {
  getAutoSearchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(AutoSearch);
