export const GET_CONTACTS = 'GET_CONTACTS';
export const SET_CONTACTS = 'SET_CONTACTS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const ADD_BULK_CONTACT = 'ADD_BULK_CONTACT';

export const CONTACT_ERRORS = 'CONTACT_ERRORS';
export const REFRESH = 'REFRESH';
export const LOADING = 'LOADING';
export const RESPONSE_MESSAGE = 'RESPONSE_MESSAGE';
export const CONTACT_DETAILS = 'CONTACT_DETAILS';
export const UPDATE_CONTACT_DETAILS = 'UPDATE_CONTACT_DETAILS';
export const CONTACT_UPDATED = 'CONTACT_UPDATED';

export const GET_SEARCH_CONTACTS = 'GET_SEARCH_CONTACTS';
export const SET_SEARCH_CONTACTS = 'SET_SEARCH_CONTACTS';

export const GET_CONTACT_HISTORY_DETAILS = 'GET_CONTACT_HISTORY_DETAILS';
export const SET_CONTACT_HISTORY_DETAILS = 'SET_CONTACT_HISTORY_DETAILS';
