import {
  GET_CONTACTS,
  ADD_CONTACT,
  ADD_BULK_CONTACT,
  CONTACT_ERRORS,
  REFRESH,
  LOADING,
  RESPONSE_MESSAGE,
  SET_CONTACTS,
  CONTACT_DETAILS,
  UPDATE_CONTACT_DETAILS,
  CONTACT_UPDATED,
  GET_SEARCH_CONTACTS,
  SET_SEARCH_CONTACTS,
  GET_CONTACT_HISTORY_DETAILS,
  SET_CONTACT_HISTORY_DETAILS
} from '../constants/Contacts';

export const getContacts = (url, offset, bool, id) => {
  return {
    type: GET_CONTACTS,
    payload: {
      url,
      offset,
      bool,
      id,
    },
  };
};

export const getSearchContacts = (payload) => {
  return {
    type: GET_SEARCH_CONTACTS,
    payload: payload,
  };
};

export const addContact = (url, data) => {
  return {
    type: ADD_CONTACT,
    payload: {
      url,
      data,
    },
  };
};

export const addBulkContact = (url, data) => {
  return {
    type: ADD_BULK_CONTACT,
    payload: {
      url,
      data,
    },
  };
};

export const setContacts = (data) => {
  return {
    type: SET_CONTACTS,
    payload: data,
  };
};

export const setSearchContacts = (data) => {
  return {
    type: SET_SEARCH_CONTACTS,
    payload: data,
  };
};

export const contactErrors = (error) => {
  return {
    type: CONTACT_ERRORS,
    payload: {
      error,
    },
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const loading = (bool) => {
  return {
    type: LOADING,
    payload: bool,
  };
};

export const refresh = (bool) => {
  return {
    type: REFRESH,
    payload: bool,
  };
};

export const getContactDetails = (url) => {
  return {
    type: CONTACT_DETAILS,
    url,
  };
};

export const updateContactDetails = (data) => {
  return {
    type: UPDATE_CONTACT_DETAILS,
    data,
  };
};

export const isContactUpdated = (val) => {
  return {
    type: CONTACT_UPDATED,
    val,
  };
};


export const getContactHistoryDetails = (id) => {
  return {
    type: GET_CONTACT_HISTORY_DETAILS,
    id,
  };
}


export const setContactHistoryDetails = (data) => {
  return {
    type: SET_CONTACT_HISTORY_DETAILS,
    data,
  };
};