import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Radio, Tabs } from 'antd';
import 'antd/dist/antd.css';
import '../contacts/style.css';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { getAccountDetails } from '../../redux/actions/Accounts';

export const AccountDetails = (props) => {
  const { TextArea } = Input;
  const { accountDetails, loading } = props;

  const [edit, setEdit] = useState(true);
  const [details, setDetails] = useState('');

  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [status, setStatus] = useState('');
  const [phone, setPhone] = useState('');
  const [orgName, setOrgName] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');

  //address
  const [addressLine, setAddressLine] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [addrCountry, setAddrCountry] = useState('');
  const id = props.match.params.id;

  useEffect(() => {
    props.getAccountDetails(`/api/accounts/${id}/`);
  }, []);

  useEffect(() => {
    setDetails(accountDetails);
  }, [loading]);

  useEffect(() => {
    // account details
    setName(accountDetails?.name);
    setStatus(accountDetails?.status);
    setPhone(accountDetails?.phone);
    setOrgName(accountDetails?.organization);
    setEmail(accountDetails?.email);
    setDescription(accountDetails?.description);
    // billing address details
    setAddressLine(accountDetails?.billing_address_line);
    setStreet(accountDetails?.billing_street);
    setState(accountDetails?.billing_state);
    setPostalCode(accountDetails?.billing_postcode);
    setAddrCountry(accountDetails?.billing_country);
    setCity(accountDetails?.billing_city);
    setWebsite(accountDetails?.website);
  }, [accountDetails]);

  return (
    <>
      <div className="overall-Cont">
        <div>
          <Button
            type="primary"
            className="edit-button"
            onClick={(edit) => setEdit(!edit)}
          >
            Edit
          </Button>
        </div>
        <Tabs>
          <Tabs.TabPane tab="Details" key="details">
            <div className="other-data">
              <Form
                layout="vertical"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Item label="Account Name:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Account Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Website:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Website"
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Phone:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Email:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Organization:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Organization"
                    value={orgName}
                    onChange={(e) => setOrgName(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Status:">
                  <Radio.Group value={status}>
                    <Radio value="open"> Open </Radio>
                    <Radio value="close"> Close </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Description:" style={{ width: '49%' }}>
                  <TextArea
                    rows={4}
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Form>
            </div>

            <div className="address-cont">
              <div className="user-data">
                <Form
                  layout="vertical"
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Item
                    label="Billing Address Line"
                    style={{ width: '49%' }}
                  >
                    <Input
                      placeholder="Address Line"
                      value={addressLine}
                      onChange={(e) => setAddressLine(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Billing Street:" style={{ width: '49%' }}>
                    <Input
                      placeholder="Street"
                      value={street}
                      onChange={(e) => setStreet(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Billing City:" style={{ width: '49%' }}>
                    <Input
                      placeholder="City"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Billing State:" style={{ width: '49%' }}>
                    <Input
                      placeholder="State"
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                    {/* <Select options={options} value={value} onChange={changeHandler} disabled={edit ? "disabled" : ""}/> */}
                  </Form.Item>
                  <Form.Item
                    label="Billing Postal Code:"
                    style={{ width: '49%' }}
                  >
                    <Input
                      placeholder="Postal Code"
                      type="number"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                  </Form.Item>
                  <Form.Item label="Billing Country:" style={{ width: '49%' }}>
                    <Input
                      placeholder="Country"
                      value={addrCountry}
                      onChange={(e) => setAddrCountry(e.target.value)}
                      style={{ marginBottom: 10 }}
                      disabled={edit ? 'disabled' : ''}
                      suffix={
                        <EditOutlined
                          style={{
                            color: 'rgba(0,0,0,.45)',
                          }}
                        />
                      }
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
            {!edit ? (
              <div className="save-cancel">
                <Button type="danger" onClick={(edit) => setEdit(edit)}>
                  Cancel
                </Button>
                <Button style={{ marginLeft: '20px' }} type="primary">
                  Submit
                </Button>
              </div>
            ) : (
              ' '
            )}
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { loading, accountDetails } = state.accounts;
  return { loading, accountDetails };
};

const mapDispatchToProps = {
  getAccountDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountDetails);
