import {
  GET_DOCUMENTS,
  ADD_DOCUMENT,
  DOCUMENT_ERRORS,
  REFRESH,
  LOADING,
  RESPONSE_MESSAGE,
  SET_DOCUMENTS,
} from '../constants/Documents';

export const getDocuments = (url, offset, bool) => {
  return {
    type: GET_DOCUMENTS,
    payload: {
      url,
      offset,
      bool,
    },
  };
};

export const addDocument = (data) => {
  return {
    type: ADD_DOCUMENT,
    payload: data,
  };
};



export const setDocuments = (data) => {
  return {
    type: SET_DOCUMENTS,
    payload: data,
  };
};

export const documentErrors = (error) => {
  return {
    type: DOCUMENT_ERRORS,
    payload: {
      error,
    },
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const loading = (bool) => {
  return {
    type: LOADING,
    payload: bool,
  };
};

export const refresh = (bool) => {
  return {
    type: REFRESH,
    payload: bool,
  };
};