import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Table } from 'antd';
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RowCounter from '../../components/ui-components/pagination/rowcounter/RowCounter';
import {
  getContacts,
  loading,
  responseMessage,
} from '../../redux/actions/Contacts';
import '../leads/leads.css';

export const ContactsList = (props) => {
  const { contacts } = props;

  const [rows, setRows] = useState(10);

  const [pages, setPages] = useState(0);

  useEffect(() => {
    props.getContacts(`/api/contacts/?limit=${rows}`, 0);
    props.loading();
  }, [rows]);

  const routeToDetails = (id) => {
    props.history.push(`/home/contacts/${id}/details`);
  };

  const routeToHistory = (id)=>{
    props.history.push(`/home/contacts/${id}/history/`);
  }

  useEffect(() => {
    setPages(contacts.contact_obj_list && contacts.contacts_count);
  });

  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (contacts) => {
        return (
          <p>
            <span className="">{contacts}</span>
          </p>
        );
      },
    },
    {
      title: 'Salutation',
      dataIndex: 'salutation',
      key: 'salutation',
      render: (salutation) => <p>{salutation}</p>,
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      render: (first_name) => <p>{first_name}</p>,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      render: (last_name) => <p>{last_name}</p>,
    },
    {
      title: 'Primary Email',
      dataIndex: 'primary_email',
      key: 'primary_email',
      render: (primary_email) => <p>{primary_email}</p>,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (row) => (
        <>
          <span className="edit" style={{ cursor: 'pointer' }}>
            <EditOutlined onClick={() => routeToDetails(row.id)}/>
          </span>
          <span
            className="delete"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
          >
            <DeleteOutlined />
          </span>

          <span
            className="history"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => routeToHistory(row.id)}
          >
            <HistoryOutlined />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="main-toolbar">
        <div className="main-toolbar-functions-wrapper">
          <Button type="primary" className="btn text-white fw-12 fw-6">
            <PlusOutlined />
            <span onClick={() => props.history.push('/home/contacts/new')}>
              {' '}
              Add Contacts{' '}
            </span>
          </Button>

          <Button type="primary" className="btn text-white fw-12 fw-6">
            <PlusOutlined />
            <span onClick={() => props.history.push('/home/contacts/bulk')}>
              {' '}
              Add Bulk Contacts{' '}
            </span>
          </Button>
          <RowCounter updateRecordsPerPage={updateRecordsPerPage} />
        </div>
      </div>
      <Table
        rowKey={(record) => record.id}
        columns={columns}
        dataSource={contacts.contact_obj_list}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, contacts, offset, refresh } = state.contacts;
  return { isLoading, contacts, offset, refresh };
};

const mapDispatchToProps = {
  getContacts,
  loading,
  responseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);
