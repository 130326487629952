import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { useHistory } from 'react-router-dom';
import {
  GET_AUTOSEARCH_DATA,
  POSTDATA_WITHHEADER,
  GET_COMMENTS_HISTORY,
} from '../constants/Common';

import { service } from '../../service';

import { setAutoSearchData } from '../actions/Common';
import { setDealCommentHistoryDetails } from '../actions/Deals'
export function* getAutoSearchData() {
  yield takeEvery(GET_AUTOSEARCH_DATA, function* ({ payload }) {
    const { url, param } = payload;
    try {
      const response = yield call(service.get, `${url}?${param}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setAutoSearchData({ url: url, data: response.data }));
      }
    } catch (error) {}
  });
}

export function* postDataWithHeader() {
  yield takeEvery(POSTDATA_WITHHEADER, function* ({ payload }) {
    const { url, data, redirectUrl } = payload;
    try {
      const response = yield call(service.post, url, data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        const history = useHistory();
        yield put(history.push(redirectUrl));
      }
    } catch (error) {}
  });
}

export function* getCommentsHistory() {
  yield takeEvery(GET_COMMENTS_HISTORY, function* ({ payload }) {
    const { modules, id } = payload;
    try {
      const response = yield call(
        service.get,
        `/api/${modules}/${id}/comments/`,
        {
          headers: {
            Authorization: `jwt ${localStorage.getItem('Token')}`,
            org: localStorage.getItem('company'),
          },
        }
      );
      if (response.status === 200 || response.status === 201) {
        if(modules === 'deals')
        {yield put(setDealCommentHistoryDetails(response.data.data));}
      }
    } catch (error) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAutoSearchData),
    fork(postDataWithHeader),
    fork(getCommentsHistory),
  ]);
}
