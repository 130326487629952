import {
  ADD_INVESTOR,
  INVESTOR_ERRORS,
  GET_INVESTORS_LIST,
  IS_LOADING,
  SET_INVESTORS_LIST,
  RESPONSE_MESSAGE,
  UPDATE_ERRORS,
  GET_BULK_INVESTOR_TEMPLATE,
  DELETE_INVESTOR,
  ADD_BULK_INVESTORS,
  GET_INVESTOR_DETAILS,
  SET_INVESTOR_DETAILS,
  UPDATE_INVESTOR_DETAILS
} from '../constants/Investors.js';

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const investorErrors = (error) => {
  return {
    type: INVESTOR_ERRORS,
    data: error,
  };
};

export const getInvestorsList = (limit, offset, qParam) => {
  return {
    type: GET_INVESTORS_LIST,
    payload: {
      limit,
      offset,
      qParam
    },
  };
};

export const setInvestorsList = (data) => {
  return {
    type: SET_INVESTORS_LIST,
    data,
  };
};

export const isLoading = () => {
  return {
    type: IS_LOADING,
  };
};

export const addInvestor = (data) => {
  return {
    type: ADD_INVESTOR,
    data,
  };
};


export const updateErrors = (error) => {
  return {
    type: UPDATE_ERRORS,
    payload: error,
  };
};


export const getBulkInvestorTemplate = (investor_type) => {
  return {
    type: GET_BULK_INVESTOR_TEMPLATE,
    investor_type
  }
}

export const deleteInvestor = (id)=>{
  return {
    type : DELETE_INVESTOR,
    id
  }
}

export const addBulkInvestors = (investor_type, file) => {
  console.log({ action:'action',file, investor_type })
  return {
    type: ADD_BULK_INVESTORS,
    payload: {
      investor_type,
      file,
    },
  };
};

export const getInvestorDetails = (id) => {
  return {
    type: GET_INVESTOR_DETAILS,
    id,
  };
};

export const setInvestorDetails = (data) => {
  return {
    type: SET_INVESTOR_DETAILS,
    data,
  };
};

export const updateInvestorDetails = (data) => {
  return {
    type: UPDATE_INVESTOR_DETAILS,
    data,
  };
};
