import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Modal, Row } from 'antd';
import JoditEditor from 'jodit-react';
import { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import AutoSearch from '../../components/ui-components/autosearch/AutoSearch';
import { getContacts } from '../../redux/actions/Contacts';
import { getLeads } from '../../redux/actions/Leads';
import { postDataWithHeader } from '../../redux/actions/Common';
import { getTemplatesServerAction } from '../../redux/actions/EmailTemplates';
import TemplateGrid from '../templates/TemplateGrid';
import { useHistory } from 'react-router-dom';

const { Panel } = Collapse;

export const ComposeMail = (props) => {
  useEffect(() => {
    props.getTemplatesServerAction();
  }, []);

  const [form] = Form.useForm();

  const [templateId, setTemplateId] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const history = useHistory();
  const campaigns = useSelector((app) => app.campaigns);
  const templates = campaigns.templates;
  const currentTemplate = templates.find((t) => t.id === templateId);
  const onChangeEditor = (e) => {
    form.setFieldsValue({
      message_body: e,
    });
  };

  const contact_recipients = useRef([]);
  const lead_recipients = useRef([]);

  const sendMail = (emailData) => {
    emailData.recipients = [
      ...(contact_recipients.current || []),
      ...(lead_recipients.current || []),
    ];
    props.postDataWithHeader(`api/compose`, emailData, `/home`);
    history.push(`/home`)
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModel = () => {
    setIsModalOpen(false);
  };

  const onSelectCard = (template) => {
    setTemplateId(template.id);
    form.setFieldsValue({
      email_content: template.content,
    });
    setIsModalOpen(false);
  };

  return (
    <>
      <Form
        form={form}
        onFinish={sendMail}
        style={{ background: '#fff', padding: '20px' }}
      >
        <Collapse expandIconPosition="end" defaultActiveKey={['11']}>
          <Panel header="Compose Email" key="11">
            <Row justify="start">
              <Form.Item label="Email Template">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <Input
                    placeholder=""
                    disabled
                    value={currentTemplate ? currentTemplate.title : ''}
                  />
                  <Button
                    onClick={() => showModal()}
                    style={{
                      marginLeft: '4px',
                    }}
                  >
                    <span>Select Template</span>
                  </Button>
                </div>
              </Form.Item>
            </Row>
            <Row justify="start">
              <Col span={12}>
                <Form.Item label="Recipients Contacts" name="recipients">
                  <AutoSearch
                    placeholder="Search Contacts"
                    apiUrl="/api/contacts/search"
                    apiParam="flname"
                    keyId="primary_email"
                    labelIds={['first_name', 'last_name']}
                    onValueChange={(val) => {
                      contact_recipients.current = val;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={12}>
                <Form.Item label="Recipients Leads" name="recipients">
                  <AutoSearch
                    placeholder="Search Leads"
                    apiUrl="/api/leads/search"
                    apiParam="flname"
                    keyId="email"
                    labelIds={['first_name', 'last_name']}
                    onValueChange={(val) => {
                      lead_recipients.current = val;
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="start">
              <Col span={12}>
                <Form.Item label="Email Subject" name="message_subject">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <Collapse
          defaultActiveKey={['22']}
          expandIconPosition="end"
        >
          <Panel header="Email Content" key="22" style={{ width: '100%' }}>
            <Form.Item name="message_body">
              <div style={{ width: '100%' }}>
                <JoditEditor
                  width={1000}
                  style={{ width: '100%' }}
                  className="joeditor"
                  onChange={onChangeEditor}
                  value={currentTemplate ? currentTemplate.content : ''}
                />
              </div>
            </Form.Item>
          </Panel>
        </Collapse>
        <Row
          justify="center"
          style={{
            marginTop: '20px',
          }}
        >
          <Button
            type="primary"
            className="btn"
            danger
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <CloseCircleOutlined />
            <span>Close</span>
          </Button>

          <Button type="primary" className="btn" htmlType="submit">
            <CheckOutlined />
            <span>Send</span>
          </Button>
        </Row>
      </Form>
      <Modal
        title="Select Email Template"
        open={isModalOpen}
        onCancel={hideModel}
        width={1000}
        height={500}
      >
        <div style={{ height: '100%', overflow: 'scroll' }}>
          <TemplateGrid data={templates} onClickCard={onSelectCard} />
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = {
  postDataWithHeader,
  getContacts,
  getTemplatesServerAction,
  getLeads,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComposeMail);
