import { connect } from 'react-redux';

import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import { layout } from '../../common/layout';
import { useEffect } from 'react';

import { rules } from '../../common/rules';
export const UpdateInstitutionalInvestor = (props) => {
  const { form, investor, updateInvestor } = props;
  useEffect(() => {
    form.setFieldsValue(investor);
  }, [investor]);

  function formatValues(values) {
    updateInvestor(values)
  }

  return (
    <div>
      <Form
        form={form}
        {...layout}
        onFinish={formatValues}
        style={{ background: '#fff', padding: '20px' }}
      >
        <div>
          <Row justify="start">
            <Col xs={24} sm={24} md={24} lg={12} xl={11}>
              <Form.Item label="Investor ID" name="pbid" rules={rules.required}>
                <Input />
              </Form.Item>
              <Form.Item label="Investor First Name" name="investor_first_name">
                <Input />
              </Form.Item>
              <Form.Item
                label=" Contact Email"
                name="primary_contact_email"
                rules={rules.emailOptional}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Contact Phone"
                name="primary_contact_phone"
                rules={rules.contactNumberOptional}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Investment Company"
                name="last_investment_company"
              >
                <Input />
              </Form.Item>
              <Form.Item label="Location" name="hq_location">
                <Input />
              </Form.Item>
              <Form.Item label="Address" name="hq_address_line_1">
                <Input />
              </Form.Item>
              <Form.Item label="Dry Powder" name="dry_powder" rules={rules.numberOptional}>
                <Input />
              </Form.Item>
              <Form.Item label="Notes & Comments" name="note">
                <Input />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
              <Form.Item
                label="Investor Name"
                name="investor"
                rules={rules.name}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Investor Last Name" name="investor_last_name">
                <Input />
              </Form.Item>
              <Form.Item
                label="Contact Email 2"
                name="primary_contact_email2"
                rules={rules.emailOptional}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Website" name="website">
                <Input />
              </Form.Item>
              <Form.Item label="Sector focus" name="sector_focus">
                <Input />
              </Form.Item>
              <Form.Item label="City" name="hq_city">
                <Input />
              </Form.Item>
              <Form.Item label="Country" name="hq_country">
                <Input />
              </Form.Item>
              <Form.Item label="Investment Size" name="last_investment_size">
                <Input />
              </Form.Item>

              <Form.Item label="Investor Type" name="investor_type" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
              onClick={() => props.history.push('/home/investors')}
            >
              <CloseCircleOutlined />
              <span>Cancel</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
          </Row>
        </div>
      </Form>
    </div>
  );
};
const mapStateToProps = (state) => {
  return state;
};
const mapDispatchToProps = {};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateInstitutionalInvestor);
