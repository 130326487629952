import React from 'react';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { Row, Col, Form, Input } from 'antd';
import JoditEditor from 'jodit-react';
import { useDispatch } from 'react-redux';
import { addCampaignTemplateServerAction } from '../../redux/actions/EmailTemplates';
import { useHistory } from 'react-router-dom';

function TemplatesNew() {
  const dispatch = useDispatch();
  const history = useHistory();

  const addTemplate = (f) => {
    dispatch(addCampaignTemplateServerAction(f, history));
  };

  return (
    <div className="app-area">
      <Form onFinish={addTemplate}>
        <StyledToolbar module="templates"  mode="add"/>
        <div style={{ marginTop: '30px', padding: 10 }}>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Template Title"
                name="title"
                className="template-title"
                rules={[{ required: true }]}
              >
                <Input className="req" />
              </Form.Item>
              <div style={{ width: 900 }}>
                <Form.Item
                  label="Template Content"
                  name="content"
                  className="template-title"
                >
                  <JoditEditor className="joeditor" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TemplatesNew;
