import {
  USERS_DATA,
  UPDATE_USER_DETAILS,
  IS_LOADING,
  USERS_DELETED,
  USER_ADDED,
  USER_UPDATED,
  UPDATE_USER_FORM_DATA,
  USER_ERRORS,
  SAVE_USERS_LIST,
} from '../constants/Users';

const initUsers = {
  users: '',
  userDetails: {},
  loading: true,
  areUsersDeleted: false,
  userAdded: false,
  userUpdated: false,
  errors: '',
};

const users = (state = initUsers, action) => {
  switch (action.type) {
    case SAVE_USERS_LIST:
      return {
        ...state,
        users: action.data,
        loading: false,
      };
    case USERS_DATA:
      return {
        ...state,
        users: action.data,
        loading: false,
      };
    case UPDATE_USER_DETAILS:
      console.log('LOG ACTION', action);
      return {
        ...state,
        userDetails: action.data.data.data.profile_obj,
        loading: false,
      };
    case UPDATE_USER_FORM_DATA:
      return {
        ...state,
        userDetails: {
          ...state.userDetails,
          [action.payload.target]: action.payload.data,
        },
      };
    case IS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_DELETED:
      return {
        ...state,
        areUsersDeleted: action.val,
      };
    case USER_ADDED:
      return {
        ...state,
        userAdded: action.val,
      };
    case USER_UPDATED:
      return {
        ...state,
        userUpdated: action.val,
      };
    case USER_ERRORS:
      return {
        ...state,
        errors: action.errors,
      };
    default:
      return state;
  }
};

export default users;
