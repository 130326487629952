import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Form, Row } from 'antd';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import {addBulkLeads,updateErrors,} from '../../redux/actions/Leads';

const { Panel } = Collapse;

export const AddBulkLeads = (props) => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState(props.errors !== '' ? props.errors : '');
  const [responseMessage, setResponseMessage] = useState(
    props.responseMessage !== '' ? props.responseMessage : ''
  );

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/Leads');
    }
  }, [responseMessage]);

  useEffect(() => {
    let errorsInLead = {};
    let errorsInForm = '';
    if (props.errors != '') {
      errorsInLead = props.errors['error']['lead_errors'];
      if (errorsInLead != null || errorsInLead != undefined) {
        let errorValues = Object.values(errorsInLead);
        let updateErrors = Object.values(errorsInLead);
        updateErrors.forEach((element) => {
          errorsInForm += element[0] + ', ';
        });
      }
      setErrors(errorsInForm);
    }
    if (props.responseMessage != '' || props.responseMessage != undefined) {
      setResponseMessage(props.responseMessage);
    }
  });

  const addBulkLeads = () => {
    props.updateErrors('');
    props.addBulkLeads('/api/leads/bulk/', parsedData);
    props.history.push('/home/Leads');
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Parsed Data Response in array format
        setParsedData(results.data);
      },
    });
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {errors != '' ? (
          <Alert type="error" closable message={errors}></Alert>
        ) : (
          ''
        )}
        {responseMessage != '' && responseMessage != undefined ? (
          <Alert type="success" closable message={responseMessage}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <div className="app-area">
        <Form onFinish={addBulkLeads}>
          <StyledToolbar module="leads" mode="bulk" />
          <Collapse
            expandIconPosition="end"
            defaultActiveKey={[1]}
            collapsible="disabled"
          >
            <Panel key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Select File" name="document_attachment">
                    <input
                      type="file"
                      name="file"
                      accept=".csv"
                      onChange={changeHandler}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
            <Button
              className="btn-cancel mr-2"
              onClick={() => {
                props.history.push('/home/leads');
              }}
            >
              <CloseCircleOutlined />
              Cancel
            </Button>
            <Button className="btn-save" onClick={addBulkLeads}>
              <CheckOutlined />
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, responseMessage } = state.leads;
  return { errors, responseMessage };
};

const mapDispatchToProps = {
  addBulkLeads,
  updateErrors,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkLeads);
