import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { service } from '../../service';
import {
  USER_STATUS,
  USER_DETAILS,
  DELETE_ALL_USERS,
  GET_USERS_LIST,
  CREATE_NEW_USER,
} from '../constants/Users';
import {
  updateUserDetails,
  usersDeleted,
  saveUsersList,
  isUserAdded,
} from '../actions/Users';
import history from '../store/history';

export function* UserStatus() {
  yield takeEvery(USER_STATUS, function* ({ payload }) {
    let { id, status } = payload;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.post, `users/${id}/status/`, {
        status: status,
      });
    } catch (err) {}
  });
}

export function* getUsersList() {
  yield takeEvery(GET_USERS_LIST, function* ({ payload }) {
    let { url, offset, bool } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset || 0}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(saveUsersList({ data: response.data }));
        // history.push('/home/users/')
      }
    } catch (error) {}
  });
}

function forwardTo(location) {
  history.push(location);
}

export function* createNewUser() {
  yield takeEvery(CREATE_NEW_USER, function* ({ payload }) {
    let { url, offset, bool } = payload;
    try {
      let response = yield call(service.post, '/api/users/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(isUserAdded(true));
      }
    } catch (error) {}
  });
}

export function* getUserDetails() {
  yield takeEvery(USER_DETAILS, function* ({ url }) {
    try {
      let response = yield call(service, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      yield put(updateUserDetails(response));
    } catch (err) {}
  });
}

export function* deleteAllUsers() {
  yield takeEvery(DELETE_ALL_USERS, function* ({ ids }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      // yield put(usersDeleted(true))
      let response = yield call(service.post, `delete_users/`, {
        users_list: ids.toString(),
      });
      if (!response.data.error) {
        yield put(usersDeleted(true));
      }
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(UserStatus),
    fork(getUserDetails),
    fork(deleteAllUsers),
    fork(getUsersList),
    fork(createNewUser),
  ]);
}
