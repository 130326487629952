import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { contactErrors } from '../../redux/actions/Contacts';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Row, Col, Form, Button } from 'antd';
import { Collapse } from 'antd';
import { addDocument } from '../../redux/actions/Documents';

const { Panel } = Collapse;

export const AddDocument = (props) => {
  // State to store parsed data
  const [responseMessage, setResponseMessage] = useState(
    props.responseMessage !== '' ? props.responseMessage : ''
  );
  const [selectedFile, setSelectedFile] = useState();

  useEffect(() => {
    if (responseMessage != '') {
      props.history.push('/home/contacts');
    }
  }, [responseMessage]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const addDocument = (e) => {
    props.contactErrors('');
    props.addDocument(e);
  };
  return (
    <>
      <Form onFinish={addDocument}>
        <Collapse expandIconPosition="end" defaultActiveKey={[1]} collapsible="disabled">
          <Panel header="Documents :" key="1">
            <Row justify="start">
              <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                <Form.Item
                  label="Document Attachment"
                  name="document_attachment"
                >
                  <input type="file" name="file" onChange={changeHandler} />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>
        <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
          <Button className="btn-cancel mr-2">
            <CloseCircleOutlined />
            Cancel
          </Button>

          <Button className="btn-save" htmlType="submit">
            <CheckOutlined />
            Save
          </Button>
        </div>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, responseMessage } = state.accounts;
  return { errors, responseMessage };
};

const mapDispatchToProps = {
  addDocument,
  contactErrors,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddDocument);
