import {
  CheckOutlined,
  CloseCircleOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Tooltip,
} from 'antd';
import { connect, useDispatch } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { getDealDetails, updateDealDetails } from '../../redux/actions/Deals';
import { getCommentsHistory } from '../../redux/actions/Common';
import { useEffect, useState } from 'react';
import { layout } from '../common/layout';
import moment from 'moment';
import { rules } from '../common/rules';
import { CommentsHistory } from '../common/CommentsHistory';
import { SET_DEAL_COMMENT_DETAILS } from '../../redux/constants/Deals';

const { Panel } = Collapse;
const { Option } = Select;

const reasonForDeactivatingDeal = [
  'Company Bankrupt',
  'Company Bought Over',
  'Company Dissolved',
  'Management Buyout',
  'Owner Decided not to pursue',
];

const dealStatusList = [
  { label: 'New Draft', value: 'DRAFT' },
  { label: 'Deactivated', value: 'DEACTIVATED' },
  { label: 'Closed', value: 'CLOSED' },
  { label: 'Open (In-Progress)', value: 'OPEN' },
  { label: 'On Hold', value: 'ON_HOLD' },
];

export const DealDetails = (props) => {
  const id = props.match.params.id;

  const dispatch = useDispatch();

  const { deal, responseMessage, dealCommentHistory } = props;
  const [dealStatus, setDealStatus] = useState();
  const [dealCommentHistoryVisible, setDealCommentHistoryVisible] =
    useState(false);

  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD';

  const updateDeal = (values) => {
    values['deal_origination_date'] = values?.deal_origination_date
      ? moment(values.deal_origination_date).format(dateFormat)
      : undefined;
    values['deal_close_date'] = values?.deal_close_date
      ? moment(values.deal_close_date).format(dateFormat)
      : undefined;
    values['last_contact_date'] = values?.last_contact_date
      ? moment(values.last_contact_date).format(dateFormat)
      : undefined;
    values['deal_history'] = values?.deal_history
      ? moment(values.deal_history).format(dateFormat)
      : undefined;

    values['deal_year'] = values?.deal_origination_date
      ? moment(values.deal_origination_date).format('YYYY')
      : undefined;
    values['deal_month'] = values?.deal_origination_date
      ? moment(values.deal_origination_date).format('MM')
      : undefined;
    values['deal_day'] = values?.deal_origination_date
      ? moment(values.deal_origination_date).format('DD')
      : undefined;
    values['id'] = id;

    if (values?.deal_status === 'DEACTIVATED' && values?.deal_closing_reason) {
      values['deal_closing_reason'] = form.getFieldValue('deal_closing_reason');
    } else {
      values['deal_closing_reason'] = '';
    }

    props.updateDealDetails(values);
  };

  useEffect(() => {
    if (deal) {
      deal['deal_origination_date'] = deal?.deal_origination_date
        ? moment(deal.deal_origination_date, dateFormat)
        : undefined;
      deal['deal_close_date'] = deal?.deal_close_date
        ? moment(deal.deal_close_date, dateFormat)
        : undefined;
      deal['last_contact_date'] = deal?.last_contact_date
        ? moment(deal.last_contact_date, dateFormat)
        : undefined;
      deal['deal_history'] = deal?.deal_history
        ? moment(deal.deal_history, dateFormat)
        : undefined;
      deal['deal_size_currency'] = deal?.deal_size_currency
        ? deal.deal_size_currency
        : 'CAD';
      setDealStatus(deal.deal_status);
    }

    form.setFieldsValue(deal);
  }, [deal]);

  useEffect(() => {
    props.getDealDetails(id);
  }, [id]);

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/deals');
    }
  }, [responseMessage]);

  useEffect(() => {
    if (dealCommentHistory.length > 0) {
      setDealCommentHistoryVisible(true);
    } else {
      setDealCommentHistoryVisible(false);
    }
  }, [dealCommentHistory]);

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <>
      <CommentsHistory
        commentsArray={dealCommentHistory}
        visible={dealCommentHistoryVisible}
        onCancel={() => {
          dispatch({ type: SET_DEAL_COMMENT_DETAILS, data: [] });
        }}
      />
      <div className="app-area">
        <Form
          form={form}
          {...layout}
          onFinish={updateDeal}
          style={{ background: '#fff', padding: '20px' }}
        >
          <StyledToolbar
            id={id}
            module="deals"
            onSubmit={() => form.submit()}
            mode="edit"
          />
          <div>
            <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
              <Panel header="Primary Details" key="1">
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item label="Account Manager" name="account_manager">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Company" name="company">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Deal Name" name="deal_name">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Notes" name="notes">
                      <Input
                        suffix={
                          <Tooltip
                            title="History"
                            onClick={() => {
                              props.getCommentsHistory('deals', deal.id);
                            }}
                          >
                            <ReadOutlined />
                          </Tooltip>
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Execution Manager"
                      name="execution_manager"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Referral Partner" name="referral_partner">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Last Contact Date"
                      name="last_contact_date"
                      rules={rules.dateOptional}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                      />
                    </Form.Item>
                    <Form.Item label="Deal Status" name="deal_status">
                      <Select
                        onChange={(val) => {
                          form.getFieldInstance('deal_closing_reason');
                          setDealStatus(val);
                        }}
                      >
                        {dealStatusList.map(({ label, value }) => (
                          <Option key={value} value={value}>
                            {label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      shouldUpdate={(prev, curr) => true}
                      style={
                        dealStatus === 'DEACTIVATED'
                          ? { display: 'inline' }
                          : { display: 'none' }
                      }
                      label="Deal Deactivate Reason"
                      name="deal_closing_reason"
                      rules={[
                        {
                          required: dealStatus === 'DEACTIVATED',
                          message:
                            'Please select a reason for deactiviting the deal',
                        },
                      ]}
                    >
                      <Select>
                        {reasonForDeactivatingDeal.map((reason) => (
                          <Option key={reason} value={reason}>
                            {reason}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Deal Details"
                key="2"
                style={{ marginTop: '10px' }}
              >
                <Row>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item label="Deal Name" name="deal_name">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label="Deal Type"
                      name="deal_type"
                      initialValue="DEBT"
                    >
                      <Select>
                        <Option value="DEBT">Debt</Option>
                        <Option value="EQUITY">Equity</Option>
                        <Option value="MERGER">Merger</Option>
                        <Option value="ACQUISITION">Acquisition</Option>
                        <Option value="MANAGEMENT BUYOUT">
                          Management Buyout
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Deal Origination Date"
                      name="deal_origination_date"
                      rules={rules.dateOptional}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                      />
                    </Form.Item>
                    <Form.Item label="Debt Type" name="debt_type">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Deal Maturity Debt"
                      name="deal_maturity_debt"
                      rules={rules.amountOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item label="Deal Status" name="deal_status">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label="Deal Size Millions"
                      name="deal_size_millions"
                      rules={rules.amountOptional}
                    >
                      <Input
                        addonAfter={
                          <Select
                            defaultValue="CAD"
                            onSelect={(val) =>
                              form.setFieldValue('deal_size_currency', val)
                            }
                          >
                            <Option value="CAD">CAD</Option>
                            <Option value="INR">INR</Option>
                            <Option value="USD">USD</Option>
                          </Select>
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Deal Size Currency"
                      name="deal_size_currency"
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label="Deal Close Date"
                      name="deal_close_date"
                      rules={rules.dateOptional}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Debt Size"
                      name="debt_size"
                      rules={rules.amountOptional}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Deal History"
                      name="deal_history"
                      rules={rules.dateOptional}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Company Details"
                key="3"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item label="Company" name="company">
                      <Input disabled />
                    </Form.Item>
                    <Form.Item
                      label="Primary Industry Sector"
                      name="primary_industry_sector"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="Company Address" name="company_address">
                      <Input />
                    </Form.Item>
                    <Form.Item label="Province" name="company_province">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Email"
                      name="company_primary_contact_email"
                      rules={rules.emailOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item label="Company Country" name="company_country">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Primary Industry Group"
                      name="primary_industry_group"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item label="HQ Location" name="company_hq_location">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Postal Code"
                      name="company_postal_code"
                      rules={rules.numberOptional}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="company_primary_contact_phone"
                      rules={rules.contactNumberOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Lending Institution Details"
                key="4"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item label="Lender Name" name="lenders_name">
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact First Name"
                      name="contact_first_name_at_lending_institution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Email"
                      name="email_for_contact_at_lending"
                      rules={rules.emailOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Linkedin URL"
                      name="contact_linkedin_at_lending_institution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="contact_last_name_at_lending_institution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="telephone_for_contact_at_lending"
                      rules={rules.contactNumberOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Referral Party Details"
                key="5"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item
                      label="Percentage"
                      name="referral_party_percentage"
                      rules={rules.decimalOptional}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="First Name"
                      name="referral_party_first_name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Email"
                      name="referral_party_email"
                      rules={rules.emailOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Dollar Value"
                      name="referral_party_dollar_value"
                      rules={rules.amountOptional}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="referral_party_last_name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="referral_party_telephone"
                      rules={rules.contactNumberOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Individual Investor Details"
                key="6"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item
                      label="First Name"
                      name="individual_inverstor_first_name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Email"
                      name="individual_investor_email"
                      rules={rules.emailOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Last Name"
                      name="individual_inverstor_last_name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="individual_investor_telephone"
                      rules={rules.contactNumberOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Equity Institution Details"
                key="7"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item
                      label="Equity Provider Name"
                      name="equity_provider_name"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact First Name"
                      name="contact_first_name_at_equity_intitution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Email"
                      name="email_for_contact_at_equity_institution"
                      rules={rules.emailOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Linkedin URL"
                      name="contact_linkedin_at_equity_institution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Last Name"
                      name="contact_last_name_at_equity_intitution"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Contact Number"
                      name="telephone_for_contact_at_equity"
                      rules={rules.contactNumberOptional}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
              <Panel
                header="Status Details"
                key="8"
                style={{ marginTop: '10px' }}
              >
                <Row justify="start">
                  <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                    <Form.Item
                      label="Current Business Status"
                      name="current_business_status"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Current Deal Status"
                      name="current_deal_status"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                    <Form.Item
                      label="Current Financing Status"
                      name="current_financing_status"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Row
              justify="center"
              style={{ marginTop: '20px', marginBottom: '50px' }}
            >
              <Button
                type="primary"
                className="btn bg-darkblue text-white fw-12 fw-6"
                onClick={() => props.history.push('/home/deals')}
              >
                <CloseCircleOutlined />
                <span>Cancel</span>
              </Button>
              <Button
                type="primary"
                className="btn text-white fw-12 fw-6"
                htmlType="submit"
              >
                <CheckOutlined />
                <span>Save</span>
              </Button>
            </Row>
          </div>
        </Form>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { responseMessage, errors, deal, dealCommentHistory } = state.deals;
  return { responseMessage, errors, deal, dealCommentHistory };
};

const mapDispatchToProps = {
  getDealDetails,
  updateDealDetails,
  getCommentsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealDetails);
