import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import React, { useEffect } from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import logo from '../../assets/images/calender.png';
import AddEvent from '../events/AddEvent';
import { connect } from 'react-redux';

import { isLoading } from '../../redux/actions/Users';

import {
  getEvents,
  loading,
  deleteEvent,
  responseMessage,
} from '../../redux/actions/Events';

const CalendarComp = (props) => {
  const { eventsData } = props;
  console.log('props events list');

  const meetings = [];
  for (var key in eventsData.events) {
    var obj = eventsData.events[key];
    console.log(obj.start_date + 'T' + obj.start_time);
    const combinedStartDateTime = new Date(
      obj.start_date + 'T' + obj.start_time
    );
    const combinedendDateTime = new Date(obj.end_date + 'T' + obj.end_time);
    meetings.push({
      id: obj.id,
      title: obj.name,
      start: combinedStartDateTime,
      end: combinedendDateTime,
    });
  }

  useEffect(() => {
    props.getEvents(`/api/events/`, 0);
    props.loading();
  }, [isLoading]);

  const handleEventSelection = (e) => {
    routeToDetails(e.id);
    console.log(e, 'Event data');
  };

  const routeToDetails = (id) => {
    props.history.push(`/home/events/${id}/details`);
  };

  const locales = {
    'en-US': require('date-fns/locale/en-US'),
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  return (
    <div>
      <header className="px-4 py-2 flex items-center">
        <img src={logo} alt="calendar" className="mr-2 w-12 h-12" />
        <h1 className="mr-10 text-xl text-gray-600 fond-bold">Calendar</h1>
      </header>
      <AddEvent></AddEvent>
      <Calendar
        localizer={localizer}
        events={meetings}
        startAccessor="start"
        endAccessor="end"
        onSelectEvent={handleEventSelection}
        style={{ height: 500, margin: '50px' }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, eventsData, offset, refresh } = state.events;
  return { isLoading, eventsData, offset, refresh };
};

const mapDispatchToProps = {
  getEvents,
  loading,
  deleteEvent,
  responseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarComp);
