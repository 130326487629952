import { Table } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { getDealHistoryDetails } from '../../redux/actions/Deals';

export const DealHistory = (props) => {
  const id = props.match.params.id;

  const { dealHistory } = props;

  function isChanged(row, index, key) {
    return (
      dealHistory.length > index + 1 &&
      dealHistory[index + 1]?.[key] !== row[key]
    );
  }
  useEffect(() => {
    props.getDealHistoryDetails(id);
  }, [id]);

  const columns = [
    {
      title: 'Account Manager',
      dataIndex: 'account_manager',
      key: 'account_manager',
      render: (account_manager, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'account_manager')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{account_manager}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Partner',
      dataIndex: 'referral_partner',
      key: 'referral_partner',
      render: (referral_partner, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_partner')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_partner}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party Percentage',
      dataIndex: 'referral_party_percentage',
      key: 'referral_party_percentage',
      render: (referral_party_percentage, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_percentage')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_percentage}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party Dollar Value',
      dataIndex: 'referral_party_dollar_value',
      key: 'referral_party_dollar_value',
      render: (referral_party_dollar_value, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_dollar_value')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_dollar_value}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Execution Manager',
      dataIndex: 'execution_manager',
      key: 'execution_manager',
      render: (execution_manager, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'execution_manager')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{execution_manager}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Name',
      dataIndex: 'deal_name',
      key: 'deal_name',
      render: (deal_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_name') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Size Millions',
      dataIndex: 'deal_size_millions',
      key: 'deal_size_millions',
      render: (deal_size_millions, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_size_millions')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_size_millions}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Size Currency',
      dataIndex: 'deal_size_currency',
      key: 'deal_size_currency',
      render: (deal_size_currency, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_size_currency')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_size_currency}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Type',
      dataIndex: 'deal_type',
      key: 'deal_type',
      render: (deal_type, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_type') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_type}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Origination Date',
      dataIndex: 'deal_origination_date',
      key: 'deal_origination_date',
      render: (deal_origination_date, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_origination_date')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_origination_date}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Close Date',
      dataIndex: 'deal_close_date',
      key: 'deal_close_date',
      render: (deal_close_date, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_close_date')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_close_date}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Maturity Debt',
      dataIndex: 'deal_maturity_debt',
      key: 'deal_maturity_debt',
      render: (deal_maturity_debt, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_maturity_debt')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_maturity_debt}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Lenders Name',
      dataIndex: 'lenders_name',
      key: 'lenders_name',
      render: (lenders_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'lenders_name')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{lenders_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Contact First Name At Lending Institution',
      dataIndex: 'contact_first_name_at_lending_institution',
      key: 'contact_first_name_at_lending_institution',
      render: (contact_first_name_at_lending_institution, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'contact_first_name_at_lending_institution'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{contact_first_name_at_lending_institution}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Contact Last Name At Lending Institution',
      dataIndex: 'contact_last_name_at_lending_institution',
      key: 'contact_last_name_at_lending_institution',
      render: (contact_last_name_at_lending_institution, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'contact_last_name_at_lending_institution'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{contact_last_name_at_lending_institution}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Email For Contact At Lending',
      dataIndex: 'email_for_contact_at_lending',
      key: 'email_for_contact_at_lending',
      render: (email_for_contact_at_lending, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'email_for_contact_at_lending')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{email_for_contact_at_lending}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Telephone For Contact At Lending',
      dataIndex: 'telephone_for_contact_at_lending',
      key: 'telephone_for_contact_at_lending',
      render: (telephone_for_contact_at_lending, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'telephone_for_contact_at_lending'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{telephone_for_contact_at_lending}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Equity Provider Name',
      dataIndex: 'equity_provider_name',
      key: 'equity_provider_name',
      render: (equity_provider_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'equity_provider_name')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{equity_provider_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Contact First Name At Equity Intitution',
      dataIndex: 'contact_first_name_at_equity_intitution',
      key: 'contact_first_name_at_equity_intitution',
      render: (contact_first_name_at_equity_intitution, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'contact_first_name_at_equity_intitution'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{contact_first_name_at_equity_intitution}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Contact Last Name At Equity Intitution',
      dataIndex: 'contact_last_name_at_equity_intitution',
      key: 'contact_last_name_at_equity_intitution',
      render: (contact_last_name_at_equity_intitution, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'contact_last_name_at_equity_intitution'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{contact_last_name_at_equity_intitution}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Email For Contact At Equity Institution',
      dataIndex: 'email_for_contact_at_equity_institution',
      key: 'email_for_contact_at_equity_institution',
      render: (email_for_contact_at_equity_institution, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'email_for_contact_at_equity_institution'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{email_for_contact_at_equity_institution}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Telephone For Contact At Equity',
      dataIndex: 'telephone_for_contact_at_equity',
      key: 'telephone_for_contact_at_equity',
      render: (telephone_for_contact_at_equity, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'telephone_for_contact_at_equity'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{telephone_for_contact_at_equity}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Individual Inverstor First Name',
      dataIndex: 'individual_inverstor_first_name',
      key: 'individual_inverstor_first_name',
      render: (individual_inverstor_first_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'individual_inverstor_first_name'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{individual_inverstor_first_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Individual Inverstor Last Name',
      dataIndex: 'individual_inverstor_last_name',
      key: 'individual_inverstor_last_name',
      render: (individual_inverstor_last_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'individual_inverstor_last_name'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{individual_inverstor_last_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Individual Investor Email',
      dataIndex: 'individual_investor_email',
      key: 'individual_investor_email',
      render: (individual_investor_email, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'individual_investor_email')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{individual_investor_email}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Individual Investor Telephone',
      dataIndex: 'individual_investor_telephone',
      key: 'individual_investor_telephone',
      render: (individual_investor_telephone, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'individual_investor_telephone')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{individual_investor_telephone}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party First Name',
      dataIndex: 'referral_party_first_name',
      key: 'referral_party_first_name',
      render: (referral_party_first_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_first_name')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_first_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party Last Name',
      dataIndex: 'referral_party_last_name',
      key: 'referral_party_last_name',
      render: (referral_party_last_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_last_name')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_last_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party Email',
      dataIndex: 'referral_party_email',
      key: 'referral_party_email',
      render: (referral_party_email, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_email')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_email}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Referral Party Telephone',
      dataIndex: 'referral_party_telephone',
      key: 'referral_party_telephone',
      render: (referral_party_telephone, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'referral_party_telephone')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{referral_party_telephone}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (company, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{company}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Closing Reason',
      dataIndex: 'deal_closing_reason',
      key: 'deal_closing_reason',
      render: (deal_closing_reason, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_closing_reason')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_closing_reason}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Status',
      dataIndex: 'deal_status',
      key: 'deal_status',
      render: (deal_status, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_status') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_status}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Year',
      dataIndex: 'deal_year',
      key: 'deal_year',
      render: (deal_year, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_year') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_year}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Month',
      dataIndex: 'deal_month',
      key: 'deal_month',
      render: (deal_month, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_month') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_month}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal Day',
      dataIndex: 'deal_day',
      key: 'deal_day',
      render: (deal_day, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_day') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{deal_day}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Deal History',
      dataIndex: 'deal_history',
      key: 'deal_history',
      render: (deal_history, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'deal_history')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{deal_history}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Debt Size',
      dataIndex: 'debt_size',
      key: 'debt_size',
      render: (debt_size, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'debt_size') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{debt_size}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Debt Type',
      dataIndex: 'debt_type',
      key: 'debt_type',
      render: (debt_type, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'debt_type') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{debt_type}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Current Business Status',
      dataIndex: 'current_business_status',
      key: 'current_business_status',
      render: (current_business_status, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'current_business_status')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{current_business_status}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Current Financing Status',
      dataIndex: 'current_financing_status',
      key: 'current_financing_status',
      render: (current_financing_status, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'current_financing_status')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{current_financing_status}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Current Deal Status',
      dataIndex: 'current_deal_status',
      key: 'current_deal_status',
      render: (current_deal_status, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'current_deal_status')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{current_deal_status}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Primary Industry Sector',
      dataIndex: 'primary_industry_sector',
      key: 'primary_industry_sector',
      render: (primary_industry_sector, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'primary_industry_sector')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{primary_industry_sector}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Primary Industry Group',
      dataIndex: 'primary_industry_group',
      key: 'primary_industry_group',
      render: (primary_industry_group, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'primary_industry_group')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{primary_industry_group}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Address',
      dataIndex: 'company_address',
      key: 'company_address',
      render: (company_address, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_address')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_address}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Hq Location',
      dataIndex: 'company_hq_location',
      key: 'company_hq_location',
      render: (company_hq_location, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_hq_location')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_hq_location}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Province',
      dataIndex: 'company_province',
      key: 'company_province',
      render: (company_province, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_province')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_province}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Country',
      dataIndex: 'company_country',
      key: 'company_country',
      render: (company_country, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_country')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_country}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Postal Code',
      dataIndex: 'company_postal_code',
      key: 'company_postal_code',
      render: (company_postal_code, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_postal_code')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_postal_code}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Primary Contact Email',
      dataIndex: 'company_primary_contact_email',
      key: 'company_primary_contact_email',
      render: (company_primary_contact_email, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_primary_contact_email')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_primary_contact_email}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Primary Contact Phone',
      dataIndex: 'company_primary_contact_phone',
      key: 'company_primary_contact_phone',
      render: (company_primary_contact_phone, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'company_primary_contact_phone')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_primary_contact_phone}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Last Contact Date',
      dataIndex: 'last_contact_date',
      key: 'last_contact_date',
      render: (last_contact_date, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'last_contact_date')
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{last_contact_date}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Company Primary Contact Linkedin',
      dataIndex: 'company_primary_contact_linkedin',
      key: 'company_primary_contact_linkedin',
      render: (company_primary_contact_linkedin, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(
                row,
                index,
                'company_primary_contact_linkedin'
              )
                ? '#bfd3b3'
                : '',
            },
          },
          children: (
            <p>
              <span>{company_primary_contact_linkedin}</span>
            </p>
          ),
        };
      },
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
      key: 'notes',
      render: (notes, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, 'notes') ? '#bfd3b3' : '',
            },
          },
          children: (
            <p>
              <span>{notes}</span>
            </p>
          ),
        };
      },
    },
  ];

  return (
    <>
      <StyledToolbar id={id} module="deals" mode="history" />

      <Table
        className="users-table users-table-inactive"
        columns={columns}
        dataSource={dealHistory}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 1300 }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { dealHistory } = state.deals;
  return { dealHistory };
};

const mapDispatchToProps = {
  getDealHistoryDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealHistory);
