import { Select } from 'antd';
import { connect } from 'react-redux';

const RowCounter = (props) => {
  const { updateRecordsPerPage } = props;
  const { Option } = Select;
  return (
    <div className="toolbar-records-per-page">
      <Select
        style={{ width: 170 }}
        defaultValue="10"
        onChange={updateRecordsPerPage}
      >
        <Option value="10">10 Records per page</Option>
        <Option value="20">20 Records per page</Option>
        <Option value="30">30 Records per page</Option>
      </Select>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RowCounter);
