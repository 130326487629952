import {
  DEAL_ERRORS,
  IS_LOADING,
  RESPONSE_MESSAGE,
  SET_DEALS_LIST,
  SET_DEAL_DETAILS,
  SET_DEAL_HISTORY_DETAILS,
  UPDATE_ERRORS,
  SET_DEAL_COMMENT_DETAILS,
} from '../constants/Deals';

const initialState = {
  deals: {},
  dealsLoading: true,
  error: '',
  dealHistory: [],
  dealCommentHistory: [],
};

const deals = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        dealsLoading: true,
      };
    case SET_DEALS_LIST:
      return {
        ...state,
        deals: action.data,
        dealsLoading: false,
      };
    case DEAL_ERRORS:
      return {
        ...state,
        deals: {},
        dealsLoading: false,
        error: action.data,
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_DEAL_DETAILS:
      return {
        ...state,
        deal: action.data,
      };
    case SET_DEAL_HISTORY_DETAILS: {
      return {
        ...state,
        dealHistory: action.data,
      };
    }
    case SET_DEAL_COMMENT_DETAILS: {
      return {
        ...state,
        dealCommentHistory: action.data,
      };
    }
    default:
      return state;
  }
};

export default deals;
