import {
  SET_ACCOUNTS_DATA,
  RESPONSE_MESSAGE,
  UPDATE_ERRORS,
  REFRESH,
  UPDATE_ACCOUNT_DETAILS,
} from '../constants/Accounts';

const initialState = {
  isLoading: true,
  accountsData: '',
  refresh: false,
  responseMessage: '',
  errors: [],
  openOffset: 0,
  closeOffset: 0,
  accountDetails: {},
};

const accounts = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACCOUNTS_DATA:
      return {
        ...state,
        accountsData: action.payload.response,
        openOffset: action.payload.response.active_accounts.offset,
        closeOffset: action.payload.response.closed_accounts.offset,
        isLoading: false,
      };
    case UPDATE_ACCOUNT_DETAILS:
      return {
        ...state,
        accountDetails: action.data.data.account_obj,
        loading: false,
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case REFRESH:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
};

export default accounts;
