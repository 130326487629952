import { Table } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { getContactHistoryDetails } from '../../redux/actions/Contacts';
import { getCountryStatusLabel } from './util';

export const ContactHistory = (props) => {
  const id = props.match.params.id;

  const { contactHistory } = props;

  function isChanged(row, index, key) {
    return (
      contactHistory.length > index + 1 &&
      contactHistory[index + 1]?.[key] !== row[key]
    );
  }
  function isAddressChanged(row, index, key) {
    return (
      contactHistory.length > index + 1 &&
      contactHistory[index + 1]?.address?.[key] !== row?.address?.[key]
    );
  }
  useEffect(() => {
    props.getContactHistoryDetails(id);
  }, [id]);

  const columns = [
    {
      title: "Salutation",
      dataIndex: "salutation",
      key: "salutation",
      render: (salutation, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "salutation") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{salutation}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      render: (first_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "first_name") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{first_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
      render: (last_name, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "last_name") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{last_name}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (title, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "title") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{title}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Primary Email",
      dataIndex: "primary_email",
      key: "primary_email",
      render: (primary_email, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "primary_email") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{primary_email}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobile_number",
      key: "mobile_number",
      render: (mobile_number, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "mobile_number") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{mobile_number}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (description, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "description") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{description}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Date Of Birth",
      dataIndex: "date_of_birth",
      key: "date_of_birth",
      render: (date_of_birth, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "date_of_birth") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{date_of_birth}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      render: (department, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "department") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{department}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Facebook Url",
      dataIndex: "facebook_url",
      key: "facebook_url",
      render: (facebook_url, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "facebook_url") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{facebook_url}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Language",
      dataIndex: "language",
      key: "language",
      render: (language, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "language") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{language}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Linked In Url",
      dataIndex: "linked_in_url",
      key: "linked_in_url",
      render: (linked_in_url, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "linked_in_url") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{linked_in_url}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Organization",
      dataIndex: "organization",
      key: "organization",
      render: (organization, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "organization") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{organization}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Secondary Email",
      dataIndex: "secondary_email",
      key: "secondary_email",
      render: (secondary_email, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "secondary_email")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{secondary_email}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Secondary Number",
      dataIndex: "secondary_number",
      key: "secondary_number",
      render: (secondary_number, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "secondary_number")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{secondary_number}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Twitter Username",
      dataIndex: "twitter_username",
      key: "twitter_username",
      render: (twitter_username, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "twitter_username")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{twitter_username}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Contacts Country",
      dataIndex: "contacts_country",
      key: "contacts_country",
      render: (contacts_country, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "contacts_country")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{getCountryStatusLabel(contacts_country)}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Address Lane",
      dataIndex: ["address", "address_line"],
      key: "address_line",
      render: (address_line, row, index) => {
        return {
          props: {
            style: {
              background: isAddressChanged(row, index, "address_line")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{address_line}</span>
            </p>
          ),
        };
      },
    },

    {
      title: "Address Street",
      dataIndex: ["address", "street"],
      key: "street",
      render: (street, row, index) => {
        return {
          props: {
            style: {
              background: isAddressChanged(row, index, "street")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{street}</span>
            </p>
          ),
        };
      },
    },

    {
      title: "Address City",
      dataIndex: ["address", "city"],
      key: "city",
      render: (city, row, index) => {
        return {
          props: {
            style: {
              background: isAddressChanged(row, index, "city")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{city}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Address State",
      dataIndex: ["address", "state"],
      key: "state",
      render: (state, row, index) => {
        return {
          props: {
            style: {
              background: isAddressChanged(row, index, "state")
                ? "#bfd3b3"
                : "",
            },
          },
          children: (
            <p>
              <span>{state}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Marketing Status",
      dataIndex: "has_subscribe",
      key: "has_subscribe",
      render: (has_subscribe, row, index) => {
        return {
          props: {
            style: {
              background: isChanged(row, index, "has_subscribe") ? "#bfd3b3" : "",
            },
          },
          children: (
            <p>
              <span>{has_subscribe?"Subscribed":"Not Subscribed"}</span>
            </p>
          ),
        };
      },
    },
  ];
  

  return (
    <>
      <StyledToolbar id={id} module="contacts" mode="history" />

      <Table
        className="users-table users-table-inactive"
        columns={columns}
        dataSource={contactHistory}
        pagination={{ pageSize: 5 }}
        scroll={{ x: 1300 }}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { contactHistory } = state.contacts;
  return { contactHistory };
};

const mapDispatchToProps = {
  getContactHistoryDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactHistory);
