import {
  SET_CONTACTS,
  CONTACT_ERRORS,
  RESPONSE_MESSAGE,
  REFRESH,
  UPDATE_CONTACT_DETAILS,
  CONTACT_UPDATED,
  SET_CONTACT_HISTORY_DETAILS
} from '../constants/Contacts';

const initialState = {
  id: '',
  contacts: '',
  contactsSearch: [],
  errors: '',
  isLoading: true,
  refresh: false,
  responseMessage: '',
  offset: 0,
  contactDetails: {},
  contactHistory:[],
  contactTasks: {},
  contactEvents: {},
  addressDetails: {},
  contactUpdated: false,
};

const contacts = (state = initialState, action) => {
  switch (action.type) {
    case SET_CONTACTS:
      return {
        ...state,
        contacts: action.payload.response,
        isLoading: false,
      };
    case UPDATE_CONTACT_DETAILS:
      return {
        ...state,
        addressDetails: action.data.data.address_obj,
        contactDetails: action.data.data.contact_obj,
        contactTasks: action.data.data.tasks,
        contactEvents: action.data.data.events,
        loading: false,
      };
    case CONTACT_ERRORS: {
      return {
        ...state,
        errors: action.payload,
      };
    }
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case CONTACT_UPDATED:
      return {
        ...state,
        contactUpdated: action.val,
      };
    case REFRESH:
      return {
        ...state,
        refresh: action.payload,
      };
    case SET_CONTACT_HISTORY_DETAILS: {
        return {
          ...state,
          contactHistory: action.data
        }
      }
    default:
      return state;
  }
};

export default contacts;
