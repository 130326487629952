import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  refresh,
  responseMessage,
  setCampaignsData, updateCampaignDetails, updateErrors
} from '../actions/Campaigns';
import {
  ADD_CAMPAIGN, CAMPAIGN_DETAILS, DELETE_CAMPAIGN, GET_CAMPAIGNS
} from '../constants/Campaigns';
import {
  ADD_CAMPAIGN_TEMPLATE_SERVER, DELETE_TEMPLATE, GET_TEMPLATE_SERVER, UPDATE_CAMPAIGN_TEMPLATE_SERVER
} from '../constants/EmailTemplates';

import {
  setCampaignTemplatesAction
} from '../actions/EmailTemplates';

import { service } from '../../service';

export function* getCampaignsList() {
  yield takeEvery(GET_CAMPAIGNS, function* ({ payload }) {
    let { url, offset } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setCampaignsData({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* addCampaign() {
  yield takeEvery(ADD_CAMPAIGN, function* ({ payload }) {
    try {
      // payload.contacts_source = payload.contacts_source[0];
      console.log(payload.contacts_source);
      let response = yield call(service.post, '/api/campaigns/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* deleteCampaign() {
  yield takeEvery(DELETE_CAMPAIGN, function* ({ payload }) {
    let { id, bool } = payload;
    try {
      let response = yield call(service.delete, `/api/campaigns/${id}/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(refresh(bool));
      }
    } catch (error) {}
  });
}

export function* getCampaignDetails() {
  yield takeEvery(CAMPAIGN_DETAILS, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('response aampaign details');
      console.log(response);
      yield put(updateCampaignDetails(response));
    } catch (err) {}
  });
}

export function* getCampaignTemplatesSaga() {
  yield takeEvery(GET_TEMPLATE_SERVER, function* ({ url }) {
    try {
      // console.log('Fetching all campaign templates');
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, '/api/campaign_templates/', {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      // console.log('response aampaign templates');
      // console.log(response);
      yield put(setCampaignTemplatesAction(response.data.email_templates));
    } catch (err) {}
  });
}

export function* addCampaignTemplate() {
  yield takeEvery(
    ADD_CAMPAIGN_TEMPLATE_SERVER,
    function* ({ payload, history }) {
      try {
        // payload.contacts_source = payload.contacts_source[0];
        console.log(payload.contacts_source);
        let response = yield call(
          service.post,
          '/api/campaign_templates/',
          payload,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem('Token')}`,
              org: localStorage.getItem('company'),
            },
          }
        );
        if (!response.data.error) {
          history.push('/home/templates/');
        }
      } catch (error) {
        console.error(error);
      }
    }
  );
}

export function* updateCampaignTemplate() {
  yield takeEvery(
    UPDATE_CAMPAIGN_TEMPLATE_SERVER,
    function* ({ payload, history }) {
      try {
        // payload.contacts_source = payload.contacts_source[0];
        console.log(payload.contacts_source);
        let response = yield call(
          service.put,
          '/api/campaign_templates/',
          payload,
          {
            headers: {
              Authorization: `jwt ${localStorage.getItem('Token')}`,
              org: localStorage.getItem('company'),
            },
          }
        );
        if (!response.data.error) {
          history.push('/home/templates/');
        }
      } catch (error) {
        console.error(error);
      }
    }
  );
}

export function* deleteCampaignTemplate() {
  yield takeEvery(DELETE_TEMPLATE, function* ({ payload, history }) {
    try {
      let response = yield call(
        service.delete,
        `/api/campaign_templates/?id=${payload.id}`,
        payload,
        {
          headers: {
            Authorization: `jwt ${localStorage.getItem('Token')}`,
            org: localStorage.getItem('company'),
          },
        }
      );
      if (!response.data.error) {
        history.push('/home/templates/');
      }
    } catch (error) {
      console.error(error);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCampaignsList),
    fork(addCampaign),
    fork(deleteCampaign),
    fork(getCampaignDetails),
    fork(getCampaignTemplatesSaga),
    fork(addCampaignTemplate),
    fork(updateCampaignTemplate),
    fork(deleteCampaignTemplate),
  ]);
}
