import {
    SET_EVENTS_DATA,
    RESPONSE_MESSAGE,
    UPDATE_ERRORS,
    REFRESH,
    UPDATE_EVENT_DETAILS,
    EVENT_UPDATED
  } from '../constants/Events';
  
  const initialState = {
    isLoading: true,
    eventsData: '',
    refresh: false,
    responseMessage: '',
    errors: [],
    offset: 0,
    eventDetails: {},
    eventUpdated: false,
  };
  
  const events = (state = initialState, action) => {
    switch (action.type) {
      case SET_EVENTS_DATA:
        return {
          ...state,
          eventsData: action.payload.response,
          offset: action.payload.response.offset,
          isLoading: false,
        };
      case UPDATE_EVENT_DETAILS:
        return {
          ...state,
          eventDetails: action.data.data.event_obj,
          loading: false,
        };
      case RESPONSE_MESSAGE:
        return {
          ...state,
          responseMessage: action.payload,
        };
      case UPDATE_ERRORS:
        return {
          ...state,
          errors: action.payload,
        };
      case EVENT_UPDATED:
        return {
          ...state,
          eventUpdated: action.val,
        };
      case REFRESH:
        return {
          ...state,
          refresh: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default events;
  