export const GET_LEAD_DATA = 'GET_LEAD_DATA';
export const REDIRECT_TO_URL = 'REDIRECT_TO_URL';
export const SET_LEAD_DATA = 'SET_LEAD_DATA';
export const GET_OPEN_TASKS_DATA = 'GET_OPEN_TASKS_DATA'; 
export const SET_OPEN_TASKS_DATA = 'SET_OPEN_TASKS_DATA';
export const GET_TOP_EMPLOYEE_EMAIL_DATA = 'GET_TOP_EMPLOYEE_EMAIL_DATA'; 
export const SET_TOP_EMPLOYEE_EMAIL_DATA = 'SET_TOP_EMPLOYEE_EMAIL_DATA';
export const GET_LEADS_BY_SOURCE_DATA = 'GET_LEADS_BY_SOURCE_DATA';
export const SET_LEADS_BY_SOURCE_DATA = 'SET_LEADS_BY_SOURCE_DATA';
export const GET_NUM_CLOSED_DEALS = 'GET_NUM_CLOSED_DEALS';
export const SET_NUM_CLOSED_DEALS = 'SET_NUM_CLOSED_DEALS';
export const GET_DEAL_DEACTIVATE_REASON_DATA = 'GET_DEAL_DEACTIVATE_REASON_DATA';
export const SET_DEAL_DEACTIVATE_REASON_DATA = 'SET_DEAL_DEACTIVATE_REASON_DATA';
export const GET_AVG_DEAL_CLOSING_RATE = 'GET_AVG_DEAL_CLOSING_RATE';
export const SET_AVG_DEAL_CLOSING_RATE = 'SET_AVG_DEAL_CLOSING_RATE';
export const GET_NUM_INVESTORS_DATA = 'GET_NUM_INVESTORS_DATA';
export const SET_NUM_INVESTORS_DATA = 'SET_NUM_INVESTORS_DATA';
export const GET_DEALS_WON_DATA = 'GET_DEALS_WON_DATA';
export const SET_DEALS_WON_DATA = 'SET_DEALS_WON_DATA';
export const GET_AVG_REVENUE_DATA = 'GET_AVG_REVENUE_DATA';
export const SET_AVG_REVENUE_DATA = 'SET_AVG_REVENUE_DATA';