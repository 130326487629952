export const GET_USERS_LIST = 'GET_USERS_LIST';
export const SAVE_USERS_LIST = 'SAVE_USERS_LIST';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
export const USERS_DATA = 'USERS_DATA';
export const USER_STATUS = 'USER_STATUS';
export const USER_DETAILS = 'USER_DETAILS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';
export const DELETE_ALL_USERS = 'DELETE_ALL_USERS';
export const IS_LOADING = 'IS_LOADING';
export const USERS_DELETED = 'USERS_DELETED';
export const USER_ADDED = 'USER_ADDED';
export const USER_UPDATED = 'USER_UPDATED';
export const UPDATE_USER_FORM_DATA = 'UPDATE_USER_FORM_DATA';
export const USER_ERRORS = 'USER_ERRORS';
