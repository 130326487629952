import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import { GET_DATA, POST_DATA, PUT_DATA, PATCH_DATA, DELETE_DATA } from '../constants/Fetch';
import { getCompaniesData } from '../actions/Companies';
import {
  updateUsersData,
  isUserAdded,
  isUserUpdated,
  userErrors,
} from '../actions/Users';
import { isContactUpdated } from '../actions/Contacts';
import { isTaskUpdated } from '../actions/Tasks';
import { responseMessage } from '../actions/Campaigns';
import { isEventUpdated, isEventDeleted } from '../actions/Events';
import { service } from '../../service';

export function* getData() {
  yield takeEvery(GET_DATA, function* ({ payload }) {
    let { url, obj } = payload;
    let response;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      response = yield call(service.get, url);
      if (response.status === 200) {
        if (obj === 'leads') {
        } else if (obj === 'tasks') {
        } else if (obj === 'users') {
          yield put(updateUsersData(response));
        } else if (obj === 'companies') {
          yield put(getCompaniesData(response));
        }
      }
    } catch (err) {
      if (err.response) {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = '/login';
        }
      }
    }
  });
}

export function* postData() {
  yield takeEvery(POST_DATA, function* ({ payload }) {
    let { url, obj, data } = payload;
    let response;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      response = yield call(service.post, url, data);
      if (!response.data.error) {
        if (obj === 'leads') {
        } else if (obj === 'tasks') {
        } else if (obj === 'users') {
          yield put(isUserAdded(true));
        }
      }
    } catch (err) {
      if (obj === 'users') {
        yield put(userErrors(err.response.data.errors.user_errors));
      }
    }
  });
}

export function* patchData() {
  yield takeEvery(PATCH_DATA, function* ({ payload }) {
    let { url, obj, data } = payload;
    let response;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      response = yield call(service.patch, url, data);
      if (!response.data.error) {
        if (obj === 'leads') {
        } else if (obj === 'tasks') {
        } else if (obj === 'users') {
          yield put(isUserAdded(true));
        } else if (obj === 'contacts') {
          yield put(isContactUpdated(true));
        }
      }
    } catch (err) {
      if (obj === 'users') {
        yield put(userErrors(err.response.data.errors.user_errors));
      }
    }
  });
}

export function* putData() {
  yield takeEvery(PUT_DATA, function* ({ payload }) {
    let { url, obj, data } = payload;
    let response;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      service.defaults.headers['org'] = localStorage.getItem('company');
      response = yield call(service.put, url, data);
      if (!response.data.error) {
        if (obj === 'leads') {
        } else if (obj === 'tasks') {
          yield put(isTaskUpdated(true));
        } else if (obj === 'contacts') {
          yield put(isContactUpdated(true));
        } else if (obj === 'users') {
          yield put(isUserUpdated(true));
        } else if (obj === 'events') {
          yield put(isEventUpdated(true));
        } else if (obj === 'campaigns') {
          yield put(responseMessage('success'))
        }
      }
    } catch (err) {
      yield put(responseMessage('fail'))
    }
  });
}

export function* deleteData() {
  yield takeEvery(DELETE_DATA, function* ({ payload }) {
    let { url, obj } = payload;
    let response;
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      service.defaults.headers['org'] = localStorage.getItem('company');
      response = yield call(service.delete, url);
      if (!response.data.error) {
        if (obj === 'leads') {
        } else if (obj === 'events') {
          console.log('came hereeeeeeee for delete');
          yield put(isEventDeleted(true));
        }
      }
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getData), fork(postData), fork(putData), fork(deleteData), fork(patchData)]);
}
