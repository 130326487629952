import { FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { withRouter } from 'react-router-dom';
import './StyledToolbar.css';
import { PageCounter } from '../../pagination/pagecounter/PageCounter';
import RowCounter from '../../pagination/rowcounter/RowCounter';
import { capFirstLetter } from '../../../../utils/stringUtils';

export const MainToolbar = (props) => {
  const {
    totalCount,
    setDisplayFilters,
    displayFilters,
    offset,
    setOffset,
    rows,
    updateRecordsPerPage,
    module,
    hasBulkUpload,
    buttons,
  } = props;

  return (
    <div className="main-toolbar">
      <div className="main-toolbar-functions-wrapper">
        {setDisplayFilters && (
          <FilterOutlined
            onClick={() => {
              setDisplayFilters(!displayFilters);
            }}
          />
        )}
        {module && (
          <>
            {buttons.map((button) => (
              <Button type="primary" className="btn text-white fw-12 fw-6">
                <PlusOutlined />
                <span onClick={() => props.history.push('/home/' + button.url)}>
                  {button.label}
                </span>
              </Button>
            ))}

            {hasBulkUpload && (
              <Button type="primary" className="btn text-white fw-12 fw-6">
                <PlusOutlined />
                <span
                  onClick={() =>
                    props.history.push('/home/' + module + '/bulk')
                  }
                >
                  {'Add Bulk ' + capFirstLetter(module)}
                </span>
              </Button>
            )}
          </>
        )}
        <PageCounter
          page={!totalCount ? 0 : totalCount === 0 ? totalCount : offset + 1}
          count={totalCount || 0}
          next={() => {
            setOffset(
              offset + 1 < Math.ceil((totalCount || 0) / rows)
                ? offset + 1
                : offset
            );
          }}
          per_page={rows}
          previous={() => {
            setOffset(offset > 0 ? offset - 1 : offset);
          }}
        />
        <RowCounter updateRecordsPerPage={updateRecordsPerPage} />
      </div>
    </div>
  );
};

export default withRouter(MainToolbar);
