import {
  ADD_BULK_DEALS,
  ADD_DEAL,
  DEAL_ERRORS,
  GET_BULK_DEAL_TEMPLATE,
  GET_DEALS_LIST,
  GET_DEAL_DETAILS,
  GET_DEAL_HISTORY_DETAILS,
  IS_LOADING,
  SET_DEAL_DETAILS,
  SET_DEAL_HISTORY_DETAILS,
  SET_DEAL_COMMENT_DETAILS,
  SET_DEALS_LIST,
  RESPONSE_MESSAGE,
  UPDATE_DEAL_DETAILS,
  UPDATE_ERRORS,
  DELETE_DEAL,
} from '../constants/Deals';

export const addBulkDeals = (url, data) => {
  return {
    type: ADD_BULK_DEALS,
    payload: {
      url,
      data,
    },
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const dealErrors = (error) => {
  return {
    type: DEAL_ERRORS,
    data: error,
  };
};

export const getDealsList = (payload) => {
  const { rows, offset, qParam, pane } = payload;
  return {
    type: GET_DEALS_LIST,
    payload: {
      limit: rows,
      offset,
      qParam,
      pane,
    },
  };
};

export const setDealsList = (data) => {
  return {
    type: SET_DEALS_LIST,
    data,
  };
};

export const isLoading = () => {
  return {
    type: IS_LOADING,
  };
};

export const addDeal = (data) => {
  return {
    type: ADD_DEAL,
    data,
  };
};

export const updateErrors = (error) => {
  return {
    type: UPDATE_ERRORS,
    payload: error,
  };
};

export const getDealDetails = (id) => {
  return {
    type: GET_DEAL_DETAILS,
    id,
  };
};

export const setDealDetails = (data) => {
  return {
    type: SET_DEAL_DETAILS,
    data,
  };
};

export const updateDealDetails = (data) => {
  return {
    type: UPDATE_DEAL_DETAILS,
    data,
  };
};

export const deleteDeal = (id) => {
  return {
    type: DELETE_DEAL,
    id,
  };
};

export const getBulkDealTemplate = () => {
  return {
    type: GET_BULK_DEAL_TEMPLATE,
  };
};

export const getDealHistoryDetails = (id) => {
  return {
    type: GET_DEAL_HISTORY_DETAILS,
    id,
  };
};

export const setDealHistoryDetails = (data) => {
  return {
    type: SET_DEAL_HISTORY_DETAILS,
    data,
  };
};

export const setDealCommentHistoryDetails = (data) => {
  return {
    type: SET_DEAL_COMMENT_DETAILS,
    data,
  };
};
