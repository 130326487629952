import {
  GET_LEAD_DATA,
  SET_LEAD_DATA,
  GET_OPEN_TASKS_DATA,
  SET_OPEN_TASKS_DATA,
  GET_LEADS_BY_SOURCE_DATA,
  SET_LEADS_BY_SOURCE_DATA,
  GET_NUM_CLOSED_DEALS,
  SET_NUM_CLOSED_DEALS,
  REDIRECT_TO_URL,
  GET_TOP_EMPLOYEE_EMAIL_DATA,
  SET_TOP_EMPLOYEE_EMAIL_DATA,
  GET_DEAL_DEACTIVATE_REASON_DATA,
  SET_DEAL_DEACTIVATE_REASON_DATA,
  GET_AVG_DEAL_CLOSING_RATE,
  SET_AVG_DEAL_CLOSING_RATE,
  GET_DEALS_WON_DATA,
  SET_DEALS_WON_DATA,
  GET_AVG_REVENUE_DATA,
  SET_AVG_REVENUE_DATA,
  GET_NUM_INVESTORS_DATA,
  SET_NUM_INVESTORS_DATA,
} from '../constants/CrmDashboard';

export const redirectToUrl = (url) => {
  return {
    type: REDIRECT_TO_URL,
    payload: {
      url,
    },
  };
};

export const getLeadData = (url) => {
  return {
    type: GET_LEAD_DATA,
    payload: {
      url,
    },
  };
};

export const setLeadData = (data) => {
  return {
    type: SET_LEAD_DATA,
    payload: data,
  };
};

export const getOpenTasksData = (url) => {
  return {
    type: GET_OPEN_TASKS_DATA,
    payload: {
      url,
    },
  };
};

export const setOpenTasksData = (data) => {
  return {
    type: SET_OPEN_TASKS_DATA,
    payload: data,
  };
};

export const getTopEmployeeEmailData = (url) => {
  return {
    type: GET_TOP_EMPLOYEE_EMAIL_DATA,
    payload: {
      url,
    },
  };
};

export const getLeadsBySourceData = (url) => {
  return {
    type: GET_LEADS_BY_SOURCE_DATA,
    payload: {
      url,
    },
  };
};

export const getNumClosedDeals = (url) => {
  return {
    type: GET_NUM_CLOSED_DEALS,
    payload: {
      url,
    },
  };
};

export const setTopEmployeeEmailData = (data) => {
  return {
    type: SET_TOP_EMPLOYEE_EMAIL_DATA,
    payload: data,
  };
};

export const setLeadsBySourceData = (data) => {
  return {
    type: SET_LEADS_BY_SOURCE_DATA,
    payload: data,
  };
};

export const setNumClosedDeals = (data) => {
  return {
    type: SET_NUM_CLOSED_DEALS,
    payload: data,
  };
};

export const getDealDeactivateReasonData = (url) => {
  return {
    type: GET_DEAL_DEACTIVATE_REASON_DATA,
    payload: {
      url,
    },
  };
};

export const setDealDeactivateReasonData = (data) => {
  return {
    type: SET_DEAL_DEACTIVATE_REASON_DATA,
    payload: data,
  };
};

export const getAvgDealClosingRateData = (url) => {
  return {
    type: GET_AVG_DEAL_CLOSING_RATE,
        payload: {
      url,
    },
  };
};

export const getDealWonData = (url) => {
  return {
    type: GET_DEALS_WON_DATA,

    payload: {
      url,
    },
  };
};

export const setAvgDealClosingRateData = (data) => {
  return {
    type: SET_AVG_DEAL_CLOSING_RATE,
        payload: data,
  };
};

export const setDealWonData = (data) => {
  return {
    type: SET_DEALS_WON_DATA,
    payload: data,
  };
};

export const getAvgRevenueData = (url) => {
  return {
    type: GET_AVG_REVENUE_DATA,
    payload: {
      url,
    },
  };
};

export const setAvgRevenueData = (data) => {
  return {
    type: SET_AVG_REVENUE_DATA,
    payload: data,
  };
};

export const getNumInvestorsData = (url) => {
  return {
    type: GET_NUM_INVESTORS_DATA,
    payload: {
      url,
    },
  };
};

export const setNumInvestorsData = (data) => {
  return {
    type: SET_NUM_INVESTORS_DATA,
    payload: data,
  };
};