export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const SET_ACCOUNTS_DATA = 'SET_ACCOUNTS_DATA';
export const LOADING = 'LOADING';
export const ADD_ACCOUNT = 'ADD_ACCOUNT';
export const RESPONSE_MESSAGE = 'RESPONSE_MESSAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const REFRESH = 'REFRESH';
export const ACCOUNT_DETAILS = 'ACCOUNT_DETAILS';
export const UPDATE_ACCOUNT_DETAILS = 'UPDATE_ACCOUNT_DETAILS';
