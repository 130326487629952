import { React, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import './crm-dashboard.css';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';

import {
  Card,
  CardHeader,
  CardBody,
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';

import {
  getLeadData,
  getOpenTasksData,
  getTopEmployeeEmailData,
  getNumClosedDeals,
  getLeadsBySourceData,
  getDealDeactivateReasonData,
  getDealWonData,
  getAvgRevenueData,
  getNumInvestorsData,
  getAvgDealClosingRateData,
} from '../../redux/actions/CrmDashboard';

const CrmDashboard = (props) => {
  const {
    leadData,
    loading,
    openTasksData,
    topEmployeeEmailData,
    numClosedDeals,
    leadsBySourceData,
    dealDeactivateReasonData,
    dealWonData,
    avgRevenueData,
    numInvestors,
    avgDealClosingRateData,
  } = props;

  useEffect(() => {
    props?.getLeadData('/api/dashboard/leadsbymonth/');
    props?.getOpenTasksData('/api/dashboard/taskbyweek/');
    props?.getTopEmployeeEmailData('/api/dashboard/emailbyemp/');
    props?.getLeadsBySourceData('/api/leads/sourceCount');
    props?.getNumClosedDeals('/api/dashboard/dealscount');
    props?.getDealDeactivateReasonData('/api/dashboard/dealclosereasoncount');
    props?.getDealWonData('/api/dashboard/dealswon');
    props?.getAvgRevenueData('/api/dashboard/avg-revenue');
    props?.getNumInvestorsData('/api/dashboard/investors');
    props?.getAvgDealClosingRateData('/api/dashboard/deals-avg-close-time');
  }, [loading]);

  const data1 = typeof leadData === 'string' ? JSON.parse(leadData) : leadData;
  const data2 =
    typeof openTasksData === 'string'
      ? JSON.parse(openTasksData)
      : openTasksData;
  const data3 =
    typeof topEmployeeEmailData === 'string'
      ? JSON.parse(topEmployeeEmailData)
      : topEmployeeEmailData;
  const data4 =
    typeof leadsBySourceData === 'string'
      ? JSON.parse(leadsBySourceData)
      : leadsBySourceData;
  const data6 =
    typeof dealDeactivateReasonData === 'string'
      ? JSON.parse(dealDeactivateReasonData)
      : dealDeactivateReasonData;
  const data7 =
    typeof dealWonData === 'string' ? JSON.parse(dealWonData) : dealWonData;
  const data9 =
    typeof numInvestors === 'string' ? JSON.parse(numInvestors) : numInvestors;

  const chartLeadsBySource = {
    labels: data4.source,
    datasets: [
      {
        label: 'Sources',
        backgroundColor: [
          'rgba(75,192,192,0.4)',
          'rgba(255,99,132,0.4)',
          'rgba(54,162,235,0.4)',
          'rgba(255,206,86,0.4)',
          'rgba(153,102,255,0.4)',
          'rgba(255,159,64,0.4)',
          'rgba(201,203,207,0.4)',
          'rgba(255,99,132,0.4)',
        ],
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: data4.count,
      },
    ],
  };

  const data5 =
    typeof numClosedDeals === 'string'
      ? JSON.parse(numClosedDeals)
      : numClosedDeals;
  const data8 =
    typeof avgRevenueData === 'string'
      ? JSON.parse(avgRevenueData)
      : avgRevenueData;
  const numC = Object.values(data5);

  const data10 =
    typeof avgDealClosingRateData === 'string'
      ? JSON.parse(avgDealClosingRateData)
      : avgDealClosingRateData;
  const avgClosingRate = Object.values(data10);

  const chartTasksData = {
    labels: Object.keys(data2),
    datasets: [
      {
        label: 'New Tasks',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: Object.values(data2).map((data) => data.New),
      },
      {
        label: 'In Progress Tasks',
        backgroundColor: 'rgba(192,75,192,0.4)',
        borderColor: 'rgba(192,75,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(192,75,192,0.6)',
        hoverBorderColor: 'rgba(192,75,192,1)',
        data: Object.values(data2).map((data) => data.Inprocess),
      },
      {
        label: 'Completed Tasks',
        backgroundColor: 'rgba(255, 99, 132, 0.4)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255, 99, 132, 0.6)',
        hoverBorderColor: 'rgba(255, 99, 132, 1)',
        data: Object.values(data2).map((data) => data.Completed),
      },
    ],
  };

  const chartLeadData = {
    labels: Object.keys(data1),
    datasets: [
      {
        label: 'Open Leads',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: Object.values(data1).map((data) => data.open),
      },
      {
        label: 'Closed Leads',
        backgroundColor: 'rgba(192,75,192,0.4)',
        borderColor: 'rgba(192,75,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(192,75,192,0.6)',
        hoverBorderColor: 'rgba(192,75,192,1)',
        data: Object.values(data1).map((data) => data.close),
      },
    ],
  };

  const chartTopEmployeeEmail = {
    labels: Object.keys(data3),
    datasets: [
      {
        label: 'Number of Emails Sent',
        data: Object.values(data3).map((data) => data),
        backgroundColor: 'rgba(192,75,192,0.4)',
        borderColor: 'rgba(192,75,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(192,75,192,0.6)',
        hoverBorderColor: 'rgba(192,75,192,1)',
        //data: Object.values(data3),
      },
    ],
  };

  const chartDealDeactivateReason = {
    labels: data6.reason,
    datasets: [
      {
        label: 'Reasons',
        backgroundColor: [
          'rgba(75,192,192,0.4)',
          'rgba(255,99,132,0.4)',
          'rgba(54,162,235,0.4)',
          'rgba(255,206,86,0.4)',
          'rgba(153,102,255,0.4)',
          'rgba(255,159,64,0.4)',
          'rgba(201,203,207,0.4)',
          'rgba(255,99,132,0.4)',
        ],
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: data6.count,
      },
    ],
  };

  const chartDealWon = {
    labels: Object.keys(data7),
    datasets: [
      {
        label: 'Deals Won',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: Object.values(data7),
      },
    ],
  };

  const chartAvgRevenueData = {
    labels: Object.keys(data8),
    datasets: [
      {
        label: 'Average Revenue Generated',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(75,192,192,0.6)',
        hoverBorderColor: 'rgba(75,192,192,1)',
        data: Object.values(data8),
      },
    ],
  };

  const chartNumInvestors = {
    labels: ['INSTITUTIONAL', 'RETAIL'],
    datasets: [
      {
        label: 'Number of Investors',
        backgroundColor: ['rgba(75,192,192,0.4)', 'rgba(192,75,192,0.4)'],
        borderColor: ['rgba(75,192,192,1)', 'rgba(192,75,192,1)'],
        borderWidth: 2,
        hoverBackgroundColor: ['rgba(75,192,192,0.6)', 'rgba(192,75,192,0.6)'],
        hoverBorderColor: ['rgba(75,192,192,1)', 'rgba(192,75,192,1)'],
        data: [data9.INSTITUTIONAL, data9.RETAIL],
      },
    ],
  };

  return (
    <>
      <StyledToolbar title={'CRM Analytics Dashboard'} />

      <div className="container">
        <div className="box">
          <h2 className="heading">Number of Closed Deals</h2>
          <p className="valuep">{numC}</p>
        </div>
        <div className="box">
          <h2 className="heading">Average Deal Closing Rate</h2>
          <p className="valuep">{avgClosingRate}</p>
        </div>
      </div>
      <div class="chart-pair">
        <div>
          <Card className="chart-card">
            <CardHeader>
              <h4 className="mb-0">Number of Leads</h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartLeadData}
                options={{
                  title: {
                    display: true,
                    text: 'Number of Leads Opened and Closed',
                    fontSize: 20,
                    fontColor: '#444',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        stacked: true,
                        ticks: {
                          fontColor: '#444',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          color: '#ddd',
                        },
                        stacked: true,
                        ticks: {
                          fontColor: '#444',
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  animation: {
                    duration: 1000,
                    easing: 'easeInOutQuad',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>

        <div>
          <Card className="chart-card">
            <CardHeader>
              <h4 className="mb-0">Number of Open Tasks</h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartTasksData}
                options={{
                  title: {
                    display: true,
                    text: 'Number of Open Tasks',
                    fontSize: 20,
                    fontColor: '#444',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  scales: {
                    xAxes: [
                      {
                        gridLines: {
                          display: false,
                        },
                        stacked: true,
                        ticks: {
                          fontColor: '#444',
                        },
                      },
                    ],
                    yAxes: [
                      {
                        gridLines: {
                          color: '#ddd',
                        },
                        stacked: true,
                        ticks: {
                          fontColor: '#444',
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  animation: {
                    duration: 1000,
                    easing: 'easeInOutQuad',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <div class="chart-pair">
        <div>
          <Card className="chart-card">
            <CardHeader>
              <h4 className="mb-0">Top 5 Employee Communication Traction</h4>
            </CardHeader>
            <Bar
              data={chartTopEmployeeEmail}
              options={{
                title: {
                  display: true,
                  text: 'Top 5 Employees Email Count',
                  fontSize: 25,
                },
                legend: {
                  display: false,
                  position: 'right',
                },
              }}
            />
          </Card>
        </div>
        <div>
          <Card className="chart-card">
            <CardHeader>
              <p></p>
              <h4 className="mb-0"> Number of Leads By Source </h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartLeadsBySource}
                options={{
                  title: {
                    display: true,
                    text: 'Leads by Source',
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <div class="chart-pair">
        <div>
          <Card className="chart-card">
            <CardHeader>
              <p></p>
              <h4 className="mb-0"> Deal Deactivate Reason Count </h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartDealDeactivateReason}
                options={{
                  title: {
                    display: true,
                    text: 'Deal Deactivate Reason',
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
        <div>
          <Card className="chart-card">
            <CardHeader>
              <p></p>
              <h4 className="mb-0"> Number of Leads By Source </h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartLeadsBySource}
                options={{
                  title: {
                    display: true,
                    text: 'Leads by Source',
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <div class="chart-pair">
        <div>
          <Card className="chart-card">
            <CardHeader>
              <p></p>
              <h4 className="mb-0"> Deal Own Over Time </h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartDealWon}
                options={{
                  title: {
                    display: true,
                    text: 'Deal Won Over Time',
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>

        <div>
          <Card className="chart-card">
            <CardHeader>
              <p></p>
              <h4 className="mb-0"> Average Revenue Generated Each Month </h4>
            </CardHeader>
            <CardBody>
              <Bar
                data={chartAvgRevenueData}
                options={{
                  title: {
                    display: true,
                    text: 'Avg Revenue Generated',
                    fontSize: 25,
                  },
                  legend: {
                    display: true,
                    position: 'right',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
      <div>
        <div className="chart-pair">
          <Card className="chart-card">
            <CardHeader>
              <h4 className="mb-0">
                Number of Retail and Institutional Investors
              </h4>
            </CardHeader>
            <CardBody>
              <Pie
                data={chartNumInvestors}
                options={{
                  title: {
                    display: true,
                    text: 'Number of Retail and Institutional Investors',
                    fontSize: 20,
                    fontColor: '#444',
                  },
                  legend: {
                    display: true,
                    position: 'bottom',
                  },
                  responsive: true,
                  maintainAspectRatio: false,
                  animation: {
                    duration: 1000,
                    easing: 'easeInOutQuad',
                  },
                }}
              />
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    dashboardLoading: loading,
    leadData,
    openTasksData,
    topEmployeeEmailData,
    leadsBySourceData,
    numClosedDeals,
    dealDeactivateReasonData,
    avgDealClosingRateData,
    dealWonData,
    avgRevenueData,
    numInvestors,
  } = state.crmDashboard;

  return {
    loading,
    leadData,
    openTasksData,
    topEmployeeEmailData,
    leadsBySourceData,
    numClosedDeals,
    dealDeactivateReasonData,
    avgDealClosingRateData,
    dealWonData,
    avgRevenueData,
    numInvestors,
  };
};

const mapDispatchToProps = {
  getLeadData,
  getOpenTasksData,
  getTopEmployeeEmailData,
  getLeadsBySourceData,
  getNumClosedDeals,
  getDealDeactivateReasonData,
  getDealWonData,
  getAvgRevenueData,
  getNumInvestorsData,
  getAvgDealClosingRateData,
};

export default connect(mapStateToProps, mapDispatchToProps)(CrmDashboard);
