import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { service } from '../../service';
import {
  responseMessage,
  setInvestorsList,
  updateErrors,
  setInvestorDetails,
  investorErrors
} from '../actions/Investors';
import {
  ADD_INVESTOR,
  GET_INVESTORS_LIST,
  GET_BULK_INVESTOR_TEMPLATE,
  DELETE_INVESTOR,
  GET_INVESTOR_DETAILS,
  UPDATE_INVESTOR_DETAILS,
  ADD_BULK_INVESTORS,
} from '../constants/Investors';

export function* getInvestorsList() {
  yield takeEvery(GET_INVESTORS_LIST, function* ({ payload }) {
    let { limit, offset, qParam } = payload;
    try {
      let url = `/api/investors/?limit=${limit}&offset=${offset || 0}`;
      url = qParam ? url + '&' + qParam : url;
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setInvestorsList(response.data));
        yield put(responseMessage(false));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[i] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* addInvestor() {
  yield takeEvery(ADD_INVESTOR, function* ({ data }) {
    try {
      let response = yield call(service.post, '/api/investors/', data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[i] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* getBulkInvestorsTemplate() {
  // eslint-disable-next-line require-yield
  yield takeEvery(GET_BULK_INVESTOR_TEMPLATE, function* ({ investor_type }) {
    const xReq = new XMLHttpRequest();
    if (investor_type === 'RETAIL') {
      xReq.open(
        'GET',
        'http://localhost:8000/api/investors/template/retail',
        true
      );
    } else {
      xReq.open(
        'GET',
        'http://localhost:8000/api/investors/template/institutional',
        true
      );
    }
    xReq.responseType = 'blob';
    xReq.setRequestHeader('Accept', 'application/json');
    xReq.setRequestHeader(
      'Authorization',
      `jwt ${localStorage.getItem('Token')}`
    );
    xReq.setRequestHeader('org', localStorage.getItem('company'));

    xReq.onload = function (e) {
      if (xReq.status === 200 && xReq.readyState === 4) {
        const blob = xReq.response;
        const fileName = xReq
          .getResponseHeader('content-disposition')
          .split('filename=')[1];
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob);
        } else {
          const downloadLink = window.document.createElement('a');
          const contentTypeHeader = xReq.getResponseHeader('Content-Type');
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    };
    xReq.send();
  });
}

export function* deleteInvestorById() {
  yield takeEvery(DELETE_INVESTOR, function* ({ id }) {
    try {
      let response = yield call(service.delete, `/api/investors/${id}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(updateErrors(error));
    }
  });
}

export function* getInvestorDetails() {
  yield takeEvery(GET_INVESTOR_DETAILS, function* ({ id }) {
    try {
      let response = yield call(service.get, `/api/investors/${id}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(setInvestorDetails(response.data.data));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(updateErrors(error));
    }
  });
}

export function* updateInvestorDetails() {
  yield takeEvery(UPDATE_INVESTOR_DETAILS, function* ({ data }) {
    try {
      let response = yield call(
        service.patch,
        `/api/investors/${data.id}`,
        data,
        {
          headers: {
            Authorization: `jwt ${localStorage.getItem('Token')}`,
            org: localStorage.getItem('company'),
          },
        }
      );
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[i] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* addBulkInvestors() {
  yield takeEvery(ADD_BULK_INVESTORS, function* ({ payload }) {
    let { file, investor_type } = payload;
    console.log({ file, investor_type });
    try {
      const formData = new FormData();
      formData.append('investor_attachment', file);
      let url = '';
      if (investor_type === 'RETAIL') {
        url = '/api/investors/bulk/retail';
      } else {
        url = '/api/investors/bulk/institutional';
      }
      console.log({ url });
      let response = yield call(service.post, url, formData, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });

      if (!response.data.error) {
        yield put(responseMessage(response.data.message));
        yield put(investorErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        err = `At Row ${error.response.data.row + 1} : ${JSON.stringify(
          error.response.data.message
        )}`;
      }
      yield put(responseMessage(false));
      yield put(investorErrors(err));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addInvestor),
    fork(getInvestorsList),
    fork(getBulkInvestorsTemplate),
    fork(deleteInvestorById),
    fork(addBulkInvestors),
  ]);
}
