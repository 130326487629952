import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Menu,
  Select,
  Breadcrumb,
  Button,
  Upload,
  InputNumber,
} from 'antd';
import {
  PlusOutlined,
  VerticalAlignTopOutlined,
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { addLead } from '../../redux/actions/Leads';
import { getProfiles } from '../../redux/actions/Profiles';
import ReactQuill from 'react-quill';
import { rules } from '../common/rules';
import { modules, formats } from '../common/quillConfig';
import { getContacts } from '../../redux/actions/Contacts';
import { layout } from '../common/layout';
import { ContactDrawer } from './components/ContactDrawer';
import './leads.css';
import SkeletonInput from 'antd/lib/skeleton/Input';
import { getConfirmLocale } from 'antd/lib/modal/locale';
import ContactsAutoSearch from '../contacts/ContactsAutoSearch';
import Address from '../../components/ui-components/Address';
import { StyledToolbar } from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';

const { Panel } = Collapse;
const { Option } = Select;

export const AddLead = (props) => {
  // useEffect(() => {
  //   // props.getProfiles()
  //   // props.getContacts(`/api/contacts/`, 0);

  // }, [])

  const [form] = Form.useForm();

  const onContactSelected = (contactObj) => {
    form.setFieldsValue({
      contacts: [contactObj.id],
    });
  };

  const { responseMessage, errors, profiles, contacts } = props;

  const currencyList = (
    <Select defaultValue="CAD">
      <Option value="CAD">CAD</Option>
      <Option value="INR">INR</Option>
      <Option value="USD">USD</Option>
    </Select>
  );

  const [contactDrawer, setContactDrawer] = useState(false);

  const contactToShow = contacts ? contacts.contact_obj_list : [];

  const displayContactDrawer = () => {
    setContactDrawer(true);
  };

  const addLead = (e) => {
    props.addLead(e);
  };

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/leads');
    }
  }, [responseMessage]);

  return (
    <div className="add-lead">
      <StyledToolbar module="leads" onSubmit={()=>form.submit()} mode = 'add'/>
      <div className="add-form">
        <Form
          form={form}
          {...layout}
          onFinish={addLead}
          style={{ background: '#fff', padding: '20px' }}
        >
          <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
            <Panel header="Leads Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Lead Title"
                    name="title"
                    rules={rules.title}
                    // validateStatus="error"
                    // help={(errors?.title) ? errors.title : false}
                  >
                    <Input className="required" />
                  </Form.Item>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    rules={[{ required: true }]}
                  >
                    <Input className="required" />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    rules={[{ required: true }]}
                  >
                    <Input className="required" />
                  </Form.Item>

                  <Form.Item
                    label="E-Mail ID"
                    name="email"
                    rules={[{ type: 'email', required: true }]}
                  >
                    <Input className="required" />
                  </Form.Item>

                  {/* <Form.Item label="Skype ID" name="skype_ID">
                    <Input />
                  </Form.Item> */}

                  <Form.Item
                    label="Organization"
                    name="organization"
                    rules={[{ required: true }]}
                  >
                    <Input className="required" />
                  </Form.Item>

                  <Form.Item label="Website" name="website">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="Assigned To" name="assigned_to">
                    <Select mode="multiple" className="required">
                      <Option key={profiles.id}>
                        {profiles?.user_details?.first_name}
                      </Option>
                      <Option key="a-2">Assignee two</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Status"
                    name="status"
                    className="ant-dropdown-bg"
                    rules={[{ required: true }]}
                  >
                    <Select className="required">
                      <Option key="Assigned">Assigned</Option>
                      <Option key="In Process">In Process</Option>
                      <Option key="Converted">Converted</Option>
                      <Option key="Recycled">Recycled</Option>
                      <Option key="Closed">Closed</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label="Source"
                    name="source"
                    className="ant-dropdown-bg"
                    rules={[{ required: true }]}
                  >
                    <Select className="required">
                      <Option key="Call">Call</Option>
                      <Option key="Email">Email</Option>
                      <Option key="Existing">Existing Customer</Option>
                      <Option key="Partner">Partner</Option>
                      <Option key="Public Relations">Public Relations</Option>
                      <Option key="Campaign">Campaign</Option>
                      <Option key="Other">Other</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item label="Contact Name" name="contacts">
                    <ContactsAutoSearch onContactSelected={onContactSelected} />
                  </Form.Item>

                  <Form.Item
                    label="Amount"
                    name="opportunity_amount"
                    rules={[{ required: true }]}
                    // rules={rules.amount}
                  >
                    <Input addonAfter={currencyList} className="required" />
                  </Form.Item>

                  {/* <Form.Item label="Probability" name="probability">

                    <InputNumber min={1} max={100} />
                  </Form.Item> */}

                  <Form.Item
                    label="Lead label"
                    name="lead_label"
                    className="ant-dropdown-bg"
                    rules={[{ required: true }]}
                  >
                    <Select className="required">
                      <Option key="Hot">Hot</Option>
                      <Option key="Warm">Warm</Option>
                      <Option key="Cold">Cold</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>

            <Panel
              header="Address Details"
              key="2"
              style={{ marginTop: '10px' }}
            >
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Address Lane"
                    name="address_lane"
                    // rules={rules.addressLane}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Street" name="street">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Postal Code" name="pincode">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="City" name="city">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Province" name="state">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header="Description" key="3" style={{ marginTop: '10px' }}>
              <Form.Item
                label="Description"
                name="description"
                style={{ width: '100%' }}
              >
                <ReactQuill modules={modules} formats={formats} />
              </Form.Item>
            </Panel>
          </Collapse>

          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
            >
              <CloseCircleOutlined />
              <span>Cancel</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
          </Row>
        </Form>
        <ContactDrawer
          open={contactDrawer}
          setContactDrawer={setContactDrawer}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { responseMessage, errors } = state.leads;
  const { profiles } = state.profiles;
  const { contacts } = state.contacts;
  return { responseMessage, errors, profiles, contacts };
};

const mapDispatchToProps = {
  addLead,
  getProfiles,
  getContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddLead);
