import {
  GET_ACCOUNTS,
  SET_ACCOUNTS_DATA,
  LOADING,
  ADD_ACCOUNT,
  RESPONSE_MESSAGE,
  UPDATE_ERRORS,
  DELETE_ACCOUNT,
  REFRESH,
  ACCOUNT_DETAILS,
  UPDATE_ACCOUNT_DETAILS,
} from '../constants/Accounts';

export const getAccounts = (url, offset, bool) => {
  return {
    type: GET_ACCOUNTS,
    payload: {
      url,
      offset,
      bool,
    },
  };
};

export const setAccountsData = (data) => {
  return {
    type: SET_ACCOUNTS_DATA,
    payload: data,
  };
};

export const loading = (bool) => {
  return {
    type: LOADING,
    payload: bool,
  };
};

export const addAccount = (data) => {
  return {
    type: ADD_ACCOUNT,
    payload: data,
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const updateErrors = (error) => {
  return {
    type: UPDATE_ERRORS,
    payload: error,
  };
};

export const deleteAccount = (id, bool) => {
  return {
    type: DELETE_ACCOUNT,
    payload: {
      id,
      bool,
    },
  };
};

export const refresh = (bool) => {
  return {
    type: REFRESH,
    payload: bool,
  };
};

export const getAccountDetails = (url) => {
  return {
    type: ACCOUNT_DETAILS,
    url,
  };
};

export const updateAccountDetails = (data) => {
  return {
    type: UPDATE_ACCOUNT_DETAILS,
    data,
  };
};
