import { withRouter } from 'react-router-dom';
import { Button, Row, Form, Col, Input, Select } from 'antd';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';
import './FilterToolbar.css';
const { Option } = Select;

export const FilterToolbar = (props) => {
  const { columns, isVisible, onSearch, onClear } = props;
  const [form] = Form.useForm();
  const listValue = (formVals) => {
    var qParam = '';
    Object.entries(formVals)
      .filter(([, value]) => !!value)
      .forEach(([key, value]) => (qParam += key + '=' + value + '&'));
    qParam = qParam.length > 0 ? qParam.substring(0,qParam.length-1):qParam;
    onSearch(qParam);
  };

  function getFormItem(element) {
    if (element.type === 'text') {
      return (
        <Col span={6}>
          <Form.Item
            key={element.name}
            label={element.label}
            name={element.name}
          >
            <Input />
          </Form.Item>
        </Col>
      );
    }
    if (element.type === 'dropdown') {
      return (
        <Col span={6}>
        <Form.Item style={{ width: '100%' }} key={element.name} label={element.label} name={element.name}>
          <Select >
            {element.options.map((option) => (
              <Option key={option.value} value={option.value}>
                {option.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        </Col>
      );
    }
  }

  return (
    <>
      {isVisible ? (
        <div className="filter-toolbar">
          <Form style={{ padding: '10px' }} form={form} onFinish={listValue}>
            <Row justify="start">{columns.map(getFormItem)}</Row>
            <Row>
              <Col
                span={24}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Button type="primary" className="btn fw-12 fw-6">
                  <SearchOutlined />
                  <span
                    onClick={() => {
                      form.submit();
                    }}
                  >
                    Search
                  </span>
                </Button>
                <Button type="primary" className="btn fw-12 fw-6">
                  <ClearOutlined />
                  <span
                    onClick={() => {
                      form.resetFields();
                      onClear();
                    }}
                  >
                    Clear
                  </span>
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default withRouter(FilterToolbar);
