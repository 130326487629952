import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Select,
  Button,
  Breadcrumb,
  DatePicker,
  Modal,
} from 'antd';
import JoditEditor from 'jodit-react';
import {
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import TemplateGrid from '../templates/TemplateGrid';
import { getProfiles } from '../../redux/actions/Profiles';
import {
  updateCampaignDetails,
  getCampaignDetails,
  loading,
  responseMessage as setResponseMessage,
} from '../../redux/actions/Campaigns';
import {
  getTemplatesServerAction
} from '../../redux/actions/EmailTemplates';
import { putData } from '../../redux/actions/Fetch';
import ProfileAutoSearch from '../users/UsersAutoSearch';
import moment from 'moment';

const { Panel } = Collapse;
const { Option } = Select;

export const DetailCampaign = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getTemplatesServerAction();
  }, []);

  useEffect(() => {
    props.getCampaignDetails(`/api/campaigns/${props.match.params.id}/`);
  }, [props.match.params.id]);

  const { responseMessage, errors, profiles } = props;
  const [emailContent, setEmailContent] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactsList, setContactsList] = useState([]);
  const [templateId, setTemplateId] = useState(-1);
  const campaigns = useSelector((app) => app.campaigns);
  const campaignDetails = campaigns.campaignDetails;
  const recipients = campaignDetails.recipients;
  const templates = campaigns.templates;
  const currentTemplate = templates.find((t) => t.id === templateId);
  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const onUserSelected = (userObj) => {
    setContactsList(id => [...id, userObj.id])
    console.log('come on', contactsList)
    form.setFieldsValue({
      contacts_source: contactsList,
    });
  };

  const onUserDeSelected = (userObj) => {
    const index = contactsList.indexOf(userObj.id)
    if (index !== -1) {
      setContactsList([
        ...contactsList.slice(0, index),
        ...contactsList.slice(index + 1)
      ])

      form.setFieldsValue({
        contacts_source: contactsList,
      });
    }
  }

  const updateFormDetails = (campaign) => {
    const scheduled_date_time = campaign.scheduled_date_time
      ? moment(campaign.scheduled_date_time)
      : '';

    let recipients

    if (campaign.recipients) {
      recipients = campaign.recipients.map(user => {
        return user.id
      })
      setContactsList([...recipients])
    }

    const details = {
      name: campaign.name,
      scheduled_later: campaign.scheduled_later ? 'True' : 'False',
      from_email: campaign.from_email,
      scheduled_date_time,
      timezone: campaign.timezone,
      subject: campaign.email_subject,
      email_content: campaign.email_content,
      contacts_source: recipients
    };

    form.setFieldsValue({
      ...details,
    });
  };

  useEffect(() => {
    updateFormDetails(campaignDetails);
  }, [campaignDetails]);

  useEffect(() => {
    form.setFieldsValue(
      {
        contacts_source: contactsList
      },
    );
  }, [contactsList])

  const onChangeEditor = (e) => {
    form.setFieldsValue({
      email_content: e,
    });
  };

  const onSelectCard = (template) => {
    setTemplateId(template.id);
    form.setFieldValue({
      email_content: template.content,
    });
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const hideModel = () => {
    setIsModalOpen(false);
  };

  const updateCampaign = (e) => {
    const id = props.match.params.id
    props.putData(
        `api/campaigns/${id}/`,
        'campaigns',
        e,
        id,
    )
  };

  const returnCampaign = () => {
    props.history.push('/home/campaigns')
  }

  useEffect(() => {
      console.log(responseMessage)
      if (responseMessage === 'success') {
          props.setResponseMessage('')
          returnCampaign()
      }
  }, [responseMessage])

  return (
    <div className="update-campaign">
      <Row className="update-toolbar">
        <Breadcrumb className="update-toolbar-breadcrumb">
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/campaigns">Campaigns</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row
        className="update-toolbar-buttons"
        style={{ padding: '10px 5px 10px 0' }}
      >
        <Button className="btn" onClick={returnCampaign}>
          <LeftOutlined />
          <span>Back to Campaigns</span>
        </Button>

        <Button type="primary" className="btn" danger onClick={returnCampaign}>
          <CloseCircleOutlined />
          <span>Clsoe</span>
        </Button>

        <Button type="primary" className="btn" htmlType='submit' onClick={() => {updateCampaign(form.getFieldsValue())}}>
          <CheckOutlined />
          <span>Save</span>
        </Button>
      </Row>

      <Form
        form={form}
        // labelCol={{ span: 12 }}
        // wrapperCol={{ span: 16 }}
        onFinish={updateCampaign}
        style={{ background: '#fff', padding: '20px' }}
      >
        <Collapse expandIconPosition="end" defaultActiveKey={['11']}>
          <Panel header="Edit Campaign Detail" key="11">
            <Row justify="start">
              <Col span={12} style={{ paddingRight: '10px' }}>
                <Form.Item label="Title" name="name">
                  <Input placeholder="Test" />
                </Form.Item>

                <Form.Item label="Schedule Later" name="scheduled_later">
                  <Select>
                    <Option key="true">True</Option>

                    <Option key="false">False</Option>
                  </Select>
                </Form.Item>

                <Form.Item label="From Email" name="from_email">
                  <Input placeholder="From Email" />
                </Form.Item>

                <Form.Item label="Email Template" name="template">
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Input
                      placeholder=""
                      disabled
                      value={currentTemplate ? currentTemplate.title : ''}
                    />
                    <Button
                      onClick={() => showModal()}
                      style={{
                        marginLeft: '4px',
                      }}
                    >
                      <span>Select Template</span>
                    </Button>
                  </div>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Recipients" name="contacts_source">
                  <ProfileAutoSearch 
                    preFilledValue={recipients} 
                    onUserSelected={onUserSelected} 
                    onUserDeSelected={onUserDeSelected}
                  />
                </Form.Item>

                <Form.Item
                  label="Scheduled Date Time"
                  name="scheduled_date_time"
                >
                  <DatePicker
                    // defaultValue={moment('2023-01-30T12:32:26.551Z')}
                    format={dateFormat}
                    showTime
                    style={{ width: '100%' }}
                  />
                </Form.Item>

                <Form.Item label="Time Zone" name="timezone">
                  <Input placeholder="UTC" />
                </Form.Item>

                <Form.Item label="Email Subject" name="subject">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <Collapse expandIconPosition="end" defaultActiveKey={['22']}>
          <Panel header="Email Content" key="22" style={{ width: '100%' }}>
            <Form.Item name="email_content">
              <div style={{ width: '100%' }}>
                <JoditEditor
                  width={1000}
                  style={{
                    width: '100%',
                  }}
                  className="joeditor"
                  onChange={onChangeEditor}
                  value={campaignDetails ? campaignDetails.email_content : ''}
                />
              </div>
            </Form.Item>
          </Panel>
        </Collapse>

        <Row
          justify="center"
          style={{
            marginTop: '20px',
          }}
        >
          <Button type="primary" className="btn" danger onClick={returnCampaign}>
            <CloseCircleOutlined />
            <span>Clsoe</span>
          </Button>

          <Button type="primary" className="btn" htmlType='submit'>
            <CheckOutlined />
            <span>Save</span>
          </Button>
        </Row>
      </Form>

      <div>
        <Modal
          title="Select Email Template"
          open={isModalOpen}
          onCancel={hideModel}
          onOk={hideModel}
          width={1000}
          height={500}
        >
          <div
            style={{
              height: '100%',
              overflow: 'scroll',
            }}
          >
            <TemplateGrid data={templates} onClickCard={onSelectCard} />
          </div>
        </Modal>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { responseMessage, errors } = state.campaigns;
  const { profiles } = state.profiles;
  return { responseMessage, errors, profiles };
};

const mapDispatchToProps = {
  updateCampaignDetails,
  getCampaignDetails,
  getProfiles,
  getTemplatesServerAction,
  putData,
  setResponseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailCampaign);
