export const ADD_BULK_DEALS = 'ADD_BULK_DEALS';
export const ADD_DEAL = 'ADD_DEAL';
export const GET_DEALS_LIST = 'GET_DEALS_LIST';
export const SET_DEALS_LIST = 'SET_DEALS_LIST';
export const DEAL_ERRORS = 'DEAL_ERRORS'
export const RESPONSE_MESSAGE = 'DEAL_RESPONSE_MESSAGE'
export const IS_LOADING = 'IS_LOADING'
export const UPDATE_ERRORS = 'DEAL_UPDATE_ERRORS'
export const GET_DEAL_DETAILS = 'GET_DEAL_DETAILS'
export const SET_DEAL_DETAILS = 'SET_DEAL_DETAILS'
export const UPDATE_DEAL_DETAILS = 'UPDATE_DEAL_DETAILS'
export const DELETE_DEAL = 'DELETE_DEAL'
export const GET_BULK_DEAL_TEMPLATE = 'GET_BULK_DEAL_TEMPLATE'
export const SET_BULK_DEAL_TEMPLATE = 'SET_BULK_DEAL_TEMPLATE'

export const GET_DEAL_HISTORY_DETAILS = 'GET_DEAL_HISTORY_DETAILS'
export const SET_DEAL_HISTORY_DETAILS = 'SET_DEAL_HISTORY_DETAILS'
export const SET_DEAL_COMMENT_DETAILS = 'SET_DEAL_COMMENT_DETAILS'