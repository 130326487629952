import {
  DeleteOutlined,
  EditOutlined,
  HistoryOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import { Table, Tabs, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import FilterToolbar from '../../components/ui-components/toolbar/filterToolbar/FilterToolbar';
import { getDealStatusLabel } from './util';
import { deleteDeal, getDealsList, isLoading } from '../../redux/actions/Deals';
import { getCommentsHistory } from '../../redux/actions/Common';
import CommentsHistory from '../common/CommentsHistory';
import { SET_DEAL_COMMENT_DETAILS } from '../../redux/constants/Deals';
import { MainToolbar } from '../../components/ui-components/toolbar/mainToolbar/MainToolbar';

const { TabPane } = Tabs;

export const DealsList = (props) => {
  const { deals, loading, responseMessage, dealCommentHistory } = props;
  const [rows, setRows] = useState(10);
  const [offset, setOffset] = useState(0);
  const [qParam, setQParam] = useState();
  const [pane, setPane] = useState('OPEN');
  const [displayFilters, setDisplayFilters] = useState(false);
  const [dealCommentHistoryVisible, setDealCommentHistoryVisible] =
    useState(false);
  const dispatch = useDispatch();

  const filterColumn = [
    { label: 'Deal Name', name: 'deal_name', type: 'text' },
    { label: 'Company', name: 'company', type: 'text' },
    { label: 'Account Manager', name: 'account_manager', type: 'text' },
    {
      label: 'Deal Type',
      name: 'deal_type',
      type: 'dropdown',
      options: [
        { label: 'Debt', value: 'DEBT' },
        { label: 'Acquisition', value: 'ACQUISITION' },
        { label: 'Merger', value: 'MERGER' },
        { label: 'Equity', value: 'EQUITY' },
        { label: 'Management Buyout', value: 'MANAGEMENT_BUYOUT' },
      ],
    },
  ];
  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const deleteDeal = (id) => {
    props.deleteDeal(id);
  };

  const routeToDetails = (id) => {
    props.history.push(`/home/deals/${id}/details`);
  };

  const routeToHistory = (id) => {
    props.history.push(`/home/deals/${id}/dealhistory`);
  };
  useEffect(() => {
    props.getDealsList({ rows, offset, qParam, pane });
  }, [loading, rows, offset, qParam, pane]);

  useEffect(() => {
    if (responseMessage === true) {
      props.getDealsList({ rows, offset, pane });
    }
  }, [responseMessage]);

  useEffect(() => {
    if (dealCommentHistory?.length > 0) {
      setDealCommentHistoryVisible(true);
    } else {
      setDealCommentHistoryVisible(false);
    }
  }, [dealCommentHistory]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <p onClick={() => routeToDetails(id)}>{id}</p>,
    },
    {
      title: 'Deal Name',
      dataIndex: 'deal_name',
      key: 'deal_name',
      render: (deal_name) => <p>{deal_name}</p>,
    },
    {
      title: 'Company',
      dataIndex: 'company',
      key: 'company',
      render: (company) => <p>{company}</p>,
    },
    {
      title: 'Account Manager',
      dataIndex: 'account_manager',
      key: 'account_manager',
      render: (account_manager) => <p>{account_manager}</p>,
    },
    {
      title: 'Deal Type',
      dataIndex: 'deal_type',
      key: 'deal_type',
      render: (deal_type) => <p>{deal_type}</p>,
    },
    {
      title: 'Deal Status',
      dataIndex: 'deal_status',
      key: 'deal_status',
      render: (deal_status) => <p>{getDealStatusLabel(deal_status)}</p>,
    },
    {
      title: 'Deal Close Date',
      dataIndex: 'deal_close_date',
      key: 'deal_close_date',
      render: (deal_close_date) => <p>{deal_close_date}</p>,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (deal, row) => (
        <>
          <span
            className="edit"
            style={{ cursor: 'pointer' }}
            onClick={() => routeToDetails(row.id)}
          >
            <Tooltip title="Edit Deal">
              <EditOutlined />
            </Tooltip>
          </span>
          <span
            className="delete"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => deleteDeal(row.id)}
          >
            <Tooltip title="Delete Deal">
              <DeleteOutlined />
            </Tooltip>
          </span>
          <span
            className="history"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => routeToHistory(row.id)}
          >
            <Tooltip title="Deal History">
              <HistoryOutlined />
            </Tooltip>
          </span>
          <span
            className="notesHistory"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => {
              props.getCommentsHistory('deals', deal.id);
            }}
          >
            <Tooltip title="Notes History">
              <ReadOutlined />
            </Tooltip>
          </span>
        </>
      ),
    },
  ];

  const updateKey = (e) => {
    setOffset(0);
    switch (e) {
      case '0':
        setPane('DRAFT');
        break;
      case '1':
        setPane('OPEN');
        break;
      case '2':
        setPane('CLOSED');
        break;
      case '3':
        setPane('DEACTIVATED');
        break;
      case '4':
        setPane('HOLD');
        break;
      default:
        setPane('');
    }
  };

  return (
    <>
      <MainToolbar
        setDisplayFilters={setDisplayFilters}
        displayFilters={displayFilters}
        offset={offset}
        setOffset={setOffset}
        totalCount={deals.totalCount}
        rows={rows}
        updateRecordsPerPage={updateRecordsPerPage}
        module="deals"
        history={props.history}
        hasBulkUpload
        buttons={[{ url: 'deals/new', label: 'Add Deal' }]}
      />
      <FilterToolbar
        columns={filterColumn}
        isVisible={displayFilters}
        onSearch={(qParam) => {
          setQParam(qParam);
        }}
        onClear={() => {
          setQParam(undefined);
        }}
      />
      <div className="list-table">
        <Tabs onChange={updateKey} type="card" defaultActiveKey="1">
          <TabPane tab="Draft" key="0"></TabPane>
          <TabPane tab="Open" key="1"></TabPane>
          <TabPane tab="Closed" key="2"></TabPane>
          <TabPane tab="Deactivated" key="3"></TabPane>
          <TabPane tab="Hold" key="4"></TabPane>
        </Tabs>
        <CommentsHistory
          commentsArray={dealCommentHistory}
          visible={dealCommentHistoryVisible}
          onCancel={() => {
            dispatch({ type: SET_DEAL_COMMENT_DETAILS, data: [] });
          }}
        />

        <Table
          rowKey={(deal) => deal.id}
          columns={columns}
          dataSource={deals.data}
          pagination={false}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    deals,
    dealsLoading: loading,
    responseMessage,
    dealCommentHistory,
  } = state.deals;
  return { deals, loading, responseMessage, dealCommentHistory };
};

const mapDispatchToProps = {
  getDealsList,
  isLoading,
  deleteDeal,
  getCommentsHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(DealsList);
