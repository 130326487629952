import {
  GET_TASKS,
  SET_TASKS_DATA,
  LOADING,
  ADD_TASK,
  RESPONSE_MESSAGE,
  UPDATE_ERRORS,
  DELETE_TASK,
  REFRESH,
  TASK_DETAILS,
  UPDATE_TASK_DETAILS,
  TASK_UPDATED,
  TASK_NOTES,
  UPDATE_TASK_NOTES,
  ADD_TASK_NOTE,
} from '../constants/Tasks';

export const getTasks = (url, offset, bool) => {
  return {
    type: GET_TASKS,
    payload: {
      url,
      offset,
      bool,
    },
  };
};

export const setTasksData = (data) => {
  return {
    type: SET_TASKS_DATA,
    payload: data,
  };
};

export const loading = (bool) => {
  return {
    type: LOADING,
    payload: bool,
  };
};

export const addTask = (data) => {
  return {
    type: ADD_TASK,
    payload: data,
  };
};

export const responseMessage = (msg) => {
  return {
    type: RESPONSE_MESSAGE,
    payload: msg,
  };
};

export const updateErrors = (error) => {
  return {
    type: UPDATE_ERRORS,
    payload: error,
  };
};

export const deleteTask = (id, bool) => {
  return {
    type: DELETE_TASK,
    payload: {
      id,
      bool,
    },
  };
};

export const refresh = (bool) => {
  return {
    type: REFRESH,
    payload: bool,
  };
};

export const getTaskDetails = (url) => {
  return {
    type: TASK_DETAILS,
    url,
  };
};

export const updateTaskDetails = (data) => {
  return {
    type: UPDATE_TASK_DETAILS,
    data,
  };
};

export const addTaskNote = (data) => {
  return {
    type: ADD_TASK_NOTE,
    payload: data,
  };
};

export const getTaskNotes = (url) => {
  return {
    type: TASK_NOTES,
    url,
  };
};

export const updateTaskNotes = (data) => {
  return {
    type: UPDATE_TASK_NOTES,
    data,
  };
};

export const isTaskUpdated = (val) => {
  return {
    type: TASK_UPDATED,
    val,
  };
};
