import {
  GETDATA_WITHHEADER,
  GET_AUTOSEARCH_DATA,
  POSTDATA_WITHHEADER,
  REDIRECT_TO_URL,
  SET_AUTOSEARCH_DATA,
  GET_COMMENTS_HISTORY,
  SET_COMMENTS_HISTORY,
} from '../constants/Common';

export const getAutoSearchData = (url, param, obj) => {
  return {
    type: GET_AUTOSEARCH_DATA,
    payload: {
      url,
      param,
      obj,
    },
  };
};

export const setAutoSearchData = (data) => {
  return {
    type: SET_AUTOSEARCH_DATA,
    payload: data,
  };
};

export const getDataWithHeader = (url, param, obj) => {
  return {
    type: GETDATA_WITHHEADER,
    payload: {
      url,
      param,
      obj,
    },
  };
};

export const postDataWithHeader = (url, data, redirectUrl) => {
  return {
    type: POSTDATA_WITHHEADER,
    payload: {
      url,
      data,
      redirectUrl,
    },
  };
};

export const redirectToUrl = (url) => {
  return {
    type: REDIRECT_TO_URL,
    payload: {
      url,
    },
  };
};

export const getCommentsHistory = (modules, id) => {
  return {
    type: GET_COMMENTS_HISTORY,
    payload: {
      modules,
      id,
    },
  };
};