import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Select,
  Breadcrumb,
  Button,
  DatePicker,
} from 'antd';
import {
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { addTask } from '../../redux/actions/Tasks';
import { layout } from '../common/layout';
import { getProfiles } from '../../redux/actions/Profiles';
import { getContacts } from '../../redux/actions/Contacts';
import { ContactDrawer } from '../leads/components/ContactDrawer';
import '../leads/leads.css';
import ContactsAutoSearch from '../contacts/ContactsAutoSearch';

const { Panel } = Collapse;
const { Option } = Select;

export const AddTask = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getContacts(`/api/contacts/`, 0);
  }, []);

  const [form] = Form.useForm();

  const onContactSelected = (contactObj) => {
    form.setFieldsValue({
      contacts: [contactObj.id],
    });
  };

  const { responseMessage, errors, profiles, contacts } = props;

  const [contactDrawer, setContactDrawer] = useState(false);
  const dateFormat = 'YYYY-MM-DD';

  const contactToShow = contacts ? contacts.contact_obj_list : [];
  console.log(contactToShow);

  console.log(profiles);
  const displayContactDrawer = () => {
    setContactDrawer(true);
  };

  const addTask = (e) => {
    console.log(e);
    console.log(props);
    props.addTask(e);
  };

  console.log(props);
  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/tasks');
    }
  }, [responseMessage]);

  return (
    <div className="add-lead">
      <Row className="main-toolbar">
        <Breadcrumb className="main-toolbar-breadcrumb">
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/tasks">Tasks</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/tasks/new">Add Task</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="leads-toolbar-buttons">
          <Button type="primary" className="btn fw-12 fw-6">
            <LeftOutlined />
            <span onClick={() => props.history.push('/home/tasks')}>
              Back To Tasks
            </span>
          </Button>
          <Button
            type="primary"
            className="btn text-white bg-darkblue fw-12 fw-6"
          >
            <CloseCircleOutlined />
            <span onClick={() => props.history.push('/home/tasks')}>
              Cancel
            </span>
          </Button>
          <Button type="primary" className="btn text-white fw-12 fw-6">
            <CheckOutlined />
            <span onClick={() => props.history.push('/home/tasks')}>Save</span>
          </Button>
        </Row>
      </Row>
      <div className="add-form">
        <Form
          form={form}
          {...layout}
          onFinish={addTask}
          style={{ background: '#fff', padding: '20px' }}
        >
          <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
            <Panel header="Tasks Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Title" name="title">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    className="ant-dropdown-bg"
                  >
                    <Select>
                      <Option key="New">New</Option>
                      <Option key="In Progress">In Progress</Option>
                      <Option key="Completed">Completed</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Priority"
                    name="priority"
                    className="ant-dropdown-bg"
                  >
                    <Select>
                      <Option key="Low">Low</Option>
                      <Option key="Medium">Medium</Option>
                      <Option key="High">High</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Assigned To" name="assigned_to">
                    <Select mode="multiple">
                      <Option key={profiles.id}>
                        {profiles?.user_details?.first_name}
                      </Option>
                      <Option key="a-2">Assignee two</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item label="Due Date" name="due_date">
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>
                  <Form.Item label="Contact Name" name="contacts">
                    <ContactsAutoSearch onContactSelected={onContactSelected} />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
            >
              <CloseCircleOutlined />
              <span>Cancel</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
          </Row>
        </Form>
        <ContactDrawer
          open={contactDrawer}
          setContactDrawer={setContactDrawer}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('state');
  console.log(state);
  const { responseMessage, errors } = state.tasks;
  const { profiles } = state.profiles;
  const { contacts } = state.contacts;
  return { responseMessage, errors, profiles, contacts };
};

const mapDispatchToProps = {
  addTask,
  getProfiles,
  getContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
