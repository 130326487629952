import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getTaskDetails } from '../../redux/actions/Tasks';
import { putData } from '../../redux/actions/Fetch';
import './detailPage.css';
import 'antd/dist/antd.css';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import { Input, Form, Tabs, Button, Radio } from 'antd';
import TaskNotes from './TaskNotes';
const { TextArea } = Input;

export const TaskDetails = (props) => {
  const [edit, setEdit] = useState(true);

  const { taskDetails, loading, taskUpdated } = props;
  // user details
  const [createdUserFirstName, setCreatedUserFirstName] = useState('');
  const [createdUserLastName, setCreatedUserLastName] = useState('');
  const [createdUserEmail, setCreatedUserEmail] = useState('');
  const [createdUserAltEmail, setCreatedUserAltEmail] = useState('');
  const [createdUserSkypeId, setCreatedUserSkypeId] = useState('');
  const [createdUserDescription, setCreatedUserDescription] = useState('');
  const [createdUserPhone, setCreatedUserPhone] = useState('');

  // assigned to user details
  const [assignedFirstName, setAssignedFirstName] = useState('');
  const [assignedLastName, setAssignedLastName] = useState('');
  const [assignedEmail, setAssignedEmail] = useState('');
  const [assignedAltEmail, setAssignedAltEmail] = useState('');
  const [assignedSkypeId, setAssignedSkypeId] = useState('');
  const [assignedDescription, setAssignedDescription] = useState('');
  const [assignedPhone, setAssignedPhone] = useState('');

  // others
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('');
  const [priority, setPriority] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [createdOn, setCreatedOn] = useState('');
  const [account, setAccount] = useState('');

  const [details, setDetails] = useState('');

  const id = props.match.params.id;

  function updateTaskDetails(e) {
    console.log('onsubmit');
    let taskObj = {
      title: title,
      status: status,
      priority: priority,
      due_date: dueDate,
      id: id,
    };
    props.putData(`api/tasks/${id}/`, 'tasks', taskObj, id);
    console.log(taskUpdated);
    if (taskUpdated) {
      setEdit(false);
    }
  }

  useEffect(() => {
    props.getTaskDetails(`/api/tasks/${id}/`);
  }, []);

  useEffect(() => {
    setDetails(taskDetails);
  }, [loading]);

  useEffect(() => {
    // task details
    setTitle(taskDetails.title);
    setStatus(taskDetails.status);
    setPriority(taskDetails.priority);
    setDueDate(taskDetails.due_date);
    setCreatedOn(moment(taskDetails.created_on).format('YYYY-MM-DD'));

    // account details
    setAccount(taskDetails.account);

    // created by user details
    setCreatedUserFirstName(taskDetails?.created_by?.user_details?.first_name);
    setCreatedUserLastName(taskDetails?.created_by?.user_details?.last_name);
    setCreatedUserEmail(taskDetails?.created_by?.user_details?.email);
    setCreatedUserAltEmail(
      taskDetails?.created_by?.user_details?.alternate_email
    );
    setCreatedUserPhone(taskDetails?.created_by?.user_details?.phone);
    setCreatedUserSkypeId(taskDetails?.created_by?.user_details?.skype_ID);
    setCreatedUserDescription(
      taskDetails?.created_by?.user_details?.description
    );

    // assgined to user details
    let assignedTo = taskDetails?.assigned_to;
    console.log(assignedTo);
    setAssignedFirstName(
      assignedTo != undefined ? assignedTo[0]['user_details'].first_name : ''
    );
    setAssignedLastName(
      assignedTo != undefined ? assignedTo[0]['user_details'].last_name : ''
    );
    setAssignedEmail(
      assignedTo != undefined ? assignedTo[0]['user_details'].email : ''
    );
    setAssignedAltEmail(
      assignedTo != undefined
        ? assignedTo[0]['user_details'].alternate_email
        : ''
    );
    setAssignedPhone(
      assignedTo != undefined ? assignedTo[0]['user_details'].phone : ''
    );
    setAssignedSkypeId(
      assignedTo != undefined ? assignedTo[0]['user_details'].skype_ID : ''
    );
    setAssignedDescription(
      assignedTo != undefined ? assignedTo[0]['user_details'].description : ''
    );
    console.log('due date');
    console.log(dueDate);
  }, [taskDetails]);

  console.log(details);

  return (
    <div className="overall-Cont">
      <div>
        <Button
          type="primary"
          className="edit-button"
          onClick={(edit) => setEdit(!edit)}
        >
          Edit
        </Button>
      </div>
      <Tabs>
        <Tabs.TabPane tab="Details" key="details">
          <div className="main-box">
            <div className="other-data">
              <Form
                layout="vertical"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Item label="Title:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Account:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Account Name"
                    value={account}
                    // onChange={(e) => setAccount(e.target.value)}
                    style={{ marginBottom: 10 }}
                    // disabled={edit ? 'disabled' : ''}
                    disabled="disabled"
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Due Date:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Due Date"
                    value={dueDate}
                    onChange={(e) => setDueDate(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Created On:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Created On"
                    value={createdOn}
                    onChange={(e) => setCreatedOn(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>

                <Form.Item label="Status:">
                  <Radio.Group value={status}>
                    <Radio value="New"> New </Radio>
                    <Radio value="In Progress"> In Progress </Radio>
                    <Radio value="Completed"> Completed </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label="Priority:">
                  <Radio.Group value={priority}>
                    <Radio value="Low"> Low </Radio>
                    <Radio value="Medium"> Medium </Radio>
                    <Radio value="High"> High </Radio>
                  </Radio.Group>
                </Form.Item>
              </Form>
            </div>

            <div className="detail-section">
              {/* assigned to user details section */}
              <div className="assign-cont">
                <p className="user-data-title">Assigned To</p>
                <div className="user-data">
                  <Form
                    layout="vertical"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Form.Item label="First Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="First Name"
                        value={assignedFirstName}
                        // onChange={(e) => setAssignedFirstName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Last Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Last Name"
                        value={assignedLastName}
                        // onChange={(e) => setAssignedLastName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Email:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Email"
                        value={assignedEmail}
                        // onChange={(e) => setAssignedEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Alternate Email:"
                      style={{ width: '49%' }}
                    >
                      <Input
                        placeholder="Email"
                        value={assignedAltEmail}
                        // onChange={(e) => setAssignedAltEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Skype ID:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Skype ID"
                        value={assignedSkypeId}
                        // onChange={(e) => setAssignedSkypeId(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Phone:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Phone"
                        value={assignedPhone}
                        // onChange={(e) => setAssignedPhone(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Description:" style={{ width: '49%' }}>
                      <TextArea
                        rows={4}
                        placeholder="Description"
                        value={assignedDescription}
                        // onChange={(e) => setAssignedDescription(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            <div className="detail-section">
              <div className="user-cont">
                <p className="user-data-title">Created By User Details</p>
                <div className="user-data">
                  <Form
                    layout="vertical"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Form.Item label="First Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="First Name"
                        value={createdUserFirstName}
                        // onChange={(e) => setCreatedUserFirstName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Last Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Last Name"
                        value={createdUserLastName}
                        // onChange={(e) => setCreatedUserLastName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Email:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Email"
                        value={createdUserEmail}
                        // onChange={(e) => setCreatedUserEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Alternate email:"
                      style={{ width: '49%' }}
                    >
                      <Input
                        placeholder="Email"
                        value={createdUserAltEmail}
                        // onChange={(e) => setCreatedUserAltEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Skype ID:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Skype ID"
                        value={createdUserSkypeId}
                        // onChange={(e) => setCreatedUserSkypeId(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Phone:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Phone"
                        value={createdUserPhone}
                        // onChange={(e) => setCreatedUserPhone(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Description:" style={{ width: '49%' }}>
                      <TextArea
                        rows={4}
                        placeholder="Description"
                        value={createdUserDescription}
                        // onChange={(e) => setCreatedUserDescription(e.target.value)}
                        style={{ marginBottom: 10 }}
                        // disabled={edit ? 'disabled' : ''}
                        disabled="disabled"
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
            {!edit ? (
              <div className="save-cancel">
                <Button type="danger">Cancel</Button>
                <Button
                  style={{ marginLeft: '20px' }}
                  type="primary"
                  onClick={updateTaskDetails}
                >
                  Submit
                </Button>
              </div>
            ) : (
              ' '
            )}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notes" key="notes">
          <TaskNotes id={id} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, taskDetails, taskUpdated } = state.tasks;
  return { loading, taskDetails, taskUpdated };
};

const mapDispatchToProps = {
  getTaskDetails,
  putData,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskDetails);