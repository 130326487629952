import moment from 'moment';
import { connect , useDispatch } from "react-redux";
import {Modal} from 'antd'
export const CommentsHistory = (props) => {    
  const { commentsArray, visible, onCancel } = props;
  const arrrayDiv = commentsArray?.map((element, index) => (
    <p key={index}>{index+1}. <b>{element.notes}</b> at {moment(element.created_at).format('MMMM Do YYYY, h:mm:ss a"')}</p>
  ));
  return (
    <Modal
      visible={visible}
      title="Comment History Details"
      onCancel={onCancel}
      footer={null}
    >{arrrayDiv}</Modal>
  );
};

export default connect()(CommentsHistory);
