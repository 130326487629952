import React, { useEffect, useState } from 'react';

import {
  CheckOutlined,
  CloseCircleOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Form, Row } from 'antd';
import { motion } from 'framer-motion';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { addBulkDeals, dealErrors, getBulkDealTemplate } from '../../redux/actions/Deals';

const { Panel } = Collapse;

export const AddBulkDeals = (props) => {
  const { error, responseMessage } = props;
  const [file, setFile] = useState(undefined);

  useEffect(() => {
    if (responseMessage !== '' && responseMessage !== false) {
      props.history.push('/home/deals');
    }
  }, [responseMessage]);

  const addBulkDeals = () => {
    props.addBulkDeals(file);
  };

  const getBulkDealTemplate = () => {
    props.getBulkDealTemplate();
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {error && error !== '' && error.length !== 0 ? (
          <Alert type="error" closable message={error}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <div className="app-area">
        <Form onFinish={addBulkDeals}>
          <StyledToolbar module="deals" mode="bulk" onSubmit={()=>{if(file){addBulkDeals(file)}}}/>
          <Collapse
            expandIconPosition="end"
            defaultActiveKey={[1]}
            collapsible="disabled"
          >
            <Panel key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Download .XLSX Template File for the Bulk Adding Deals"
                    name="document_attachment"
                  >
                    <Button
                      type="primary"
                      className="btn text-white bg-darkblue fw-12 fw-6"
                      onClick={getBulkDealTemplate}
                    >
                      <DownloadOutlined />
                      Download File
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Select File" name="document_attachment">
                    <input
                      type="file"
                      name="file"
                      accept=".xlsx"
                      onChange={(val) => setFile(val.target.files[0])}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
            <Button
              className="btn-cancel mr-2"
              onClick={() => {
                props.history.push('/home/deals');
              }}
            >
              <CloseCircleOutlined />
              Cancel
            </Button>
            <Button
              className="btn-save"
              onClick={addBulkDeals}
              disabled={!file}
            >
              <CheckOutlined />
              Save
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { error, responseMessage } = state.deals;
  return { error, responseMessage };
};

const mapDispatchToProps = {
  addBulkDeals,
  dealErrors,
  getBulkDealTemplate
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkDeals);
