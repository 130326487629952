import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_DOCUMENTS,
  ADD_DOCUMENT,
  DELETE_DOCUMENT,
} from '../constants/Documents';
import {
  setDocuments,
  refresh,
  responseMessage,
  documentErrors,
} from '../actions/Documents';
import { service } from '../../service';

export function* getDocuments() {
  yield takeEvery(GET_DOCUMENTS, function* ({ payload }) {
    let { url, offset } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setDocuments({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* addDocument() {
  yield takeEvery(ADD_DOCUMENT, function* ({ payload }) {
    try {
      let response = yield call(service.post, '/api/accounts/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('Hello');

      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(documentErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(documentErrors(error.response.data.errors));
    }
  });
}

export function* deleteDocument() {
  yield takeEvery(DELETE_DOCUMENT, function* ({ payload }) {
    let { id, bool } = payload;
    try {
      let response = yield call(service.delete, `/api/documents/${id}/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(refresh(bool));
      }
    } catch (error) {}
  });
}

export default function* rootSaga() {
  yield all([fork(getDocuments), fork(addDocument), fork(deleteDocument)]);
}
