import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Form, Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { forgotPassword } from '../../../redux/actions/Auth';
import { resetPasswordAction } from '../../../redux/actions/Auth';
import { motion } from 'framer-motion';
import { rules } from '../../common/rules';
import { useParams } from 'react-router-dom';

export const ResetPasswordForm = (props) => {
  let { uid, token } = useParams();
  console.log(uid, token);

  const onSubmit = (e) => {
    // TODO: send API request to reset password
    console.log(e);
    props.resetPasswordAction({ uid, token, ...e });
    props.history.push('/login');
    alert('Password updated');

    // TODO: Navigate to login page
  };

  const { errors, alert } = props;

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {errors != '' ? (
          <Alert type="error" closable message={errors}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <Form layout="vertical" name="forgotpassword-form" onFinish={onSubmit}>
        <Form.Item name="new_password1" label="Password" rules={rules.password}>
          <Input.Password className="sign-in-email" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item
          name="new_password2"
          label="Confirm Password"
          rules={[
            rules.password,
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password1') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
          dependencies={['new_password1']}
        >
          <Input.Password className="sign-in-email" prefix={<LockOutlined />} />
        </Form.Item>

        <Form.Item>
          <Button
            className="sign-in-button mt-4"
            type="primary"
            htmlType="submit"
            block
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, alert } = state.auth;
  return { errors, alert };
};

const mapDispatchToProps = {
  forgotPassword,
  resetPasswordAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
