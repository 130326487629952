import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Select,
  Breadcrumb,
  Button,
} from 'antd';
import {
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { addAccount } from '../../redux/actions/Accounts';
import { layout } from '../common/layout';
import { getProfiles } from '../../redux/actions/Profiles';
import { getContacts } from '../../redux/actions/Contacts';
import { getLeads } from '../../redux/actions/Leads';
import { ContactDrawer } from '../leads/components/ContactDrawer';
import '../leads/leads.css';
import BillingAddress from '../../components/ui-components/BillingAddress';
import ContactsAutoSearch from '../contacts/ContactsAutoSearch';

const { Panel } = Collapse;
const { Option } = Select;

export const AddAccount = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getLeads(`/api/leads/`, 0);
    props.getContacts(`/api/contacts/`, 0);
  }, []);

  const [form] = Form.useForm();

  const onContactSelected = (contactObj) => {
    form.setFieldsValue({
      contacts: [contactObj.id],
    });
  };

  const { responseMessage, profiles, leadsData } = props;

  const [selectedFile, setSelectedFile] = useState();

  const [contactDrawer, setContactDrawer] = useState(false);
  const leadToShow = leadsData ? leadsData.open_leads.open_leads : [];

  const addAccount = (e) => {
    props.addAccount(e);
  };

  console.log(props);
  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/accounts');
    }
  }, [responseMessage]);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  console.log('leads');
  console.log(leadsData);
  return (
    <div className="add-lead">
      <Row className="main-toolbar">
        <Breadcrumb className="main-toolbar-breadcrumb">
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/accounts">Accounts</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/accounts/new">Add Accounts</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="leads-toolbar-buttons">
          <Button type="primary" className="btn fw-12 fw-6">
            <LeftOutlined />
            <span onClick={() => props.history.push('/home/accounts')}>
              Back To Accounts
            </span>
          </Button>
          <Button
            type="primary"
            className="btn text-white bg-darkblue fw-12 fw-6"
          >
            <CloseCircleOutlined />
            <span onClick={() => props.history.push('/home/accounts')}>
              Cancel
            </span>
          </Button>
          <Button type="primary" className="btn text-white fw-12 fw-6">
            <CheckOutlined />
            <span onClick={() => props.history.push('/home/accounts')}>
              Save
            </span>
          </Button>
        </Row>
      </Row>
      <div className="add-form">
        <Form
          form={form}
          {...layout}
          onFinish={addAccount}
          style={{ background: '#fff', padding: '20px' }}
        >
          {/* Account Details */}
          <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
            <Panel header="Accounts Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Organization"
                    name="organization"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Name"
                    name="name"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Mobile Number"
                    name="phone"
                    className="user-mobilenumber"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    className="user-emailaddress"
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Website" name="website">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Status"
                    name="status"
                    className="ant-dropdown-bg"
                  >
                    <Select>
                      <Option key="open">Open</Option>
                      <Option key="close">Close</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Account Attachment"
                    name="account_attachment"
                  >
                    <input type="file" name="file" onChange={changeHandler} />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          {/* Address Details */}
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            className="mt-4"
          >
            <Panel header="Billing Address Details" key="1">
              <BillingAddress rules={[{ required: true }]} />
            </Panel>
          </Collapse>

          {/* Contact Details */}
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            className="mt-4"
          >
            <Panel header="Contact Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Contact Name" name="contacts">
                    <ContactsAutoSearch onContactSelected={onContactSelected} />
                  </Form.Item>
                  {/* Change to teams dropdown */}
                  <Form.Item label="Teams" name="teams">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Assigned To" name="assigned_to">
                    <Select mode="multiple">
                      <Option key={profiles.id}>
                        {profiles?.user_details?.first_name}
                      </Option>
                      <Option key="a-2">Assignee two</Option>
                    </Select>
                  </Form.Item>
                  {/* Change to tags dropdown */}
                  <Form.Item label="Tags" name="tags">
                    <Input />
                  </Form.Item>
                  {/* Change to leads dropdown */}
                  <Form.Item label="Lead" name="lead">
                    <Select mode="multiple">
                      {leadToShow.map((element) => {
                        return (
                          <Option key={element.id} value={element.id}>
                            {' '}
                            {element.first_name + ' ' + element.last_name}{' '}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
            >
              <CloseCircleOutlined />
              <span>Cancel</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
          </Row>
        </Form>
        <ContactDrawer
          open={contactDrawer}
          setContactDrawer={setContactDrawer}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('state');
  console.log(state);
  const { responseMessage, errors } = state.accounts;
  const { profiles } = state.profiles;
  const { leadsData } = state.leads;
  const { contacts } = state.contacts;
  return { responseMessage, errors, profiles, contacts, leadsData };
};

const mapDispatchToProps = {
  addAccount,
  getProfiles,
  getLeads,
  getContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAccount);
