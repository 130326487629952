import React from 'react';
import { List, Card } from 'antd';
import 'antd/dist/antd.css';

function TemplateGrid({ data, onClickCard }) {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 4,
        lg: 4,
        xl: 4,
        xxl: 4,
      }}
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <Card
            style={{
              height: '200px',
              overflow: 'scroll',
              cursor: 'pointer',
            }}
            title={item.title}
            onClick={() => {
              if (onClickCard) {
                onClickCard(item);
              }
            }}
          >
            <div
              style={{ height: '100%' }}
              dangerouslySetInnerHTML={{ __html: item.content }}
            />
          </Card>
        </List.Item>
      )}
    />
  );
}

export default TemplateGrid;
