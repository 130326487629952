import React, { useState } from 'react';
import './style.css';
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import moment from 'moment';

function ContactsEvents(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventDetails, seteventDetails] = useState('');
  const eventsList = props.events;
  console.log('eventsList');
  console.log(eventsList);
  const now = moment();
  const todayEventsList =
    eventsList != undefined && eventsList.length > 0
      ? eventsList.filter(function (event) {
          return (
            moment(event.start_date).format('yyyy-MM-DD') ==
            moment(now).format('yyyy-MM-DD')
          );
        })
      : [];
  const upcomingEventsList =
    eventsList != undefined && eventsList.length > 0
      ? eventsList.filter(function (event) {
          return (
            moment(event.start_date).format('yyyy-MM-DD') >
            moment(now).format('yyyy-MM-DD')
          );
        })
      : [];
  const pastEventsList =
    eventsList != undefined && eventsList.length > 0
      ? eventsList.filter(function (event) {
          return (
            moment(event.end_date).format('yyyy-MM-DD') <
            moment(now).format('yyyy-MM-DD')
          );
        })
      : [];

  const showModal = (details) => {
    setIsModalOpen(true);
    seteventDetails(details);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="planned">Today</div>
      {todayEventsList.length > 0 ? (
        <>
          <p> * Click on event title to get more details</p>
          {todayEventsList.map((details, index) => {
            return (
              <>
                <div className="task-cont" key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="circle"
                      // onClick={() => setDone(!done)}
                    ></div>
                    <h5 onClick={() => showModal(details)}> {details.name}</h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <div className="due_date">
                      Start Date Time :{' '}
                      <b>
                        {details.start_date} {details.start_time}
                      </b>
                    </div>
                    <div className="due_date">
                      End Date Time :{' '}
                      <b>
                        {details.end_date} {details.end_time}
                      </b>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <h5 style={{ textAlign: 'center' }}> No events today </h5>
      )}

      <div className="planned">Upcoming</div>
      {upcomingEventsList.length > 0 ? (
        <>
          <p> * Click on event title to get more details</p>
          {upcomingEventsList.map((details, index) => {
            return (
              <>
                <div className="task-cont" key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="circle"
                      // onClick={() => setDone(!done)}
                    ></div>
                    <h5 onClick={() => showModal(details)}> {details.name}</h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <div className="due_date">
                      Start Date Time :{' '}
                      <b>
                        {details.start_date} {details.start_time}
                      </b>
                    </div>
                    <div className="due_date">
                      End Date Time :{' '}
                      <b>
                        {details.end_date} {details.end_time}
                      </b>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <h5 style={{ textAlign: 'center' }}> No upcoming events </h5>
      )}

      <div className="planned">Past</div>
      {pastEventsList.length > 0 ? (
        <>
          <p> * Click on event title to get more details</p>
          {pastEventsList.map((details, index) => {
            return (
              <>
                <div className="task-cont" key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="circle"
                      // onClick={() => setDone(!done)}
                    ></div>
                    <h5 onClick={() => showModal(details)}> {details.name}</h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <div className="due_date">
                      Start Date Time :{' '}
                      <b>
                        {details.start_date} {details.start_time}
                      </b>
                    </div>
                    <div className="due_date">
                      End Date Time :{' '}
                      <b>
                        {details.end_date} {details.end_time}
                      </b>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <h5 style={{ textAlign: 'center' }}> No past events </h5>
      )}

      <Modal
        title="Event Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <p>
            <b>Name: </b>
            {eventDetails.name}
          </p>
          <p>
            <b>Description: </b>
            {eventDetails.description}
          </p>
          <p>
            <b>Event Type: </b>
            {eventDetails.event_type}
          </p>
          <p>
            <b>Start Date Time: </b>
            {eventDetails.start_date} {eventDetails.start_time}
          </p>
          <p>
            <b>End Date Time: </b>
            {eventDetails.end_date} {eventDetails.end_time}
          </p>
          <p>
            <b>Created On: </b>
            {moment(eventDetails.created_on).format('yyyy-MM-DD HH:mm:ss')}
          </p>
          <p>
            <b>Assigned To: </b>
            {eventDetails?.assigned_to != undefined
              ? eventDetails?.assigned_to[0]?.user_details?.first_name +
                ' ' +
                eventDetails?.assigned_to[0]?.user_details?.last_name
              : 'None'}
          </p>
          <p>
            <b>Created By: </b>
            {eventDetails?.created_by?.user_details?.first_name +
              ' ' +
              eventDetails?.created_by?.user_details?.last_name}
          </p>
        </div>
      </Modal>
    </>
  );
}

export default ContactsEvents;
