import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_EVENTS,
  ADD_EVENT,
  DELETE_EVENT,
  EVENT_DETAILS,
} from '../constants/Events';
import {
  setEventsData,
  refresh,
  responseMessage,
  updateErrors,
  updateEventDetails,
} from '../actions/Events';
import { service } from '../../service';

export function* getEventsList() {
  yield takeEvery(GET_EVENTS, function* ({ payload }) {
    let { url, offset } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setEventsData({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* addEvent() {
  yield takeEvery(ADD_EVENT, function* ({ payload }) {
    try {
      console.log('Payload');
      payload.start_date = payload.starts_at.format('YYYY-MM-DD');
      payload.start_time = payload.starts_at.format('HH:mm:ss');
      payload.end_date = payload.ends_at.format('YYYY-MM-DD');
      payload.end_time = payload.ends_at.format('HH:mm:ss');
      payload.org = 2;
      payload.event_type = 'Non-Recurring';
      payload.contacts = '[' + payload.contacts.toString() + ']';
      delete payload.starts_at;
      delete payload.ends_at;
      console.log(payload);
      let response = yield call(service.post, '/api/events/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* deleteEvent() {
  yield takeEvery(DELETE_EVENT, function* ({ payload }) {
    let { id, bool } = payload;
    try {
      let response = yield call(service.delete, `/api/events/${id}/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(refresh(bool));
      }
    } catch (error) {}
  });
}

export function* getEventDetails() {
  yield takeEvery(EVENT_DETAILS, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('response event details');
      console.log(response);
      yield put(updateEventDetails(response));
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(getEventsList),
    fork(addEvent),
    fork(deleteEvent),
    fork(getEventDetails),
  ]);
}
