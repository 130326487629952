export const ADD_INVESTOR = 'ADD_INVESTOR';
export const GET_INVESTORS_LIST = 'GET_INVESTORS_LIST';
export const SET_INVESTORS_LIST = 'SET_INVESTORS_LIST';
export const INVESTOR_ERRORS = 'INVESTOR_ERRORS'
export const RESPONSE_MESSAGE = 'INVESTOR_RESPONSE_MESSAGE'
export const IS_LOADING = 'IS_LOADING'
export const UPDATE_ERRORS = 'UPDATE_ERRORS'
export const GET_BULK_INVESTOR_TEMPLATE = 'GET_BULK_INVESTOR_TEMPLATE'
export const DELETE_INVESTOR = 'DELETE_INVESTOR'
export const ADD_BULK_INVESTORS = 'ADD_BULK_INVESTORS';
export const GET_INVESTOR_DETAILS = 'GET_INVESTOR_DETAILS'
export const SET_INVESTOR_DETAILS = 'SET_INVESTOR_DETAILS'
export const UPDATE_INVESTOR_DETAILS = 'UPDATE_INVESTOR_DETAILS'
