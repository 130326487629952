import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Select } from 'antd';
import { service } from '../../service';
import { getContacts } from '../../redux/actions/Contacts';
import { getProfiles } from '../../redux/actions/Profiles';
import { connect } from 'react-redux';


const transformUsers = (users) => {
  return users.map((user) => {
    return {
      value: `${user.first_name} ${user.last_name}`,
      key: user.id,
      obj: user,
    };
  });
};

const getNames = users => {
  return users.map((user) => {
    return `${user.first_name} ${user.last_name}`
  })
}

const { Option } = Select;
const ProfileAutoSearch = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getContacts(`/api/contacts/`, 0);
  }, []);

  const {
    onUserSelected,
    onUserDeSelected,
    preFilledValue=null,
    contacts,
    responseMessage,
  } = props;
  const [value, setValue] = useState([]);
  const [options, setOptions] = useState([]);
  const contactToShow = contacts ? contacts.contact_obj_list : [];

  useEffect(() => {
    if (preFilledValue) {
      const names = getNames(preFilledValue)
      setValue([...names])
    }
  }, [preFilledValue])

  useEffect(() => {
    if (responseMessage === true) {
      window.location.reload(false);
    }
  }, [responseMessage]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // const getUsers = async (searchText) => {
  //   const response = await service.get(`/api/users/?name=${searchText}`, {
  //     headers: {
  //       Authorization: `jwt ${localStorage.getItem('Token')}`,
  //       org: localStorage.getItem('company'),
  //     },
  //   });

  //   if (response.status == 200) {
  //     setOptions(transformUsers(response.data.active_users.active_users));
  //   }
  //   return response;
  // };
  useEffect(() => {
    if (contactToShow) {
      setOptions(transformUsers(contactToShow))
    }
  }, [contacts])

  const onSearch = (searchText) => {
    // getUsers(searchText);
  };

  const onSelect = (val, option) => {
    // console.log('onSelect', option);
    if (onUserSelected) {
      onUserSelected(option.obj);
    }
  };

  const onDeSelect = (val, option) => {
    if (onUserDeSelected) {
      onUserDeSelected(option.obj)
    }
  }

  const onChange = (data) => {
    setValue(data);
  };

  return (
    <>
      <Select
        size="large"
        placeholder="Search Contacts"
        mode="multiple"
        onChange={onChange}
        onSelect={onSelect}
        onDeselect={onDeSelect}
        // onSearch={debounce(onSearch, 100)}
        // value={value}
        options={options}
        value={value}
        // defaultValue={value}
      >
       {/* {contactToShow.map((element) => {
          return (
            <Option key={element.id} value={element.id}>
              {' '}
              {element.first_name + ' ' + element.last_name}{' '}
            </Option>
          );
        })}  */}
      </Select>
    </>
  );
};

const mapStateToProps = (state) => {
  const { profiles } = state.profiles;
  const { contacts } = state.contacts;
  const { responseMessage, errors } = state.events;
  return { profiles, contacts, responseMessage, errors };
};

const mapDispatchToProps = {
  getContacts,
  getProfiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileAutoSearch);
