import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Table, Button, Select, Input } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import {
  getTasks,
  loading,
  deleteTask,
  //  refresh,
  responseMessage,
} from '../../redux/actions/Tasks';
import '../leads/leads.css';
import { MainToolbar } from '../../components/ui-components/toolbar/mainToolbar/MainToolbar';
const { Option } = Select;

export const TasksList = (props) => {
  const { tasks_count, tasksData } = props;

  const [rows, setRows] = useState(10);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    props.getTasks(`/api/tasks/?limit=${rows}`, offset);
    props.loading();
  }, [rows]);

  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const routeToDetails = (id) => {
    props.history.push(`/home/tasks/${id}/details`);
  };

  // const taskDelete = (id) => {
  //   props.deleteTask(id, !refresh);
  // };

  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (tasksData, row) => {
        return (
          <p onClick={() => routeToDetails(row.id)}>
            <span className="">{tasksData}</span>
          </p>
        );
      },
    },
    {
      title: 'Assigned',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Assigned To"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              type="danger"
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.assigned_to[0].user_details.first_name
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (assigned_to) => {
        return (
          <p>
            {assigned_to[0] != undefined
              ? assigned_to[0]['user_details']['first_name'] +
                ' ' +
                assigned_to[0]['user_details']['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Contact',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts) => {
        return (
          <p>
            {contacts[0] != undefined
              ? contacts[0]['first_name'] + ' ' + contacts[0]['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'due_date',
      key: 'due_date',
      render: (due_date) => <p>{due_date}</p>,
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      filters: [
        {
          text: 'Low',
          value: 'Low',
        },
        {
          text: 'Medium',
          value: 'Medium',
        },
        {
          text: 'High',
          value: 'High',
        },
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.priority.includes(value),
      render: (priority) => <p>{priority}</p>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filters: [
        {
          text: 'New',
          value: 'New',
        },
        {
          text: 'In Progress',
          value: 'In Progress',
        },
        {
          text: 'Completed',
          value: 'Completed',
        },
      ],
      filterMode: 'tree',
      filterSearch: true,
      onFilter: (value, record) => record.status.includes(value),
      render: (status) => <p>{status}</p>,
    },
    {
      title: 'Actions',
      key: 'action',
      render: () => (
        <>
          <span className="edit">
            <EditOutlined />
          </span>
          <span className="delete" style={{ marginLeft: '10px' }}>
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];
  console.log({ props });
  return (
    <div className="leadslist">
      <MainToolbar
        offset={offset}
        setOffset={setOffset}
        totalCount={tasks_count}
        rows={rows}
        updateRecordsPerPage={updateRecordsPerPage}
        history={props.history}
        module="tasks"
        buttons={[{ url: 'tasks/new', label: 'Add Task' }]}
      />

      <Table
        columns={columns}
        dataSource={tasksData.tasks}
        pagination={false}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, tasksData, offset, refresh } = state.tasks;
  return { isLoading, tasksData, offset, refresh };
};

const mapDispatchToProps = {
  getTasks,
  loading,
  deleteTask,
  // refresh,
  responseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
