import {
  REFRESH, RESPONSE_MESSAGE, SET_CAMPAIGNS_DATA, UPDATE_CAMPAIGN_DETAILS, UPDATE_ERRORS
} from '../constants/Campaigns';
import {
  ADD_TEMPLATE, SET_CAMPAIGN_TEMPLATES_STORE, UPDATE_TEMPLATE
} from '../constants/EmailTemplates';
  
const initialState = {
  isLoading: true,
  campaignsData: '',
  refresh: false,
  responseMessage: '',
  errors: [],
  draftOffset: 0,
  scheduledOffset: 0,
  sentOffset: 0,
  campaignDetails: {},
  templates: [],
};

const updateTemplateReducer = (state, action) => {
  const template = action.payload;
  const index = template.id;
  const templates = state.templates;
  templates[index] = template;

  return {
    ...state,
    templates,
  };
};

const addTemplateReducer = (state, action) => {
  const newTemplate = action.payload;
  // const index = state.templates.length;
  const templates = state.templates;
  templates.push({ ...newTemplate, id: new Date().getTime() });
  // console.log('add template reducer called', newTemplate);
  return {
    ...state,
    templates,
  };
};

const deleteTemplateReducer = (state, action) => {
  const template = action.payload;
  const index = template.id;
  // console.log(template);
  const templates = state.templates;
  const id = templates.findIndex((t) => t.id === index);
  // console.log('Going to delete ', id);
  templates.splice(id, 1);

  return {
    ...state,
    templates,
  };
};

const setCampaignTemplateReducer = (state, action) => {
  const templates = action.payload;
  return {
    ...state,
    templates,
  };
};

const campaigns = (state = initialState, action) => {
  switch (action.type) {
    case SET_CAMPAIGNS_DATA:
      return {
        ...state,
        campaignsData: action.payload.response,
        draftOffset: action.payload.response.draft_campaigns.offset,
        scheduledOffset: action.payload.response.scheduled_campaigns.offset,
        sentOffset: action.payload.response.sent_campaigns.offset,
        isLoading: false,
      };
    case UPDATE_CAMPAIGN_DETAILS:
      return {
        ...state,
        campaignDetails: action.data.data.campaign_obj,
        loading: false,
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case REFRESH:
      return {
        ...state,
        refresh: action.payload,
      };
    case ADD_TEMPLATE:
      return addTemplateReducer(state, action);
    case UPDATE_TEMPLATE:
      return updateTemplateReducer(state, action);
    case SET_CAMPAIGN_TEMPLATES_STORE:
      return setCampaignTemplateReducer(state, action);
    default:
      return state;
  }
};

export default campaigns;
