import React, { useState, useEffect } from 'react';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { connect } from 'react-redux';
import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Select,
  Button,
  Tabs,
  DatePicker,
} from 'antd';
import { CloseCircleOutlined, CheckOutlined } from '@ant-design/icons';
import moment from 'moment';
import { addEvent } from '../../redux/actions/Events';
import { getProfiles } from '../../redux/actions/Profiles';
import { getContacts } from '../../redux/actions/Contacts';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

const AddEvent = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getContacts(`/api/contacts/`, 0);
  }, []);

  const { responseMessage, contacts } = props;

  const contactToShow = contacts ? contacts.contact_obj_list : [];
  const [selectedDate, setSelectedDate] = useState(new Date());

  const addEvent = (e) => {
    console.log('Add Event called');
    console.log(e);
    console.log(props);
    props.addEvent(e);
  };

  useEffect(() => {
    if (responseMessage === true) {
      console.log(props);
      window.location.reload(false);
    }
  }, [responseMessage]);

  const disabledDate = (current) => {
    return current < moment().startOf('day');
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledDateTime = () => {
    if (selectedDate > new Date()) {
      return;
    }
    return {
      disabledHours: () => range(0, 24).splice(0, moment().hour()),
      disabledMinutes: () => range(0, moment().minute()),
    };
  };

  return (
    <div> 
          <Form onFinish={addEvent} style={{ background: '#fff', padding: '20px' }}>
        <Collapse expandIconPosition="end">
          <Panel header="Add New Event" key="1">
            <Row justify="start">
              <Col xs={24} sm={24} md={24} lg={3} xl={11}>
                <Form.Item name="name">
                  <Input
                    placeholder="Title"
                    type="text"
                    style={{ marginRight: '10px' }}
                  />
                </Form.Item>
                <Form.Item name="contacts">
                  <Select mode="multiple" placeholder="Select Contacts   ">
                    {contactToShow.map((element) => {
                      return (
                        <Option key={element.id} value={element.id}>
                          {' '}
                          {element.first_name + ' ' + element.last_name}{' '}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
                <Form.Item name="starts_at">
                  <DatePicker
                    placeholder="Starts At"
                    style={{ marginRight: '10px' }}
                    use24Hours
                    onSelect={setSelectedDate}
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                  />
                </Form.Item>
                <Form.Item name="ends_at">
                  <DatePicker
                    style={{ marginRight: '10px' }}
                    placeholder="Ends At"
                    use24Hours
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    disabledDate={disabledDate}
                    disabledTime={disabledDateTime}
                  />
                </Form.Item>
                <Form.Item name="description">
                  <Input
                    placeholder="Description"
                    type="textarea"
                    style={{ marginRight: '10px' }}
                  />
                </Form.Item>
                <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
                  <Button className="btn-cancel mr-2">
                    <CloseCircleOutlined />
                    Cancel
                  </Button>
                  <Button className="btn-save" htmlType="submit">
                    <CheckOutlined />
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
          </Panel>
        </Collapse>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  console.log('state');
  console.log(state);
  const { responseMessage, errors } = state.events;
  const { profiles } = state.profiles;
  const { contacts } = state.contacts;
  return { responseMessage, errors, profiles, contacts };
};

const mapDispatchToProps = {
  addEvent,
  getProfiles,
  getContacts,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddEvent);
