import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import TemplateGrid from './TemplateGrid';
import { Link } from 'react-router-dom';

import { Button } from 'antd';
import { UserAddOutlined, LeftOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getTemplatesServerAction } from '../../redux/actions/EmailTemplates';

function TemplatesList(props) {
  const campaigns = useSelector((app) => app.campaigns);
  const templates = campaigns.templates;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTemplatesServerAction());
  }, []);

  const cardClicked = (template) => {
    history.push(`/home/templates/${template.id}/edit`);
  };
  return (
    <div style={{ padding: 10 }}>
      <div style={{ display: 'flex', flexDirection: 'row', margin: '20px' }}>
        <div className="add-btns d-flex ml-2">
          <Link to={`/home/campaigns/`}>
            <Button className="back-btn d-flex align-items-center mr-2">
              <LeftOutlined />
              Back
            </Button>
          </Link>
        </div>
        <div className="add-btns d-flex ml-2">
          <Link to={`/home/templates/new`}>
            <Button className="back-btn d-flex align-items-center mr-2">
              <UserAddOutlined className="text-uppercase" />
              Add new templates
            </Button>
          </Link>
        </div>
      </div>
      <div>
        <TemplateGrid data={templates} onClickCard={cardClicked} />
      </div>
    </div>
  );
}

export default TemplatesList;
