import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_TASKS,
  ADD_TASK,
  DELETE_TASK,
  TASK_DETAILS,
  TASK_NOTES,
  ADD_TASK_NOTE,
} from '../constants/Tasks';
import {
  setTasksData,
  refresh,
  responseMessage,
  updateErrors,
  updateTaskDetails,
  updateTaskNotes,
} from '../actions/Tasks';
import { service } from '../../service';

export function* getTasksList() {
  yield takeEvery(GET_TASKS, function* ({ payload }) {
    let { url, offset } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setTasksData({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* addTask() {
  yield takeEvery(ADD_TASK, function* ({ payload }) {
    try {
      payload.due_date = payload.due_date.format('YYYY-MM-DD');
      // console.log(moment(payload.due_date, "YYYY-MM-DD"))
      let response = yield call(service.post, '/api/tasks/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* deleteTask() {
  yield takeEvery(DELETE_TASK, function* ({ payload }) {
    let { id, bool } = payload;
    try {
      let response = yield call(service.delete, `/api/tasks/${id}/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(refresh(bool));
      }
    } catch (error) {}
  });
}

export function* getTaskDetails() {
  yield takeEvery(TASK_DETAILS, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('response task details');
      console.log(response);
      yield put(updateTaskDetails(response));
    } catch (err) {}
  });
}

export function* addTaskNote() {
  yield takeEvery(ADD_TASK_NOTE, function* ({ payload }) {
    let { id } = payload;
    try {
      let response = yield call(
        service.post,
        `/api/tasks/comment/${id}/`,
        payload,
        {
          headers: {
            Authorization: `jwt ${localStorage.getItem('Token')}`,
            org: localStorage.getItem('company'),
          },
        }
      );
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* getTaskNotes() {
  yield takeEvery(TASK_NOTES, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('response task comments');
      console.log(response);
      yield put(updateTaskNotes(response));
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(getTasksList),
    fork(addTask),
    fork(deleteTask),
    fork(getTaskDetails),
    fork(addTaskNote),
    fork(getTaskNotes),
  ]);
}
