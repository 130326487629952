import {
  CalendarOutlined,
  CarryOutOutlined,
  ContactsOutlined,
  DollarOutlined,
  FileOutlined,
  HomeOutlined,
  MailOutlined,
  RedEnvelopeOutlined,
  SendOutlined,
  TagsOutlined,
  UsergroupAddOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ROLES } from '../../../redux/constants/Auth';
import './SideNav.css';
import { toggleCollapsedNav } from '../../../redux/actions/Theme';

const { Sider } = Layout;

export const SideNav = (props) => {
  const { navCollapsed } = props;

  const profiles = useSelector((app) => app.profiles);

  const profile = profiles.profiles;

  const role = profile?.role;

  const [key, setKey] = useState();
  const path = window.location.pathname;
  const history = useHistory();
  const updateRoute = (e, route) => {
    history.push(route);
    setKey(e.key);
  };

  useEffect(() => {
    if (path.match(/\/home$/)) {
      setKey(1);
    } else if (path.match(/\/home\/compose/gi)) {
      setKey(2);
    } else if (path.match(/\/home\/leads/gi)) {
      setKey(3);
    } else if (path.match(/\/home\/contacts/gi)) {
      setKey(4);
    } else if (path.match(/\/home\/accounts/gi)) {
      setKey(5);
    } else if (path.match(/\/home\/deals/gi)) {
      setKey(6);
    } else if (path.match(/\/home\/users/gi)) {
      setKey(7);
    } else if (path.match(/\/home\/tasks/gi)) {
      setKey(8);
    } else if (path.match(/\/home\/calendar/gi)) {
      setKey(9);
    } else if (path.match(/\/home\/documents/gi)) {
      setKey(10);
    } else if (path.match(/\/home\/campaigns/gi)) {
      setKey(11);
    }
  });

  return (
    <div className="sidenav-sider">
      <Sider trigger={null} collapsible collapsed={navCollapsed}>
        <Menu mode="inline">
          <Menu.Item
            key="0"
            onClick={(e) => {
              if (navCollapsed) {
                props.toggleCollapsedNav(false);
              } else {
                props.toggleCollapsedNav(true);
              }
            }}
            // className={key === 0 ? `ant-menu-item` : 'ant-menu-item'}
            style={{backgroundColor:'#214069', color:'#ffffff', marginTop:'0px'}}
            icon={navCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          >
            {navCollapsed ? 'Open Menu' : 'Close Menu'}
          </Menu.Item>
          <Menu.Item
            key="1"
            icon={<HomeOutlined />}
            onClick={(e) => updateRoute(e, '/home')}
            className={key === 1 ? `ant-menu-item-selected` : ''}
          >
            Dashboard
          </Menu.Item>
          <Menu.Item
            key="2"
            icon={<SendOutlined />}
            onClick={(e) => updateRoute(e, '/home/compose')}
            className={key === 2 ? `ant-menu-item-selected` : ''}
          >
            Compose
          </Menu.Item>
          <Menu.Item
            key="3"
            icon={<RedEnvelopeOutlined />}
            onClick={(e) => updateRoute(e, '/home/leads')}
            className={key === 3 ? `ant-menu-item-selected` : ''}
          >
            Leads
          </Menu.Item>
          <Menu.Item
            key="4"
            icon={<ContactsOutlined />}
            onClick={(e) => updateRoute(e, '/home/contacts')}
            className={key === 4 ? `ant-menu-item-selected` : ''}
          >
            Contacts
          </Menu.Item>
          {role === ROLES.ADMIN ? (
            <Menu.Item
              key="5"
              icon={<DollarOutlined />}
              onClick={(e) => updateRoute(e, '/home/investors')}
              className={key === 5 ? `ant-menu-item-selected` : ''}
            >
              Investors
            </Menu.Item>
          ) : null}
          {/* <Menu.Item
            key="5"
            icon={<AccountBookOutlined />}
            onClick={(e) => updateRoute(e, '/home/accounts')}
            className={key === 5 ? `ant-menu-item-selected` : ''}
          >
            Accounts
          </Menu.Item> */}
          {role === ROLES.ADMIN ? (
            <Menu.Item
              key="6"
              icon={<TagsOutlined />}
              onClick={(e) => updateRoute(e, '/home/deals')}
              className={key === 6 ? `ant-menu-item-selected` : ''}
            >
              Deals
            </Menu.Item>
          ) : null}
          {role === ROLES.ADMIN ? (
            <Menu.Item
              key="7"
              icon={<UsergroupAddOutlined />}
              onClick={(e) => updateRoute(e, '/home/users')}
              className={key === 7 ? `ant-menu-item-selected` : ''}
            >
              Employees
            </Menu.Item>
          ) : null}
          <Menu.Item
            key="8"
            icon={<CarryOutOutlined />}
            onClick={(e) => updateRoute(e, '/home/tasks')}
            className={key === 8 ? `ant-menu-item-selected` : ''}
          >
            Tasks
          </Menu.Item>
          <Menu.Item
            key="9"
            icon={<CalendarOutlined />}
            onClick={(e) => updateRoute(e, '/home/calendar')}
            className={key === 9 ? `ant-menu-item-selected` : ''}
          >
            Calendar
          </Menu.Item>
          <Menu.Item
            key="10"
            icon={<FileOutlined />}
            onClick={(e) => updateRoute(e, '/home/document')}
            className={key === 10 ? `ant-menu-item-selected` : ''}
          >
            Documents
          </Menu.Item>
          <Menu.Item
            key="11"
            icon={<MailOutlined />}
            onClick={(e) => updateRoute(e, '/home/campaigns')}
            className={key === 11 ? `ant-menu-item-selected` : ''}
          >
            Campaigns
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed } = theme;
  return { navCollapsed };
};

const mapStateToDispatch = {
  toggleCollapsedNav,
};

export default connect(mapStateToProps, mapStateToDispatch)(SideNav);
