import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  ADD_CONTACT,
  ADD_BULK_CONTACT,
  GET_CONTACTS,
  CONTACT_DETAILS,
  GET_CONTACT_HISTORY_DETAILS
} from '../constants/Contacts';
import { service } from '../../service';
import {
  contactErrors,
  setContacts,
  responseMessage,
  updateContactDetails,
  setContactHistoryDetails
} from '../actions/Contacts';

export function* addContact() {
  yield takeEvery(ADD_CONTACT, function* ({ payload }) {
    let { url, data } = payload;
    try {
      data.date_of_birth = data.date_of_birth.format('YYYY-MM-DD');
      let response = yield call(service.post, url, data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(response.data.message));
        yield put(contactErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(contactErrors(error.response.data.errors));
    }
  });
}

export function* addBulkContact() {
  yield takeEvery(ADD_BULK_CONTACT, function* ({ payload }) {
    let { url, data } = payload;
    try {
      let response = yield call(service.post, url, data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(response.data.message));
        yield put(contactErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(contactErrors(error.response.data.errors));
    }
  });
}

export function* getContacts() {
  yield takeEvery(GET_CONTACTS, function* ({ payload }) {
    let { url, offset, bool } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setContacts({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* getContactDetails() {
  yield takeEvery(CONTACT_DETAILS, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      yield put(updateContactDetails(response));
    } catch (err) {}
  });
}

export function* getContactHistoryDetails() {
  yield takeEvery(GET_CONTACT_HISTORY_DETAILS, function* ({ id }) {
    try {
      let response = yield call(service.get, `/api/contacts/${id}/history/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(setContactHistoryDetails(response.data.data));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(contactErrors(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addContact),
    fork(getContacts),
    fork(addBulkContact),
    fork(getContactDetails),
    fork(getContactHistoryDetails),
  ]);
}
