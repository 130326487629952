import {
  INVESTOR_ERRORS,
  IS_LOADING,
  RESPONSE_MESSAGE,
  SET_INVESTORS_LIST,
  SET_INVESTOR_DETAILS,
  UPDATE_ERRORS
} from '../constants/Investors';

const initialState = {
  investors: {},
  investorsLoading: true,
  error: '',
};

const investors = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        investorsLoading: true,
      };
    case SET_INVESTORS_LIST:
      return {
        ...state,
        investors: action.data,
        investorsLoading: false,
      };
    case INVESTOR_ERRORS:
      return {
        ...state,
        investors: {},
        investorsLoading: false,
        error: action.data,
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case SET_INVESTOR_DETAILS:
      return {
        ...state,
        investor: action.data
      }
    default:
      return state;
  }
};

export default investors;
