import {
  SET_TASKS_DATA,
  RESPONSE_MESSAGE,
  UPDATE_ERRORS,
  REFRESH,
  UPDATE_TASK_DETAILS,
  TASK_UPDATED,
  UPDATE_TASK_NOTES,
} from '../constants/Tasks';

const initialState = {
  isLoading: true,
  tasksData: '',
  refresh: false,
  responseMessage: '',
  errors: [],
  offset: 0,
  taskDetails: {},
  taskUpdated: false,
  taskNotes: {},
};

const tasks = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS_DATA:
      return {
        ...state,
        tasksData: action.payload.response,
        offset: action.payload.response.offset,
        isLoading: false,
      };
    case UPDATE_TASK_DETAILS:
      return {
        ...state,
        taskDetails: action.data.data.task_obj,
        taskNotes: action.data.data.comments,
        loading: false,
      };
    case UPDATE_TASK_NOTES:
      return {
        ...state,
        taskNotes: action.data.data.comments,
        loading: false,
      };
    case RESPONSE_MESSAGE:
      return {
        ...state,
        responseMessage: action.payload,
      };
    case UPDATE_ERRORS:
      return {
        ...state,
        errors: action.payload,
      };
    case TASK_UPDATED:
      return {
        ...state,
        taskUpdated: action.val,
      };
    case REFRESH:
      return {
        ...state,
        refresh: action.payload,
      };
    default:
      return state;
  }
};

export default tasks;
