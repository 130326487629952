export const GET_EVENTS = 'GET_EVENTS';
export const SET_EVENTS_DATA = 'SET_EVENTS_DATA';
export const LOADING = 'LOADING';
export const ADD_EVENT = 'ADD_EVENT';
export const RESPONSE_MESSAGE = 'RESPONSE_MESSAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const DELETE_EVENT = 'DELETE_EVENT';
export const REFRESH = 'REFRESH';
export const EVENT_DETAILS = 'EVENT_DETAILS';
export const UPDATE_EVENT_DETAILS = 'UPDATE_EVENT_DETAILS';
export const EVENT_UPDATED = 'EVENT_UPDATED';
export const EVENT_DELETED = 'EVENT_DELETED';

