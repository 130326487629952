import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
const { Option } = Select;
const BillingAdress = (props) => {
  return (
    <Row className="address-component">
      <Col span={12}>
        <Form.Item
          label="Address Lane"
          name="billing_address_line"
          className="addresslane"
        >
          <Input className="req" />
        </Form.Item>
        <Form.Item label="Street" name="billing_street" className="street">
          <Input className="" />
        </Form.Item>
        <Form.Item
          label="Postal / Zip Code"
          name="billing_postcode"
          className="pincode"
        >
          <Input className="" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="City" name="billing_city" className="city">
          <Input className="" />
        </Form.Item>
        <Form.Item label="State" name="billing_state" className="state">
          <Input className="" />
        </Form.Item>
        <Form.Item
          label="Country"
          name="billing_country"
          className="country ant-dropdown-bg"
        >
          <Select>
            <Option key="US">United States</Option>
            <Option key="CA">Canada</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default BillingAdress;
