export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const SET_CAMPAIGNS_DATA = 'SET_CAMPAIGNS_DATA';
export const LOADING = 'LOADING';
export const ADD_CAMPAIGN = 'ADD_CAMPAIGN';
export const RESPONSE_MESSAGE = 'RESPONSE_MESSAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const REFRESH = 'REFRESH';
export const CAMPAIGN_DETAILS = 'CAMPAIGN_DETAILS';
export const UPDATE_CAMPAIGN_DETAILS = 'UPDATE_CAMPAIGN_DETAILS';
