import React from 'react';
import ReactQuill from 'react-quill';
import { connect } from 'react-redux';
import { getTaskNotes, addTaskNote } from '../../redux/actions/Tasks';
import 'react-quill/dist/quill.snow.css';
import './tasks.css';
import moment from 'moment';

export const TaskNotes = (props) => {
  const [taskNote, setTaskNote] = React.useState('');
  const { taskNotes, id } = props;

  console.log(taskNotes);

  function saveTaskNotes() {
    let commentObj = {
      id: id,
      comment: taskNote,
    };
    props.addTaskNote(commentObj);
  }

  return (
    <div>
      <ReactQuill
        theme="snow"
        className="main-desc"
        defaultValue={taskNote}
        onChange={setTaskNote}
        style={{ margin: '30px 50px 10px' }}
      />
      <div className="footer">
        <button className="cancel-btn">Cancel</button>
        <button className="save-btn" onClick={saveTaskNotes}>
          Add Note
        </button>
      </div>

      {taskNotes?.map((note, index) => {
        return (
          <>
            <div className="description">
              <div style={{ fontSize: 13 }}>
                <b>Commented On : </b>
                {moment(note.commented_on).format('yyyy-MM-DD hh:mm:ss')}
                <br />
                <b>Note : </b> {note.comment}
                <br />
                <b>Commented By : </b> {note.commented_by}
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, taskNotes } = state.tasks;
  return { loading, taskNotes };
};

const mapDispatchToProps = {
  getTaskNotes,
  addTaskNote,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskNotes);
