import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { service } from '../../service';
import {
  dealErrors,
  responseMessage,
  setDealDetails,
  setDealHistoryDetails,
  setDealsList,
  updateErrors,
} from '../actions/Deals';
import {
  ADD_BULK_DEALS,
  ADD_DEAL,
  GET_BULK_DEAL_TEMPLATE,
  GET_DEALS_LIST,
  GET_DEAL_DETAILS,
  UPDATE_DEAL_DETAILS,
  DELETE_DEAL,
  GET_DEAL_HISTORY_DETAILS,
} from '../constants/Deals';

export function* addBulkDeals() {
  yield takeEvery(ADD_BULK_DEALS, function* ({ payload }) {
    let { data } = payload;
    try {
      const formData = new FormData();
      formData.append('deal_attachment', data);
      let response = yield call(service.post, '/api/deals/bulk/', formData, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(response.data.message));
        yield put(dealErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        err = `At Row ${error.response.data.row + 1} : ${JSON.stringify(
          error.response.data.message
        )}`;
      }
      yield put(responseMessage(false));
      yield put(dealErrors(err));
    }
  });
}

export function* getDealsList() {
  yield takeEvery(GET_DEALS_LIST, function* ({ payload }) {
    let { limit, offset, qParam, pane } = payload;
    try {
      let url = `/api/deals/?limit=${limit}&offset=${offset || 0}`;
      url = qParam ? url + '&' + qParam : url;
      if (pane !== '') {
        url = url + '&deal_status=' + pane;
      }
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200 || response.status === 201) {
        yield put(setDealsList(response.data));
        yield put(responseMessage(false));
      }
    } catch (error) {}
  });
}

export function* addDeal() {
  yield takeEvery(ADD_DEAL, function* ({ data }) {
    try {
      let response = yield call(service.post, '/api/deals/', data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[i] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* getDealDetails() {
  yield takeEvery(GET_DEAL_DETAILS, function* ({ id }) {
    try {
      let response = yield call(service.get, `/api/deals/${id}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(setDealDetails(response.data.data));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(updateErrors(error));
    }
  });
}

export function* getDealHistoryDetails() {
  yield takeEvery(GET_DEAL_HISTORY_DETAILS, function* ({ id }) {
    try {
      let response = yield call(service.get, `/api/deals/${id}/history/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(setDealHistoryDetails(response.data.data));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(updateErrors(error));
    }
  });
}

export function* getBulkDealTemplate() {
  // eslint-disable-next-line require-yield
  yield takeEvery(GET_BULK_DEAL_TEMPLATE, function* () {
    const xReq = new XMLHttpRequest();
    xReq.open('GET', 'http://localhost:8000/api/deals/template', true);
    xReq.responseType = 'blob';
    xReq.setRequestHeader('Accept', 'application/json');
    xReq.setRequestHeader(
      'Authorization',
      `jwt ${localStorage.getItem('Token')}`
    );
    xReq.setRequestHeader('org', localStorage.getItem('company'));

    xReq.onload = function (e) {
      if (xReq.status === 200 && xReq.readyState === 4) {
        const blob = xReq.response;
        const fileName = xReq
          .getResponseHeader('content-disposition')
          .split('filename=')[1];
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob);
        } else {
          const downloadLink = window.document.createElement('a');
          const contentTypeHeader = xReq.getResponseHeader('Content-Type');
          downloadLink.href = window.URL.createObjectURL(
            new Blob([blob], { type: contentTypeHeader })
          );
          downloadLink.download = fileName;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        }
      }
    };
    xReq.send();
  });
}

export function* deleteDealById() {
  yield takeEvery(DELETE_DEAL, function* ({ id }) {
    try {
      let response = yield call(service.delete, `/api/deals/${id}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (
        (response.status === 200 || response.status === 201) &&
        !response.data?.error
      ) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      yield put(responseMessage(false));
      yield put(updateErrors(error));
    }
  });
}

export function* updateDealDetails() {
  yield takeEvery(UPDATE_DEAL_DETAILS, function* ({ data }) {
    try {
      let response = yield call(service.patch, `/api/deals/${data.id}`, data, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[i] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(addBulkDeals),
    fork(getDealsList),
    fork(getBulkDealTemplate),
    fork(addDeal),
    fork(getDealDetails),
    fork(getDealHistoryDetails),
    fork(updateDealDetails),
    fork(deleteDealById),
  ]);
}
