import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import './style.css'
export const PageCounter = (props) => {
  const { count, page, per_page, previous, next } = props;
  return (
    <span className="toolbar-pagination">
      <LeftOutlined
        onClick={previous}
        className="toolbar-pages-count-leftoutlined"
      />
      <span className="toolbar-pagination-numbers">
        {`${page} of ${Math.ceil(count / per_page)}`}
      </span>
      <RightOutlined
        onClick={next}
        className="toolbar-pages-count-rightoutlined"
      />
    </span>
  );
};
