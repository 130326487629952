import {
  CheckOutlined,
  CloseCircleOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Row } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import { capFirstLetter } from '../../../../utils/stringUtils';
import './StyledToolbar.css';

export const StyledToolbar = (props) => {
  const { id, mode, module, onSubmit, onDelete, title } = props;
  const moduleLabel = module ? capFirstLetter(module) : '';

  return (
    <Row className="main-toolbar">
      <Breadcrumb className="main-toolbar-breadcrumb">
        <Breadcrumb.Item>
          <Link to="/home">{title ?? 'Home'}</Link>
        </Breadcrumb.Item>
        {module && (
          <Breadcrumb.Item>
            <Link to={`/home/${module}`}>{moduleLabel}</Link>
          </Breadcrumb.Item>
        )}
        {mode && (
          <Breadcrumb.Item>
            {mode === 'add' && (
              <Link to={`/home/${module}/new`}>Add {moduleLabel}</Link>
            )}
            {mode === 'edit' && (
              <Link to={`/home/${module}/${id}/details`}>
                Edit {moduleLabel} {id}
              </Link>
            )}
            {mode === 'history' && (
              <Link to={`/home/${module}/${id}/details`}>
                History {moduleLabel} {id}
              </Link>
            )}
            {mode === 'bulk' && (
              <Link to={`/home/${module}/bulk`}>
                Bulk Upload {moduleLabel}
              </Link>
            )}
          </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <Row>
        {module && (
          <Link to={`/home/${module}`}>
            <Button
              type="primary"
              className="btn text-white bg-darkblue fw-12 fw-6"
            >
              <LeftOutlined />
              Back To {moduleLabel}
            </Button>
          </Link>
        )}
        {onSubmit ? (
          <>
            <Link to={`/home/${module}`}>
              <Button
                type="primary"
                className="btn text-white bg-darkblue fw-12 fw-6"
              >
                <CloseCircleOutlined />
                Cancel
              </Button>
            </Link>
            <Button type="primary" className="btn text-white fw-12 fw-6">
              <CheckOutlined />
              <span onClick={onSubmit}>Submit</span>
            </Button>
          </>
        ) : null}
        {onDelete ? (
          <Button
            type="primary"
            danger
            className="btn text-white fw-12 fw-6"
            onClick={onDelete}
          >
            Delete
          </Button>
        ) : null}
      </Row>
    </Row>
  );
};

export default withRouter(StyledToolbar);
