import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { getEventDetails } from '../../redux/actions/Events';
import { putData, deleteData } from '../../redux/actions/Fetch';
import './detailPage.css';
import 'antd/dist/antd.css';
import { EditOutlined } from '@ant-design/icons';
import { Input, Form, Tabs, Button, Select } from 'antd';

export const EventDetails = (props) => {
  const [edit, setEdit] = useState(true);

  const { eventDetails, loading, eventUpdated } = props;

  // others
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [contacts, setContacts] = useState([]);

  const [details, setDetails] = useState('');

  const id = props.match.params.id;

  function updateEventDetails(e) {
    console.log('onsubmit');
    let eventObj = {
      name: name,
      description: description,
      start_date: startDate,
      start_time: startTime,
      end_date: endDate,
      end_time: endTime,
      event_type: 'Non-Recurring',
      // contacts: contacts,
    };
    props.putData(`api/events/${id}/`, 'events', eventObj, id);
    console.log(eventUpdated);
    props.history.push('/home/calendar');
  }

  function removeFromEvents(e) {
    console.log('onDelete');
    props.deleteData(`api/events/${id}/`, 'events', id);
    props.history.push('/home/calendar');
  }

  useEffect(() => {
    props.getEventDetails(`/api/events/${id}/`);
  }, []);

  useEffect(() => {
    setDetails(eventDetails);
  }, [loading]);

  useEffect(() => {
    setName(eventDetails.name);
    setDescription(eventDetails.description);
    setStartDate(eventDetails.start_date);
    setEndDate(eventDetails.end_date);
    setStartTime(eventDetails.start_time);
    setEndTime(eventDetails.end_time);
    console.log(eventDetails.contacts);
    const contactArr = [];
    for (var key in eventDetails.contacts) {
      var obj = eventDetails.contacts[key];
      contactArr.push(obj.first_name + '' + obj.last_name);
    }
    console.log(contactArr);
    setContacts(contactArr);
  }, [eventDetails]);

  console.log(details);

  return (
    <div className="overall-Cont">
      <div>
        <Button
          type="primary"
          className="edit-button"
          onClick={(edit) => setEdit(!edit)}
        >
          Edit
        </Button>
        <Button
          type="danger"
          className="edit-button"
          onClick={() => {
            if (window.confirm('Delete the item?')) {
              removeFromEvents();
            }
          }}
        >
          Delete
        </Button>
      </div>
      <Tabs>
        <Tabs.TabPane tab="Details" key="details">
          <div className="main-box">
            <div className="other-data">
              <Form
                layout="vertical"
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <Form.Item label="Title:" style={{ width: '60%' }}>
                  <Input
                    placeholder="Title"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Start Date:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Starts At - Date"
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Start Time:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Starts At - Time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="End Date:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Ends At - Date"
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="End Time:" style={{ width: '49%' }}>
                  <Input
                    placeholder="Ends At - Time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Description:" style={{ width: '60%' }}>
                  <Input
                    placeholder="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled={edit ? 'disabled' : ''}
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
                <Form.Item label="Contacts:" style={{ width: '60%' }}>
                  <Select
                    mode="multiple"
                    placeholder="Contacts"
                    value={contacts}
                    // onChange={(e) => setContacts(e.target.value)}
                    style={{ marginBottom: 10 }}
                    disabled="disabled"
                    suffix={
                      <EditOutlined
                        style={{
                          color: 'rgba(0,0,0,.45)',
                        }}
                      />
                    }
                  />
                </Form.Item>
              </Form>
            </div>

            {!edit ? (
              <div className="save-cancel">
                <Button type="danger">Cancel</Button>
                <Button
                  style={{ marginLeft: '20px' }}
                  type="primary"
                  onClick={updateEventDetails}
                >
                  Submit
                </Button>
              </div>
            ) : (
              ' '
            )}
          </div>
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, eventDetails, eventUpdated } = state.events;
  return { loading, eventDetails, eventUpdated };
};

const mapDispatchToProps = {
  getEventDetails,
  putData,
  deleteData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EventDetails);
