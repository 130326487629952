import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
const { Option } = Select;

const Address = (props) => {

  const {hideCountry} = props;
  return (
    <Row className="address-component">
      <Col span={12}>
        <Form.Item
          label="Address Lane"
          name="address_line"
          className="addresslane"
        >
          <Input className="req" />
        </Form.Item>
        <Form.Item label="Street" name="street" className="street">
          <Input className="" />
        </Form.Item>
        <Form.Item
          label="Postal / Zip Code"
          name="postcode"
          className="postcode"
        >
          <Input className="" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="City" name="city" className="city">
          <Input className="" />
        </Form.Item>
        <Form.Item label="State" name="state" className="state">
          <Input className="" />
        </Form.Item>
        {!hideCountry && (
          <Form.Item
            label="Country"
            name="country"
            className="country ant-dropdown-bg"
          >
            <Select>
              <Option key="US">United States</Option>
              <Option key="CA">Canada</Option>
            </Select>
          </Form.Item>
        )}
      </Col>
    </Row>
  );
};

export default Address;
