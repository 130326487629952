import React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import { Collapse } from 'antd';
import { Row, Col, Button, Form, Input, Select } from 'antd';
import {
  PlusOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { connect } from 'react-redux';
import { putData } from '../../redux/actions/Fetch';
import {
  getUserDetails,
  updateUserDetails,
  isLoading,
  updateUserFormData,
} from '../../redux/actions/Users';

const { Panel } = Collapse;
const { Option } = Select;

const EditUser = (props) => {
  const [openAltEmail, setOpenAltEmail] = useState(false);
  const [openAltPhone, setOpenAltPhone] = useState(false);

  const [is_active, setIsactive] = useState(true);

  const [form] = Form.useForm();

  const users = useSelector((app) => app.users);
  const userDetails = users.userDetails;

  useEffect(() => {
    console.log('User details changed', userDetails);
    updateFormDetails(userDetails);
  }, [userDetails]);

  const updateFormDetails = (userDetails) => {
    const combined = {
      ...userDetails['address'],
      ...userDetails['user_details'],
      date_of_joining: userDetails.date_of_joining,
      has_marketing_access: userDetails.has_marketing_access,
      has_sales_access: userDetails.has_sales_access,
      is_active: userDetails.is_active,
      role: userDetails.role,
      phone: userDetails.phone,
      id: userDetails.id,
    };
    form.setFieldsValue({
      ...combined,
    });
  };

  useEffect(() => {
    props.getUserDetails(`/api/users/${props.match.params.id}/`);
  }, [props.match.params.id]);

  const updateUser = (e) => {
    props.putData(
      `api/users/${props.match.params.id}/`,
      'users',
      e,
      props.match.params.id
    );
  };

  if (props.loading) {
    return <div></div>;
  }

  return (
    <div className="app-area">
      <div className="users-information">
        <div className="toggle-button d-flex align-items-center">
          <span className="toggle-button-background"></span>
          {/* TODO: Handle is active */}
          {/* <span
            className="toggle-button-circle"
            style={{ marginLeft: is_active ? '17px' : '1px' }}
            onClick={() => setIsactive(!is_active)}
          ></span>
          <span className="toggle-button-text">
            {is_active ? 'Enable User' : 'Disable User'}
          </span> */}
        </div>
        <Form onFinish={updateUser} className="edit-user" form={form}>
          <StyledToolbar module="users" mode="edit" id={props.match.params.id}/>
          <Collapse defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header="User Details" key="1">
              <Row>
                <Col span={12} style={{ padding: '10px' }}>
                  <Form.Item
                    label="First Name"
                    name="first_name"
                    className="user-name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input first name',
                      },
                    ]}
                  >
                    <Input type="text" id="first_name" className="required" />
                  </Form.Item>
                  <Form.Item
                    label="Last Name"
                    name="last_name"
                    className="user-name"
                  >
                    <Input type="text" id="last_name" className="req" />
                  </Form.Item>
                  <Form.Item
                    label="User Role"
                    name="role"
                    className="user-userrole"
                  >
                    <Select defaultValue={'USER'}>
                      <Option value="ADMIN">ADMIN</Option>
                      <Option value="USER">USER</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: '10px' }}>
                  <Form.Item
                    label="Email Address"
                    name="email"
                    className="user-emailaddress"
                    rules={[
                      {
                        required: true,
                        message: 'Please input email',
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      id="email"
                      className="req"
                      suffix={
                        <PlusOutlined
                          className="add-email-btn"
                          onClick={() => setOpenAltEmail(true)}
                        />
                      }
                    />
                  </Form.Item>
                  {openAltEmail && (
                    <Form.Item
                      name="alternate_email"
                      style={
                        openAltEmail
                          ? { display: 'block', marginBottom: '20px' }
                          : { display: 'none' }
                      }
                    >
                      <Input
                        type="text"
                        id="alternate_email"
                        suffix={
                          <CloseOutlined
                            className="close-alternateemail-btn"
                            onClick={() => setOpenAltEmail(false)}
                          />
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Mobile Number"
                    name="phone"
                    className="user-mobilenumber"
                  >
                    <Input
                      type="text"
                      id="phone"
                      className="req"
                      suffix={
                        <PlusOutlined
                          className="add-phone-btn"
                          onClick={() => setOpenAltPhone(true)}
                        />
                      }
                    />
                  </Form.Item>
                  {openAltPhone && (
                    <Form.Item name="alternate_phone" label="Alternate Number">
                      <Input
                        type="text"
                        id="alternate_phone"
                        suffix={
                          <CloseOutlined
                            className="close-alternateemail-btn"
                            onClick={() => setOpenAltPhone(false)}
                          />
                        }
                      />
                    </Form.Item>
                  )}
                  <Form.Item
                    label="Skype ID"
                    name="skype_ID"
                    className="user-skype"
                  >
                    <Input type="text" id="skype_ID" className="req" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            className="mt-4"
          >
            <Panel header="Address Details" key="1">
              <Row className="address-component">
                <Col span={12} style={{ padding: '10px' }}>
                  <Form.Item
                    label="Address Lane"
                    name="address_line"
                    className="addresslane"
                  >
                    <Input type="text" id="address_line" className="req" />
                  </Form.Item>
                  <Form.Item label="Street" name="street" className="street">
                    <Input type="text" id="street" />
                  </Form.Item>
                  <Form.Item
                    label="Post Code"
                    name="postcode"
                    className="postcode"
                  >
                    <Input type="text" id="postcode" className="req" />
                  </Form.Item>
                </Col>
                <Col span={12} style={{ padding: '10px' }}>
                  <Form.Item label="City" name="city" className="city">
                    <Input type="text" id="city" className="req" />
                  </Form.Item>
                  <Form.Item label="State" name="state" className="state">
                    <Input type="text" id="state" className="req" />
                  </Form.Item>
                  <Form.Item label="Country" name="country" className="country">
                    <Input type="text" id="country" className="req" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>

          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            className="mt-4"
          >
            <Panel header="Description" key="1">
              <Form.Item label="" name="description">
                <ReactQuill id="descripiton" />
              </Form.Item>
            </Panel>
          </Collapse>

          <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
            <Button className="btn-cancel mr-2">
              <CloseCircleOutlined />
              Cancel
            </Button>
            <Button className="btn-save" htmlType="submit">
              <CheckOutlined />
              Save
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { loading, userDetails, userUpdated } = state.users;
  return { loading, userDetails, userUpdated };
};

const mapDispatchToProps = {
  getUserDetails,
  updateUserDetails,
  isLoading,
  updateUserFormData,
  putData,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
