import React, { useState, useEffect } from 'react';
import { Button, Input, Form, Radio, Tabs,Select } from 'antd';
import 'antd/dist/antd.css';
import './style.css';
import { connect } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { patchData } from '../../redux/actions/Fetch';
import { getContactDetails } from '../../redux/actions/Contacts';
import moment from 'moment';
import ContactsEvents from './ContactsEvents';
import ContactsTasks from './ContactsTasks';


const {Option} = Select
export const ContactDetails = (props) => {
  const {
    contactDetails,
    loading,
    contactUpdated,
    contactTasks,
    contactEvents,
    addressDetails,
  } = props;

  const [salutation, setSalutation] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState(new Date());
  const [organization, setOrganization] = useState('');
  const [title, setTitle] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [alternativeEmail, setAlternativeEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [secphone, setSecPhone] = useState('');
  const [country, setCountry] = useState('');
  const [language, setLanguage] = useState('');
  const [doNotCall, setDoNotCall] = useState('');
  const [hasSubscribed, setHasSubscribed] = useState('');
  //address
  const [addressLine, setAddressLine] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [addrCountry, setAddrCountry] = useState('');

  const [details, setDetails] = useState('');
  const [tasks, setTasks] = useState('');
  const [events, setEvents] = useState('');

  const id = props.match.params.id;

  useEffect(() => {
    props.getContactDetails(`/api/contacts/${id}/`);
  }, []);

  useEffect(() => {
    setDetails(contactDetails);
    setTasks(contactTasks);
    setEvents(contactEvents);
  }, [loading]);

  function updateContactDetails(e) {
    let contactObj = {
      salutation: salutation,
      first_name: firstName,
      last_name: lastName,
      date_of_birth: moment(dob).format('YYYY-MM-DD'),
      title: title,
      primary_email: primaryEmail,
      secondary_email: alternativeEmail,
      do_not_call: doNotCall,
      has_subscribed: hasSubscribed,
      secondary_number: secphone,
      mobile_number: phone,
      language: language,
      contacts_country: country,
      address_line: addressLine,
      street: street,
      city: city,
      postcode: postalCode,
      state: state,
      country: addrCountry,
      id: id,
    };
    props.patchData(`api/contacts/${id}/`, 'contacts', contactObj, id);
  }

  useEffect(() => {
    if (contactUpdated) {
      props.history.push('/home/contacts');
    }
  },
  [contactUpdated])

  useEffect(() => {
    // contact details
    setTitle(contactDetails?.title);
    setPrimaryEmail(contactDetails?.primary_email);
    setFirstName(contactDetails?.first_name);
    setLastName(contactDetails?.last_name);
    setAlternativeEmail(contactDetails?.secondary_email);
    setPhone(contactDetails?.mobile_number);
    setSecPhone(contactDetails?.secondary_number);
    setDob(moment(contactDetails?.date_of_birth).format('YYYY-MM-DD'));
    setSalutation(contactDetails?.salutation);
    setOrganization(contactDetails?.organization);
    setLanguage(contactDetails?.language);
    setCountry(contactDetails?.contacts_country);
    setDoNotCall(contactDetails?.do_not_call);
    setHasSubscribed(contactDetails?.has_subscribed);
    // address details
    setAddressLine(addressDetails?.address_line);
    setStreet(addressDetails?.street);
    setState(addressDetails?.state);
    setPostalCode(addressDetails?.postcode);
    setAddrCountry(addressDetails?.country);
    setCity(addressDetails?.city);
  }, [contactDetails]);
  return (
    <>
      <div className="overall-Cont">
        <Tabs>
          <Tabs.TabPane tab="Details" key="details">
            <div className="detail-section">
              <div className="user-cont">
                <div className="user-data">
                  <Form
                    layout="vertical"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Form.Item label="Salutation" style={{ width: '49%' }}>
                      <Input
                        placeholder="Salutation"
                        value={salutation}
                        onChange={(e) => setSalutation(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="First Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Last Name:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Date Of Birth:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Date Of Birth"
                        value={dob}
                        onChange={(e) => setDob(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Organization" style={{ width: '49%' }}>
                      <Input
                        placeholder="Organization"
                        value={organization}
                        onChange={(e) => setOrganization(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Title" style={{ width: '49%' }}>
                      <Input
                        placeholder="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Email:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Email"
                        value={primaryEmail}
                        onChange={(e) => setPrimaryEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Alternative email:"
                      style={{ width: '49%' }}
                    >
                      <Input
                        placeholder="Alternative email"
                        value={alternativeEmail}
                        onChange={(e) => setAlternativeEmail(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Mobile Number:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Mobile Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="Secondary Number:"
                      style={{ width: '49%' }}
                    >
                      <Input
                        placeholder="Secondary Number"
                        value={secphone}
                        onChange={(e) => setSecPhone(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Country" style={{ width: '49%' }}>
                      <Select onChange={(e) => {setCountry(e)}} value={country}>
                          <Option key="US">United States</Option>
                          <Option key="CA">Canada</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="Language" style={{ width: '49%' }}>
                      <Input
                        placeholder="Language"
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Marketing Status:">
                      <Radio.Group value={hasSubscribed} 
                        onChange={(val)=>{
                          setHasSubscribed(val.target.value)
                        }}>
                        <Radio value={true}>Subscribe</Radio>
                        <Radio value={false}>Unsubscribe</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Form>
                </div>
              </div>

              <div className="address-cont">
                <div className="user-data">
                  <Form
                    layout="vertical"
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Form.Item label="Address Lane" style={{ width: '49%' }}>
                      <Input
                        placeholder="Address Lane"
                        value={addressLine}
                        onChange={(e) => setAddressLine(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="Street:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Street"
                        value={street}
                        onChange={(e) => setStreet(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="City:" style={{ width: '49%' }}>
                      <Input
                        placeholder="City"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                    <Form.Item label="State:" style={{ width: '49%' }}>
                      <Input
                        placeholder="State"
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                      {/* <Select options={options} value={value} onChange={changeHandler} disabled={edit ? "disabled" : ""}/> */}
                    </Form.Item>
                    <Form.Item label="Postal Code:" style={{ width: '49%' }}>
                      <Input
                        placeholder="Postal Code"
                        type="number"
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        style={{ marginBottom: 10 }}
                        
                        suffix={
                          <EditOutlined
                            style={{
                              color: 'rgba(0,0,0,.45)',
                            }}
                          />
                        }
                      />
                    </Form.Item>
                  </Form>
                </div>
              </div>
                <div className="save-cancel">
                  <Button type="danger" onClick={() =>       props.history.push('/home/contacts')}>
                    Cancel
                  </Button>
                  <Button
                    style={{ marginLeft: '20px' }}
                    type="primary"
                    onClick={updateContactDetails}
                  >
                    Submit
                  </Button>
                </div>
            </div>
          </Tabs.TabPane>

          <Tabs.TabPane key="task" tab="Task">
            <ContactsTasks tasks={tasks} />
          </Tabs.TabPane>
          <Tabs.TabPane key="event" tab="Event">
            <ContactsEvents events={events} />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    loading,
    contactDetails,
    contactUpdated,
    contactTasks,
    contactEvents,
    addressDetails,
  } = state.contacts;
  return {
    loading,
    contactDetails,
    contactUpdated,
    contactTasks,
    contactEvents,
    addressDetails,
  };
};

const mapDispatchToProps = {
  getContactDetails,
  patchData,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDetails);
