import { SET_AUTOSEARCH_DATA ,SET_COMMENTS_HISTORY} from '../constants/Common';

const initialState = {
  isLoading: true,
  refresh: false,
  responseMessage: '',
  errors: [],
};

const common = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTOSEARCH_DATA:
      return {
        ...state,
        autosearch : {...state.autosearch, [action.payload.url]: action.payload.data, },
        isLoading: false,
      };
    default:
      return state;
  }
};

export default common;
