import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { service } from '../../service';
import AddOrganization from '../Organization/CreateOrganization/Index';
import AccountDetails from '../accounts/AccountDetails';
import AccountsList from '../accounts/AccountsList';
import AddAccount from '../accounts/AddAccount';
import CreateEmailCampaign from '../campaigns/CreateEmailCampaign';
import DetailCampaign from '../campaigns/DetailCampaign';
import EmailCampaignList from '../campaigns/EmailCampaignList';
import ComposeMail from '../compose/ComposeMail';
import AddBulkContact from '../contacts/AddBulkContact';
import AddContact from '../contacts/AddContact';
import ContactDetails from '../contacts/ContactDetails';
import ContactsList from '../contacts/ContactsList';
import CrmDashboard from '../crm-dashboard/crm-dashboard';
import AddBulkDeals from '../deals/AddBulkDeals';
import AddDeal from '../deals/AddDeal';
import DealDetails from '../deals/DealDetails';
import DealsList from '../deals/DealsList';
import DealHistory from '../deals/DealHistory';
import DocumentsList from '../documents/DocumentsList';
import CalendarComp from '../events/CalendarComp';
import EventDetails from '../events/EventDetails';
import AddRetailInvestor from '../investors/addIndividual/AddRetailInvestor';
import AddInstitutionalInvestor from '../investors/addIndividual/AddInstitutionalInvestor';
import InvestorsList from '../investors/InvestorsList';
import AddBulkLeads from '../leads/AddBulkLeads';
import AddLead from '../leads/AddLead';
import LeadsList from '../leads/LeadsList';
import AddTask from '../tasks/AddTask';
import TaskDetails from '../tasks/TaskDetails';
import TasksList from '../tasks/TasksList';
import TemplatesEdit from '../templates/TemplatesEdit';
import TemplatesList from '../templates/TemplatesList';
import TemplatesNew from '../templates/TemplatesNew';
import AddUser from '../users/AddUser';
import EditUser from '../users/EditUser';
import UserDetails from '../users/UserDetails';
import UsersList from '../users/Users';
import AddBulkInvestors from '../investors/AddBulkInvestors';
import InvestorDetails from '../investors/InvestorDetails';
import ContactHistory from '../contacts/ContactHistory';

const Routes = (props) => {
  useEffect(() => {
    service.defaults.headers['Authorization'] =
      'jwt ' + window.localStorage.getItem('Token');
    service
      .get('/api/auth/companies-list/')
      .then((res) => {})
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = '/login';
        }
      });
  }, []);

  return (
    <Switch>
      <Route exact path="/home" component={CrmDashboard} />
      <Route exact path="/home/leads/" component={LeadsList} />
      <Route exact path="/home/leads/bulk" component={AddBulkLeads} />
      <Route exact path="/home/leads/new" component={AddLead} />
      <Route exact path="/home/users/" component={UsersList} />
      <Route exact path="/home/users/new" component={AddUser} />
      <Route exact path="/home/users/:id/edit" component={EditUser} />
      <Route exact path="/home/users/:id/details" component={UserDetails} />
      <Route exact path="/home/compose" component={ComposeMail} />
      <Route exact path="/home/contacts/bulk" component={AddBulkContact} />
      <Route exact path="/home/contacts/new" component={AddContact} />
      <Route exact path="/home/contacts/" component={ContactsList} />
      <Route
        exact
        path="/home/contacts/:id/details"
        component={ContactDetails}
      />
      <Route
        exact
        path="/home/contacts/:id/history"
        component={ContactHistory}
      />
      <Route exact path="/home/accounts/new/" component={AddAccount} />
      <Route exact path="/home/accounts/" component={AccountsList} />
      <Route
        exact
        path="/home/accounts/:id/details"
        component={AccountDetails}
      />
      <Route exact path="/home/companies/new" component={AddOrganization} />
      <Route exact path="/home/deals" component={DealsList} />
      <Route exact path="/home/deals/bulk" component={AddBulkDeals} />
      <Route exact path="/home/deals/:id/dealhistory" component={DealHistory}/>
      <Route exact path="/home/deals/new" component={AddDeal} />
      <Route exact path="/home/deals/:id/details" component={DealDetails}/>
      <Route exact path="/home/investors" component={InvestorsList} />
      <Route exact path="/home/investors/indNew" component={AddRetailInvestor} />
      <Route exact path="/home/investors/instNew" component={AddInstitutionalInvestor} />
      <Route exact path="/home/investors/bulk" component={AddBulkInvestors} />
      <Route exact path="/home/investors/:id/details" component={InvestorDetails} />
      <Route exact path="/home/tasks" component={TasksList} />
      <Route exact path="/home/tasks/:id/details" component={TaskDetails} />
      <Route exact path="/home/tasks/new" component={AddTask} />
      <Route exact path="/home/calendar" component={CalendarComp} />
      <Route exact path="/home/events/:id/details" component={EventDetails} />
      <Route
        exact
        path="/home/campaigns/new"
        component={CreateEmailCampaign}
      />
      <Route exact path="/home/campaigns" component={EmailCampaignList} />
      <Route
        exact
        path="/home/campaigns/:id/details"
        component={DetailCampaign}
      />
      <Route exact path="/home/document" component={DocumentsList} />
      <Route exact path="/home/templates/list" component={TemplatesList} />
      <Route exact path="/home/templates/" component={TemplatesList} />
      <Route exact path="/home/templates/:id/edit" component={TemplatesEdit} />
      <Route exact path="/home/templates/new" component={TemplatesNew} />
    </Switch>
  );
};

export default Routes;
