import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Col, Collapse, Form, Row } from 'antd';
import { motion } from 'framer-motion';
import Papa from 'papaparse';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { addBulkContact, contactErrors } from '../../redux/actions/Contacts';

const { Panel } = Collapse;

export const AddBulkContact = (props) => {
  // State to store parsed data
  const [parsedData, setParsedData] = useState([]);
  const [errors, setErrors] = useState(props.errors !== '' ? props.errors : '');
  const [responseMessage, setResponseMessage] = useState(
    props.responseMessage !== '' ? props.responseMessage : ''
  );

  useEffect(() => {
    if (responseMessage != '') {
      props.history.push('/home/contacts');
    }
  }, [responseMessage]);

  useEffect(() => {
    let errorsInContact = {};
    let errorsInForm = '';
    if (props.errors != '') {
      errorsInContact = props.errors['error']['contact_errors'];
      if (errorsInContact != null || errorsInContact != undefined) {
        let contactErrors = Object.values(errorsInContact);
        contactErrors.forEach((element) => {
          errorsInForm += element[0] + ', ';
        });
      }
      setErrors(errorsInForm);
    }
    if (props.responseMessage != '' || props.responseMessage != undefined) {
      setResponseMessage(props.responseMessage);
    }
  });

  const addBulkContact = () => {
    props.contactErrors('');
    props.addBulkContact('/api/contacts/bulk/', parsedData);
  };

  const changeHandler = (event) => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        // Parsed Data Response in array format
        setParsedData(results.data);
      },
    });
  };
  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {errors != '' ? (
          <Alert type="error" closable message={errors}></Alert>
        ) : (
          ''
        )}
        {responseMessage != '' && responseMessage != undefined ? (
          <Alert type="success" closable message={responseMessage}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <div className="app-area">
        <StyledToolbar module="contacts" mode="bulk" />
        <Collapse
          expandIconPosition="end"
          defaultActiveKey={[1]}
          collapsible="disabled"
        >
          <Panel key="1">
            <Row justify="start">
              <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                <Form.Item label="Select File" name="document_attachment">
                  <input
                    type="file"
                    name="file"
                    accept=".csv"
                    onChange={changeHandler}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Panel>
        </Collapse>

        <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
          <Button
            className="btn-cancel mr-2"
            onClick={() => {
              props.history.push('/home/contacts');
            }}
          >
            <CloseCircleOutlined />
            Cancel
          </Button>
          <Button className="btn-save" onClick={addBulkContact}>
            <CheckOutlined />
            Save
          </Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, responseMessage } = state.contacts;
  return { errors, responseMessage };
};

const mapDispatchToProps = {
  addBulkContact,
  contactErrors,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddBulkContact);
