import {
  SET_LEAD_DATA,
  SET_OPEN_TASKS_DATA,
  SET_TOP_EMPLOYEE_EMAIL_DATA,
  SET_NUM_CLOSED_DEALS,
  SET_LEADS_BY_SOURCE_DATA,
  SET_DEAL_DEACTIVATE_REASON_DATA,
  SET_AVG_DEAL_CLOSING_RATE,
  SET_DEALS_WON_DATA,
  SET_AVG_REVENUE_DATA,
  SET_NUM_INVESTORS_DATA,
} from '../constants/CrmDashboard';

const initialState = {
  dashboardLoading: true,
  leadData: {},
  numClosedDeals: {},
  openTasksData: {},
  topEmployeeEmailData: {},
  leadsBySourceData: {},
  responseMessage: '',
  errors: [],
  dealDeactivateReasonData: {},
  avgDealClosingRateData: {},
  dealWonData: {},
  avgRevenueData: {},
  numInvestors: {},
};

const CrmDashboard = (state = initialState, action) => {
  switch (action.type) {
    case SET_LEAD_DATA:
      return {
        ...state,
        dashboardLoading: false,
        leadData: action.payload.data,
      };
    case SET_OPEN_TASKS_DATA:
      return {
        ...state,
        dashboardLoading: false,
        openTasksData: action.payload.data,
      };
    case SET_TOP_EMPLOYEE_EMAIL_DATA:
      return {
        ...state,
        dashboardLoading: false,
        topEmployeeEmailData: action.payload.data,
      };
    case SET_LEADS_BY_SOURCE_DATA:
      return {
        ...state,
        dashboardLoading: false,
        leadsBySourceData: action.payload.data,
      };

    case SET_NUM_CLOSED_DEALS:
      return {
        ...state,
        dashboardLoading: false,
        numClosedDeals: action.payload.data,
      };

    case SET_DEAL_DEACTIVATE_REASON_DATA:
      return {
        ...state,
        dashboardLoading: false,
        dealDeactivateReasonData: action.payload.data,
      };
    
    case SET_AVG_DEAL_CLOSING_RATE:
      return {
        ...state,
        dashboardLoading: false,
        avgDealClosingRateData: action.payload.data,
      }
    case SET_DEALS_WON_DATA:
      return {
        ...state,
        dashboardLoading: false,
        dealWonData: action.payload.data,
      };

    case SET_AVG_REVENUE_DATA:
      return {
        ...state,
        dashboardLoading: false,
        avgRevenueData: action.payload.data,
      };

    case SET_NUM_INVESTORS_DATA:
      return {
        ...state,
        dashboardLoading: false,
        numInvestors: action.payload.data,
      };

    default:
      return state;
  }
};

export default CrmDashboard;
