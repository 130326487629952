import { Form } from 'antd';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import {
  getInvestorDetails,
  updateInvestorDetails,
} from '../../redux/actions/Investors';
import UpdateInstitutionalInvestor from './updateIndividual/UpdateInstitutionalInvestor';
import UpdateRetailInvestor from './updateIndividual/UpdateRetailInvestor';

export const InvestorDetails = (props) => {
  const id = props.match.params.id;
  const { investor, responseMessage } = props;

  const updateInvestor = (values) => {
    values['id']=id
    props.updateInvestorDetails(values);
  };

  const [form] = Form.useForm();

  useEffect(() => {
    props.getInvestorDetails(id);
  }, [id]);

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/investors');
    }
  }, [responseMessage]);

  return (
    <>
      <div className="app-area">
        <StyledToolbar id={id} module="investors" mode="edit" onSubmit={() => form.submit()}/>
        {investor?.investor_type === 'INSTITUTIONAL' ? (
          <UpdateInstitutionalInvestor
            form={form}
            investor={investor}
            updateInvestor={updateInvestor}
          />
        ) : (
          <UpdateRetailInvestor
            form={form}
            investor={investor}
            updateInvestor={updateInvestor}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { responseMessage, errors, investor } = state.investors;
  return { responseMessage, errors, investor };
};

const mapDispatchToProps = {
  getInvestorDetails,
  updateInvestorDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestorDetails);
