import { combineReducers } from 'redux';
import Accounts from './Accounts';
import Auth from './Auth';
import Campaigns from './Campaigns';
import Common from './Common';
import Companies from './Companies';
import Contacts from './Contacts';
import CrmDashboard from './CrmDashboard';
import Deals from './Deals';
import Events from './Events';
import Investors from './Investors';
import Leads from './Leads';
import profiles from './Profiles';
import Tasks from './Tasks';
import Theme from './Theme';
import Users from './Users';

const reducers = combineReducers({
  accounts: Accounts,
  auth: Auth,
  campaigns: Campaigns,
  common: Common,
  companies: Companies,
  crmDashboard:CrmDashboard,
  contacts: Contacts,
  deals:Deals,
  investors: Investors,
  events: Events, 
  theme: Theme,
  leads: Leads,
  profiles: profiles,
  tasks: Tasks,
  users: Users,
});

export default reducers;
