import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  GET_ACCOUNTS,
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  ACCOUNT_DETAILS,
} from '../constants/Accounts';
import {
  setAccountsData,
  refresh,
  responseMessage,
  updateErrors,
  updateAccountDetails,
} from '../actions/Accounts';
import { service } from '../../service';

export function* getAccountsList() {
  yield takeEvery(GET_ACCOUNTS, function* ({ payload }) {
    let { url, offset } = payload;
    try {
      let response = yield call(service.get, `${url}?offset=${offset}`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (response.status === 200) {
        yield put(setAccountsData({ response: response.data }));
      }
    } catch (error) {}
  });
}

export function* addAccount() {
  yield takeEvery(ADD_ACCOUNT, function* ({ payload }) {
    try {
      payload.lead =
        payload.lead && payload.lead.length > 0 ? payload.lead[0] : '';
      let response = yield call(service.post, '/api/accounts/', payload, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(responseMessage(true));
        yield put(updateErrors([]));
      }
    } catch (error) {
      let err = '';
      if (error.response.data.error) {
        for (let i in Object.values(error.response.data.errors)) {
          err = err + Object.values(error.response.data.errors)[0] + ', ';
        }
      }
      yield put(responseMessage(false));
      yield put(updateErrors(error.response.data.errors));
    }
  });
}

export function* deleteAccount() {
  yield takeEvery(DELETE_ACCOUNT, function* ({ payload }) {
    let { id, bool } = payload;
    try {
      let response = yield call(service.delete, `/api/accounts/${id}/`, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      if (!response.data.error) {
        yield put(refresh(bool));
      }
    } catch (error) {}
  });
}

export function* getAccountDetails() {
  yield takeEvery(ACCOUNT_DETAILS, function* ({ url }) {
    try {
      service.defaults.headers['Authorization'] =
        'jwt ' + window.localStorage.getItem('Token');
      let response = yield call(service.get, url, {
        headers: {
          Authorization: `jwt ${localStorage.getItem('Token')}`,
          org: localStorage.getItem('company'),
        },
      });
      console.log('response account details');
      console.log(response);
      yield put(updateAccountDetails(response));
    } catch (err) {}
  });
}

export default function* rootSaga() {
  yield all([
    fork(getAccountsList),
    fork(addAccount),
    fork(deleteAccount),
    fork(getAccountDetails),
  ]);
}
