import React from 'react';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { Row, Col, Form, Input } from 'antd';
import JoditEditor from 'jodit-react';
import { useDispatch } from 'react-redux';
import {
  deleteTemplateAction,
  getTemplatesServerAction,
  updateCampaignTemplateAction,
} from '../../redux/actions/EmailTemplates';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';

function TemplatesEdit() {
  const dispatch = useDispatch();
  const history = useHistory();
  let { id } = useParams();
  const [form] = Form.useForm();
  const campaigns = useSelector((app) => app.campaigns);
  const templates = campaigns.templates;

  const currentTemplateIndex = templates.findIndex((t) => {
    return t.id === parseInt(id);
  });

  useEffect(() => {
    if (!currentTemplateIndex || currentTemplateIndex === -1) {
      dispatch(getTemplatesServerAction());
    }
  }, [id]);

  const currentTemplate = templates[currentTemplateIndex];

  form.setFieldsValue(currentTemplate);

  const updateTemplate = (f) => {
    const data = { ...currentTemplate, ...f };
    dispatch(updateCampaignTemplateAction(data, history));
  };

  const deleteTemplate = () => {
    if (window.confirm('Are you sure you want to delete the template? ')) {
      dispatch(deleteTemplateAction(currentTemplate, history));
    }
  };
  return (
    <div className="app-area">
      <Form
        onFinish={updateTemplate}
        form={form}
        initialValues={currentTemplate}
      >
        <StyledToolbar module="templates" onDelete={deleteTemplate}  mode="edit" id={id}/>
        <div style={{ marginTop: '30px', padding: 10 }}>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Template Title"
                name="title"
                className="template-title"
                rules={[{ required: true }]}
              >
                <Input className="req" />
              </Form.Item>
              <div style={{ width: 900 }}>
                <Form.Item
                  label="Template Content"
                  name="content"
                  className="template-title"
                >
                  <JoditEditor className="joeditor" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
}

export default TemplatesEdit;
