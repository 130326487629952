import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Modal } from 'antd';
import { postDataWithHeader } from '../../redux/actions/Common';
import { useHistory } from 'react-router-dom';

import {
  Form,
  Collapse,
  Row,
  Col,
  Input,
  Select,
  Breadcrumb,
  Button,
  DatePicker,
} from 'antd';
import JoditEditor from 'jodit-react';
import {
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { addCampaign } from '../../redux/actions/Campaigns';
import { layout } from '../common/layout';
import '../leads/leads.css';
import { getProfiles } from '../../redux/actions/Profiles';
import { getTemplatesServerAction } from '../../redux/actions/EmailTemplates';
import TemplateGrid from '../templates/TemplateGrid';
import { useSelector } from 'react-redux';
import ProfileAutoSearch from '../users/UsersAutoSearch';
import AutoSearch from '../../components/ui-components/autosearch/AutoSearch';  
import { id } from 'date-fns/locale';

const { Panel } = Collapse;
const { Option } = Select;

export const CreateEmailCampaign = (props) => {
  useEffect(() => {
    props.getProfiles();
    props.getTemplatesServerAction();
  }, []);
  
  const history = useHistory();
  const { responseMessage, errors, profiles } = props;
  const [emailContent, setEmailContent] = useState('');
  const lead_recipients = useRef([]);
  const [contactsList, setContactsList] = useState([]);
  const [templateId, setTemplateId] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const campaigns = useSelector((app) => app.campaigns);
  const templates = campaigns.templates;
  const currentTemplate = templates.find((t) => t.id === templateId);

  const [form] = Form.useForm();
  const dateFormat = 'YYYY-MM-DD HH:mm';

  const onUserSelected = (userObj) => {
    setContactsList([...contactsList, userObj.id]);
  };

  const onUserDeSelected = (userObj) => {
    const index = contactsList.indexOf(userObj.id);
    if (index !== -1) {
      setContactsList([
        ...contactsList.slice(0, index),
        ...contactsList.slice(index + 1),
      ]);
    }
  };

  const onChangeEditor = (e) => {
    form.setFieldsValue({
      email_content: e,
    });
  };
  
  const sendMail = () => {
    const emailData ={
      recipients: [profiles.user_details.email],
      email_subject: form.getFieldValue('email_subject'),
      email_content: form.getFieldValue('email_content')
    }
    
    props.postDataWithHeader(`api/compose`, emailData);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSelectCard = (template) => {
    setTemplateId(template.id);
    form.setFieldsValue({
      email_content: template.content,
    });
    setIsModalOpen(false);
  };

  const addCampaign = (newCampaignDetails) => {
    newCampaignDetails.leads_source = lead_recipients.current || []
    props.addCampaign(newCampaignDetails);
  };

  useEffect(() => {
    form.setFieldsValue({
      contacts_source: contactsList,
    });
  }, [contactsList]);


  useEffect(() => {
    form.setFieldsValue({
      leads_source: lead_recipients.current,
    });
  }, [lead_recipients.current]);

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/campaigns');
    }
  }, [responseMessage]);

  return (
    <div className="add-lead">
      <Row className="main-toolbar">
        <Breadcrumb className="main-toolbar-breadcrumb">
          <Breadcrumb.Item>
            <Link to="/home">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/campaigns">Campaigns</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/home/campaigns/new">Add New Campaigns</Link>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row className="leads-toolbar-buttons">
          <Button type="primary" className="btn fw-12 fw-6">
            <LeftOutlined />
            <span onClick={() => props.history.push('/home/campaigns')}>
              Back To Campaigns
            </span>
          </Button>
          <Button
            type="primary"
            className="btn text-white bg-darkblue fw-12 fw-6"
          >
            <CloseCircleOutlined />
            <span onClick={() => props.history.push('/home/campaigns')}>
              Cancel
            </span>
          </Button>
          <Button type="primary" className="btn text-white fw-12 fw-6">
            <CheckOutlined />
            <span onClick={() => props.history.push('/home/campaigns')}>
              Save
            </span>
          </Button>
        </Row>
      </Row>
      <div className="add-form">
        <Form
          form={form}
          {...layout}
          onFinish={addCampaign}
          style={{ background: '#fff', padding: '20px' }}
        >
          <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
            <Panel header="Campaign Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Title" name="name">
                    <Input placeholder="Untitled Email Campaign" />
                  </Form.Item>
                  <Form.Item label="Recipients Contacts" name="contacts_source">
                    <ProfileAutoSearch
                      onUserSelected={onUserSelected}
                      onUserDeSelected={onUserDeSelected}
                    />
                  </Form.Item>
                  <Form.Item label="Recipients Leads" name="leads_source">
                  <AutoSearch
                    placeholder="Search Leads"
                    apiUrl="/api/leads/search"
                    apiParam="flname"
                    keyId="id"
                    labelIds={['first_name', 'last_name']}
                    onValueChange={(val) => {
                      lead_recipients.current = val;
                    }}
                  />
                  </Form.Item>
                  <Form.Item
                    label="Schedule Later"
                    name="scheduled_later"
                    className="ant-dropdown-bg"
                  >
                    <Select>
                      <Option key="true">True</Option>
                      <Option key="false">False</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Scheduled Date Time"
                    name="scheduled_date_time"
                  >
                    <DatePicker
                      style={{ width: '100%' }}
                      format={dateFormat}
                      showTime
                    />
                  </Form.Item>
                  <Form.Item label="From Email" name="from_email">
                    <Input placeholder="From Email" />
                  </Form.Item>
                  <Form.Item label="Timezone" name="timezone">
                    <Input placeholder="UTC" />
                  </Form.Item>
                  <Form.Item label="Email Template" name="template">
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <Input
                        placeholder=""
                        disabled
                        value={currentTemplate ? currentTemplate.title : ''}
                      />
                      <Button
                        className="ml-2 align-items-center"
                        onClick={() => {
                          showModal();
                        }}
                      >
                        Select Template
                      </Button>
                    </div>
                  </Form.Item>
                  <Form.Item label="Email Subject" name="email_subject">
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Collapse
            defaultActiveKey={['1']}
            expandIconPosition="end"
            className="mt-4"
          >
            <Panel header="Email Content" key="1" style={{ width: '100%' }}>
              <Form.Item name="email_content">
                <div style={{ width: '100%' }}>
                  <JoditEditor
                    width={1000}
                    style={{ width: '100%' }}
                    className="joeditor"
                    onChange={onChangeEditor}
                    value={currentTemplate ? currentTemplate.content : ''}
                  />
                </div>
              </Form.Item>
            </Panel>
          </Collapse>
          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
            >
              <CloseCircleOutlined />
              <span onClick={() => props.history.push('/home/campaigns')}>
                Cancel
              </span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              onClick={sendMail}
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Test</span>
            </Button>
          </Row>
        </Form>
      </div>
      <Modal
        title="Select Email Template"
        open={isModalOpen}
        // onOk={handleOk}

        onCancel={handleCancel}
        width={1000}
        height={500}
      >
        <div style={{ height: '100%', overflow: 'scroll' }}>
          <TemplateGrid data={templates} onClickCard={onSelectCard} />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { responseMessage, errors } = state.campaigns;
  const { profiles } = state.profiles;
  return { responseMessage, errors, profiles };
};

const mapDispatchToProps = {
  addCampaign,
  getProfiles,
  getTemplatesServerAction,
  postDataWithHeader,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateEmailCampaign);
