import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import {
  Row,
  Col,
  DatePicker,
  Button,
  Form,
  Input,
  Select,
  Alert,
  Radio,
} from 'antd';
import { CloseCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { addContact, contactErrors } from '../../redux/actions/Contacts';
import StyledToolbar from '../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import Address from '../../components/ui-components/Address';
import Description from '../../components/ui-components/Description';
import { getLeads } from '../../redux/actions/Leads';
import { motion } from 'framer-motion';

const { Panel } = Collapse;
const { Option } = Select;

export const AddContact = (props) => {
  const [errors, setErrors] = useState(props.errors !== '' ? props.errors : '');
  const [responseMessage, setResponseMessage] = useState(
    props.responseMessage !== '' ? props.responseMessage : ''
  );

  useEffect(() => {
    if (responseMessage != '') {
      props.history.push('/home/contacts');
    }
  }, [responseMessage]);

  useEffect(() => {
    let errorsInContact = {};
    let errorsInForm = '';
    if (props.errors != '') {
      errorsInContact = props.errors['error']['contact_errors'];
      if (errorsInContact != null || errorsInContact != undefined) {
        let contactErrors = Object.values(errorsInContact);
        contactErrors.forEach((element) => {
          errorsInForm += element[0] + ', ';
        });
      }
      setErrors(errorsInForm);
    }
    if (props.responseMessage != '' || props.responseMessage != undefined) {
      setResponseMessage(props.responseMessage);
    }
  });

  const rules = {
    salutation: [{ required: true, message: 'Salutation is required' }],
    firstName: [{ required: true, message: 'First Name is required' }],
    lastName: [{ required: true, message: 'Last Name is required' }],
    title: [{ required: true, message: 'Title is required' }],
    addressLane: [{ required: true, message: 'Address lane is required' }],
    primaryEmail: [{ required: true, message: 'Primary Email is required' }],
    organization: [{ required: true, message: 'Organization is required' }],
    hasSubscribed: [
      { required: true, message: 'Marketing status is required' },
    ],
    dob: [{ required: true, message: 'Date Of Birth is required' }],
  };

  const addContact = (e) => {
    props.contactErrors('');
    const data = {
      salutation: e.salutation,
      first_name: e.first_name,
      last_name: e.last_name,
      date_of_birth: e.dob,
      organization: e.organization,
      title: e.title,
      primary_email: e.primaryemail,
      secondary_email: e.secondaryemail,
      mobile_number: e.mobilenumber,
      secondary_number: e.secondarynumber,
      language: e.language,
      department: e.department,
      do_not_call: true,
      contacts_country: e.contacts_country,
      has_subscribed: e.has_subscribed,
      address_line: e.address_line,
      street: e.street,
      city: e.city,
      state: e.state,
      postcode: e.postcode,
      description: e.description,
      linked_in_url: e.linkedinurl,
      facebook_url: e.facebookurl,
      twitter_username: e.twitterhandle,
    };
    props.addContact('/api/contacts/', data);
  };

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: 1,
          marginBottom: 20,
        }}
      >
        {errors != '' ? (
          <Alert type="error" closable message={errors}></Alert>
        ) : (
          ''
        )}
        {responseMessage != '' && responseMessage != undefined ? (
          <Alert type="success" closable message={responseMessage}></Alert>
        ) : (
          ''
        )}
      </motion.div>
      <div className="app-area">
        <div className="basic-information">
          <Form onFinish={addContact}>
            <StyledToolbar module="contacts" mode="add" />
            <Collapse defaultActiveKey={['1']} expandIconPosition="end">
              <Panel header="Basic Information" key="1">
                <Row>
                  <Col span={12}>
                    {/* <Form.Item
                      label="Salutation"
                      name="salutation"
                      className="salutation"
                      rules={rules.salutation}
                    >
                      <Input className="req" />
                    </Form.Item> */}
                    <Form.Item
                      label="Salutation"
                      name="salutation"
                      rules={rules.salutation}
                      className="req"
                    >
                      <Select>
                        <Option key="Mr">Mr</Option>
                        <Option key="Mrs">Mrs</Option>
                        <Option key="Ms">Ms</Option>
                        <Option key="Miss">Miss</Option>
                        <Option key="Dr">Dr</Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="First Name"
                      name="first_name"
                      className="firstname"
                      rules={rules.firstName}
                    >
                      <Input className="req" />
                    </Form.Item>
                    <Form.Item
                      label="Organization"
                      name="organization"
                      className="organization"
                      rules={rules.organization}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Primary Email"
                      name="primaryemail"
                      className="primaryemail"
                      errors={rules.primaryEmail}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Mobile Number"
                      name="mobilenumber"
                      className="mobilenumber"
                    >
                      <Input className="" />
                    </Form.Item>
                    <Form.Item
                      label="Department"
                      name="department"
                      className="department"
                    >
                      <Select>
                        <Option value="executive">Executive</Option>
                        <Option value="sales/marketing">Sales/Marketing</Option>
                        <Option value="finance">Finance</Option>
                        <Option value="general_management">
                          General Management
                        </Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Marketing status:"
                      name="has_subscribed"
                      errors={rules.hasSubscribed}
                    >
                      <Radio.Group>
                        <Radio value="true"> Subscribe </Radio>
                        <Radio value="false"> Unsubscribe </Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      label="Country"
                      name="contacts_country"
                      className="country"
                    >
                      <Select>
                        <Option key="US">United States</Option>
                        <Option key="CA">Canada</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Title"
                      name="title"
                      className="title"
                      rules={rules.title}
                    >
                      <Input className="req" />
                    </Form.Item>
                    <Form.Item
                      label="Last Name"
                      name="last_name"
                      className="lastname"
                      rules={rules.lastName}
                    >
                      <Input className="req" />
                    </Form.Item>
                    <Form.Item
                      label="Date of Birth"
                      name="dob"
                      className="dob"
                      rules={rules.dob}
                    >
                      <DatePicker />
                    </Form.Item>
                    <Form.Item
                      label="Secondary Email"
                      name="secondaryemail"
                      className="secondaryemail"
                    >
                      <Input className="" />
                    </Form.Item>
                    <Form.Item
                      label="Secondary Number"
                      name="secondarynumber"
                      className="secondarynumber"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Language"
                      name="language"
                      className="language"
                    >
                      <Input className="" />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>

            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="end"
              className="mt-4"
            >
              <Panel header="Address Details" key="1">
                <Address errors={rules.addressLane} hideCountry={true} />
              </Panel>
            </Collapse>

            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="end"
              className="mt-4"
            >
              <Panel header="Description" key="2">
                <Description />
              </Panel>
            </Collapse>

            <Collapse
              defaultActiveKey={['1']}
              expandIconPosition="end"
              className="mt-4"
            >
              <Panel header="Socials" key="1">
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Linkedin URL"
                      name="linkedinurl"
                      className="linkedinurl"
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Twitter Handle"
                      name="twitterhandle"
                      className="twitterhandle"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Facebook URL"
                      name="facebookurl"
                      className="facebookurl"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Panel>
            </Collapse>

            <div className="action-btns mt-4 mb-4 d-flex justify-content-center">
              <Button className="btn-cancel mr-2">
                <CloseCircleOutlined />
                Cancel
              </Button>

              <Button className="btn-save" htmlType="submit">
                <CheckOutlined />
                Save
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { errors, responseMessage } = state.contacts;
  return { errors, responseMessage };
};

const mapDispatchToProps = {
  addContact,
  getLeads,
  contactErrors,
};
export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
