export const rules = {
  required:[
    {
      required:true,
      message: 'The field is mandatory',
    }
  ],
  dateOptional: [
    {
      type: 'object',
      message: 'Please enter a valid date',
    },
  ],
  email: [
    {
      required: true,
      message: 'Please input your email address',
    },
    {
      type: 'email',
      message: 'Please enter a valid email!',
    },
  ],
  emailOptional: [
    {
      type: 'email',
      message: 'Please enter a valid email!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please input your password',
    },
  ],
  name: [
    {
      required: true,
      message: 'Please input name',
    },
  ],
  company: [
    {
      required: true,
      message: 'Please input company',
    },
  ],
  amount: [
    {
      required: true,
      message: 'Please input amount',
    },
  ],
  addressLane: [
    {
      required: true,
      message: 'Please input address lane',
    },
  ],
  saluation: [
    {
      required: true,
      message: 'Please input saluation',
    },
  ],
  lastName: [
    {
      required: true,
      message: 'Please input last name',
    },
  ],
  firstName: [
    {
      required: true,
      message: 'Please input first name',
    },
  ],
  mobileNumber: [
    {
      required: true,
      message: 'Please input mobile number',
    },
  ],
  contactNumberOptional: [
    {
      pattern: new RegExp(/^\d{10}$/),
      message: 'Please input contact number',
    },
  ],
  numberOptional: [
    {
      pattern: new RegExp(/^\d+$/),
      message: 'Please input number',
    },
  ],
  amountOptional: [
    {
      pattern: new RegExp(/^\d+(\.\d)?\d?$/),
      message: 'Please input amount with maximum 2 digits after decimal',
    },
  ],
  decimalOptional: [
    {
      pattern: new RegExp(/^\d?\d(\.\d)?\d?$/),
      message:
        'Please input number with maximum of 2 digits before and 2 digits after decimal',
    },
  ],
  title: [
    {
      required: true,
      message: 'Please input title',
    },
  ],
};
