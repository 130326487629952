import { useState, useEffect, Component } from 'react';
import { Modal, Form, Input, Button, Checkbox } from 'antd';
import LeadsDataSection from './LeadsDataSection';
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons';
import { addLead } from '../../redux/actions/Leads';
import { rules } from '../common/rules';

import {
  PlusOutlined,
  VerticalAlignTopOutlined,
  LeftOutlined,
  CheckOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

export const LeadDetails = ({
  edit,
  leadDetails,
  visible,
  onCancel,
  onCreate,
}) => {
  //   const [edit, setEdit] = useState(true);

  //   const { leadDetails} = props;

  const [details, setDetails] = useState('');
  const [opportunity_amount, setAmount] = useState('');
  const [website, setWebsite] = useState('');
  const [assigned_To, setAssignedTo] = useState('');
  const [status, setStatus] = useState('');
  const [source, setSource] = useState('');
  const [leadLabel, setLeadLabel] = useState('');
  const [tags, setTags] = useState('');
  const [contactname, setContactName] = useState('');
  const [organization, setOrganization] = useState('');
  const [skype_ID, setSkypeID] = useState('');
  const [email, setEmail] = useState('');
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [title, setLeadName] = useState('');
  const [componentDisabled, setComponentDisabled] = useState(true);

  const [industry, setIndustry] = useState('');
  const [attachment, setAttachment] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [street, setStreet] = useState('');
  const [phone, setPhone] = useState('');
  const [account_name, setAccountName] = useState('');
  const [address_line, setAddressLine] = useState('');
  const [description, setDescription] = useState('');
  const [enquiry_type, setEnquiryType] = useState('');
  const [postcode, setPostcode] = useState('');
  // const [probability, setProbability] = useState('');

  // const editdetails = (e) => {
  //   console.log(e);
  //   props.addLead(e);
  // };

  const onFinish = () => {
    leadDetails.first_name = first_name;
    leadDetails.last_name = last_name;
    leadDetails.title = title;
    leadDetails.website = website;
    leadDetails.status = status;
    leadDetails.source = source;
    leadDetails.lead_label = leadLabel;
    leadDetails.contactname = contactname;
    leadDetails.organization = organization;
    leadDetails.assigned_To = assigned_To;
    leadDetails.skype_ID = skype_ID;
    leadDetails.opportunity_amount = opportunity_amount;
    leadDetails.email = email;

    leadDetails.industry = industry;
    leadDetails.attachment = attachment;
    leadDetails.city = city;
    leadDetails.state = state;
    leadDetails.street = street;
    leadDetails.phone = phone;
    leadDetails.account_name = account_name;
    leadDetails.address_line = address_line;
    leadDetails.description = description;
    leadDetails.enquiry_type = enquiry_type;
    leadDetails.postcode = postcode;
    // leadDetails.probability = probability;

    addLead(leadDetails);
    console.log(leadDetails);
  };

  // useEffect(() => {
  //   if (responseMessage === true) {
  //     props.history.push('/home/leads');
  //   }
  // }, [responseMessage]);

  useEffect(() => {
    setLeadName(leadDetails.title);
    setFirstName(leadDetails.first_name);
    setLastName(leadDetails.last_name);
    // setProbability(leadDetails.probability);
    setWebsite(leadDetails.website);

    setAssignedTo(`${leadDetails?.created_by?.role}`);
    setStatus(leadDetails.status);
    setSource(leadDetails.source);
    setLeadLabel(leadDetails.lead_label);
    setTags(leadDetails.tags);
    setContactName(
      `${leadDetails?.contacts?.[0]?.first_name}  ${leadDetails?.contacts?.[0]?.last_name}`
    );
    setOrganization(leadDetails.organization);
    setSkypeID(leadDetails.skype_ID);
    setAmount(leadDetails.opportunity_amount);
    setEmail(leadDetails.email);

    setIndustry(leadDetails.industry);
    setCity(leadDetails.city);
    setAttachment(leadDetails.attachment);
    setState(leadDetails.state);
    setStreet(leadDetails.street);
    setPhone(leadDetails.phone);
    setAccountName(leadDetails.account_name);
    setAddressLine(leadDetails.address_line);
    setEnquiryType(leadDetails.enquiry_type);
    setPostcode(leadDetails.postcode);
    setAttachment(leadDetails.attachment);
  }, [leadDetails]);

  useEffect(() => {
    setDetails(leadDetails);
  });

  return (
    <Modal
      visible={visible}
      title="Lead Details"
      // okText="Submit"
      onCancel={onCancel}
      // onOk={onCancel}
      footer={null}
    >
      <Checkbox
        checked={componentDisabled}
        onChange={(e) => setComponentDisabled(e.target.checked)}
      >
        Edit Disabled
      </Checkbox>

      <Form
        layout="vertical"
        disabled={componentDisabled}
        onFinish={onFinish}
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <Form.Item label="Lead Title:" style={{ width: '49%' }}>
          <Input
            placeholder="Lead Title"
            value={title}
            onChange={(e) => setLeadName(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        {/* Current Place holder for Assigned field */}

        <Form.Item label="Assigned to:" style={{ width: '49%' }}>
          <Input
            placeholder="Amount"
            value={assigned_To}
            onChange={(e) => setAssignedTo(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="First Name:" style={{ width: '49%' }}>
          <Input
            placeholder="First Name"
            value={first_name}
            onChange={(e) => setFirstName(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Status:" style={{ width: '49%' }}>
          <Input
            placeholder="Status"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Last Name:" style={{ width: '49%' }}>
          <Input
            placeholder="Last Name"
            value={last_name}
            onChange={(e) => {
              console.log(e.target.value);
              setLastName(e.target.value);
            }}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Source:" style={{ width: '49%' }}>
          <Input
            placeholder="Source"
            value={source}
            onChange={(e) => setSource(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="E-Mail ID:" style={{ width: '49%' }}>
          <Input
            placeholder="E-Mail ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Contact Name:" style={{ width: '49%' }}>
          <Input
            placeholder="Contact Name"
            value={contactname}
            onChange={(e) => setContactName(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        {/* <Form.Item label="Skype ID:" style={{ width: '49%' }}>
          <Input
            placeholder="Skype ID"
            value={skype_ID}
            onChange={(e) => setSkypeID(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item> */}

        <Form.Item label="Amount:" style={{ width: '49%' }}>
          <Input
            placeholder="Amount"
            value={opportunity_amount}
            onChange={(e) => setAmount(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Organization:" style={{ width: '49%' }}>
          <Input
            placeholder="Organization"
            value={organization}
            onChange={(e) => setOrganization(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Label:" style={{ width: '49%' }}>
          <Input
            placeholder="Label"
            value={leadLabel}
            onChange={(e) => setLeadLabel(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        <Form.Item label="Website:" style={{ width: '49%' }}>
          <Input
            placeholder="Website"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
            // style={{ marginBottom: 10 }}
            // disabled={edit ? 'disabled' : ''}
            // suffix={
            //   <EditOutlined
            //     style={{
            //       color: 'rgba(0,0,0,.45)',
            //     }}
            //   />
            // }
          />
        </Form.Item>

        {/* <Form.Item label="Probability:" style={{ width: '49%' }}>
          <Input
            placeholder="Probability"
            value={probability}
            onChange={(e) => setProbability(e.target.value)}
    
          />
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {/* <span onClick={() => props.history.push('/home/leads')}> */}
            Save
            {/* </span> */}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
