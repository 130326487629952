import React, { useState } from 'react';
import 'antd/dist/antd.css';
import { AutoComplete, Input } from 'antd';
import { service } from '../../service';

const transformContacts = (contacts) => {
  return contacts.map((contact) => {
    return {
      value: `${contact.first_name} ${contact.last_name}`,
      key: contact.id,
      obj: contact,
    };
  });
};

const ContactsAutoSearch = ({ onContactSelected, preFilledValue = '' }) => {
  const [value, setValue] = useState(preFilledValue);
  const [options, setOptions] = useState([]);

  const debounce = (func, delay) => {
    let debounceTimer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  const getContacts = async (searchText) => {
    const response = await service.get(`/api/contacts/?name=${searchText}`, {
      headers: {
        Authorization: `jwt ${localStorage.getItem('Token')}`,
        org: localStorage.getItem('company'),
      },
    });

    if (response.status == 200) {
      setOptions(transformContacts(response.data.contact_obj_list));
    }
    return response;
  };

  const onSearch = (searchText) => {
    getContacts(searchText);
  };

  const onSelect = (val, option) => {
    // console.log('onSelect', option);
    if (onContactSelected) {
      onContactSelected(option.obj);
    }
  };

  const onChange = (data) => {
    // console.log('On change called', data);
    setValue(data);
  };

  return (
    <>
      <AutoComplete
        value={value}
        options={options}
        // style={{ width: 200 }}
        onSelect={onSelect}
        onSearch={debounce(onSearch, 100)}
        onChange={onChange}
        // placeholder="control mode"
      >
        <Input.Search size="large" placeholder="Search Contacts" />
      </AutoComplete>
    </>
  );
};

export default ContactsAutoSearch;
