import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Input, Table, Tabs } from 'antd';
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RowCounter from '../../components/ui-components/pagination/rowcounter/RowCounter';
import {
  deleteAccount,
  getAccounts,
  loading,
  responseMessage,
} from '../../redux/actions/Accounts';
import '../leads/leads.css';

const { TabPane } = Tabs;

export const AccountsList = (props) => {
  const { accountsData, refresh } = props;
  console.log('props accounts list');
  console.log(accountsData);

  const [rows, setRows] = useState(10);

  const [activeAccounts, setActiveAccounts] = useState();
  const [closedAccounts, setClosedAccounts] = useState();

  const [activeTab, setActiveTab] = useState(true);
  const [activePages, setActivePages] = useState(0);
  const [closedPages, setClosedPages] = useState(0);

  useEffect(() => {
    props.getAccounts(`/api/accounts/?limit=${rows}`, 0);
    props.loading();
  }, [rows]);

  useEffect(() => {
    if (accountsData && accountsData.active_accounts != undefined) {
      setActiveAccounts(accountsData.active_accounts.open_accounts);
    }
    if (accountsData && accountsData.closed_accounts != undefined) {
      setClosedAccounts(accountsData.closed_accounts.close_accounts);
    }
    console.log(activeAccounts);
    console.log(closedAccounts);
  }, [accountsData]);

  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const routeToDetails = (id) => {
    console.log('id', id);
    props.history.push(`/home/accounts/${id}/details`);
  };

  useEffect(() => {
    console.log(accountsData);
    setActivePages(accountsData && accountsData.active_accounts.offset);
    setClosedPages(accountsData && accountsData.closed_accounts.offset);
  });

  // const accountDelete = (id) => {
  //   props.deleteAccount(id, !refresh);
  // };

  const columns1 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, row) => {
        return (
          <p onClick={() => routeToDetails(row.id)}>
            <span className="">{name}</span>
          </p>
        );
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <p>{email}</p>,
    },
    {
      title: 'Contact',
      dataIndex: 'contacts',
      key: 'contacts',
      render: (contacts) => {
        return (
          <p>
            {contacts[0] != undefined
              ? contacts[0]['first_name'] + ' ' + contacts[0]['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Assigned',
      dataIndex: 'assigned_to',
      key: 'assigned_to',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Assigned To"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              type="danger"
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.assigned_to[0].user_details.first_name
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (assigned_to) => {
        return (
          <p>
            {assigned_to[0] != undefined
              ? assigned_to[0]['user_details']['first_name'] +
                ' ' +
                assigned_to[0]['user_details']['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: () => (
        <>
          <span className="edit">
            <EditOutlined />
          </span>
          <span className="delete">
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const columns2 = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'cname',
      render: (name, row) => {
        return (
          <p onClick={() => routeToDetails(row.id)}>
            <span className="">{name}</span>
          </p>
        );
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'cemail',
      render: (email) => <p>{email}</p>,
    },
    {
      title: 'Contact',
      dataIndex: 'contacts',
      key: 'ccontacts',
      render: (contacts) => {
        return (
          <p>
            {contacts[0] != undefined
              ? contacts[0]['first_name'] + ' ' + contacts[0]['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Assigned',
      dataIndex: 'assigned_to',
      key: 'cassigned_to',
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => {
        return (
          <>
            <Input
              autoFocus
              placeholder="Search Assigned To"
              value={selectedKeys[0]}
              onChange={(e) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
              }}
              onPressEnter={() => {
                confirm();
              }}
              onBlur={() => {
                confirm();
              }}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            ></Input>
            <Button
              onClick={() => {
                confirm();
              }}
              type="primary"
              size="small"
              style={{ width: 90, marginRight: 8 }}
            >
              Search
            </Button>
            <Button
              onClick={() => {
                clearFilters();
                confirm();
              }}
              type="danger"
              size="small"
              style={{ width: 90 }}
            >
              Reset
            </Button>
          </>
        );
      },
      filterIcon: () => {
        return <SearchOutlined />;
      },
      onFilter: (value, record) =>
        record.assigned_to[0].user_details.first_name
          .toLowerCase()
          .includes(value.toLowerCase()),
      render: (assigned_to) => {
        return (
          <p>
            {assigned_to[0] != undefined
              ? assigned_to[0]['user_details']['first_name'] +
                ' ' +
                assigned_to[0]['user_details']['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Actions',
      key: 'cactions',
      render: () => (
        <>
          <span className="edit">
            <EditOutlined />
          </span>
          <span className="delete">
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const tabChange = (e) => {
    if (e == 1) {
      setActiveTab(true);
    } else if (e == 2) {
      setActiveTab(false);
    }
  };

  return (
    <>
      <div className="toolbar">
        <div className="main-toolbar">
          <div className="main-toolbar-functions-wrapper">

            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <RowCounter updateRecordsPerPage={updateRecordsPerPage} />
              <Button type="primary" className="btn text-white fw-12 fw-6">
                <PlusOutlined />
                <span onClick={() => props.history.push('/home/accounts/new')}>
                  {' '}
                  Add Account{' '}
                </span>
              </Button>
            </div>

            <div className="toolbar-pages-count">
              <LeftOutlined className="toolbar-pages-count-leftoutlined" />
              <span>
                {' '}
                1 to{' '}
                {activeTab
                  ? Math.ceil(activePages / rows)
                  : Math.ceil(closedPages / rows)}
              </span>
              <RightOutlined className="toolbar-pages-count-rightoutlined" />
            </div>
          </div>
        </div>
      </div>
      <Tabs className="custom-tabs" onChange={tabChange}>
        <TabPane className="custom-tabpane" tab="Open" key="1">
          <Table
            className="users-table users-table-active"
            columns={columns1}
            dataSource={activeAccounts}
            pagination={false}
          />
        </TabPane>
        <TabPane className="custom-tabpane" tab="Close" key="2">
          <Table
            className="users-table users-table-inactive"
            columns={columns2}
            dataSource={closedAccounts}
            pagination={false}
          />
        </TabPane>
      </Tabs>
    </>
  );
};

const mapStateToProps = (state) => {
  const { isLoading, accountsData, offset, refresh } = state.accounts;
  return { isLoading, accountsData, offset, refresh };
};

const mapDispatchToProps = {
  getAccounts,
  loading,
  deleteAccount,
  responseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountsList);
