import { all } from 'redux-saga/effects';
import Accounts from './Accounts';
import Auth from './Auth';
import Campaigns from './Campaigns';
import Common from './Common';
import Contacts from './Contacts';
import CrmDashboard from './CrmDashboard';
import Deals from './Deals';
import Documents from './Documents';
import Events from './Events';
import Fetch from './Fetch';
import Investors from './Investors';
import Leads from './Leads';
import Profiles from './Profiles';
import Tasks from './Tasks';
import Users from './Users';

export default function* rootSaga(getState) {
  yield all([
    Fetch(),
    Common(),
    Auth(),
    Leads(),
    Contacts(),
    CrmDashboard(),
    Deals(),
    Investors(),
    Users(),
    Profiles(),
    Tasks(),
    Accounts(),
    Documents(),
    Events(),
    Campaigns(),
  ]);
}
