import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, DatePicker, Form, Input, Row } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import StyledToolbar from '../../../components/ui-components/toolbar/styledToolbar/StyledToolbar';
import { addInvestor } from '../../../redux/actions/Investors';
import { layout } from '../../common/layout';
import { rules } from '../../common/rules';
const { Panel } = Collapse;

export const AddRetailInvestor = (props) => {
  const [form] = Form.useForm();

  const { responseMessage, errors } = props;
  const dateFormat = 'YYYY-MM-DD';

  const addInvestor = (values) => {
    values['last_investment_date'] = values?.last_investment_date
      ? moment(values.last_investment_date).format(dateFormat)
      : undefined;
    values['last_investment_debt_date'] = values?.last_investment_debt_date
      ? moment(values.last_investment_debt_date).format(dateFormat)
      : undefined;
    values['last_closed_fund_close_date'] = values?.last_closed_fund_close_date
      ? moment(values.last_closed_fund_close_date).format(dateFormat)
      : undefined;
    values['last_closed_fund_open_date'] = values?.last_closed_fund_open_date
      ? moment(values.last_closed_fund_open_date).format(dateFormat)
      : undefined;

    values['investor_type'] = 'RETAIL';
    props.addInvestor(values);
  };

  useEffect(() => {
    if (responseMessage === true) {
      props.history.push('/home/investors');
    }
  }, [responseMessage]);

  return (
    <div className="app-area">
      <StyledToolbar
        module="investors"
        onSubmit={() => form.submit()}
        mode="add"
      />
      <Form
        form={form}
        {...layout}
        onFinish={addInvestor}
        style={{ background: '#fff', padding: '20px' }}
      >
        <div>
          <Collapse expandIconPosition="end" defaultActiveKey={[1]}>
            <Panel header="Primary Details" key="1">
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Investor ID"
                    name="pbid"
                    rules={rules.required}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investor Name"
                    name="investor"
                    rules={rules.name}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Notes" name="note">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Author Notes" name="author_note">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investor Former Name"
                    name="investor_former_name"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Investor Alias" name="investor_alias">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investor Legal Name"
                    name="investor_legal_name"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Exchange" name="exchange">
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="Description" name="description">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Investor Type"
                    name="primary_investor_type"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Other Investor Types"
                    name="other_investor_types"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="AUM" name="aum" rules={rules.numberOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Dry Powder" name="dry_powder" rules={rules.numberOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Professionals Count"
                    name="count_of_investment_professionals"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Year Founded"
                    name="year_founded"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Investor Type" name="investor_type" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header="HQ Details" key="2" style={{ marginTop: '10px' }}>
              <Row>
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="HQ Location" name="hq_location">
                    <Input />
                  </Form.Item>
                  <Form.Item label="HQ Address Line 1" name="hq_address_line_1">
                    <Input />
                  </Form.Item>
                  <Form.Item label="HQ Address Line 2" name="hq_address_line_2">
                    <Input />
                  </Form.Item>
                  <Form.Item label="HQ City" name="hq_city">
                    <Input />
                  </Form.Item>
                  <Form.Item label="HQ State/Province" name="hq_state">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="HQ Post Code"
                    name="hq_post_code"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="HQ Country/Territory"
                    name="hq_country"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="HQ Phone"
                    name="hq_phone"
                    rules={rules.contactNumberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="HQ Fax"
                    name="hq_fax"
                    rules={rules.contactNumberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="HQ Email"
                    name="hq_email"
                    rules={rules.emailOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="HQ Global Region" name="hq_global_region">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="HQ Global Sub Region"
                    name="hq_global_sub_region"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Primary Contact Details"
              key="3"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Primary Contact PBId"
                    name="primary_contact_pbid"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Contact"
                    name="primary_contact"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Contact Title"
                    name="primary_contact_title"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item
                    label="Primary Contact Email"
                    name="primary_contact_email"
                    rules={rules.emailOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Contact Phone"
                    name="primary_contact_phone"
                    rules={rules.contactNumberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Website" name="website">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Investment Details"
              key="4"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Investor Status" name="investor_status">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Parent Company" name="parent_company">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Daily Updates" name="daily_updates">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Weekly Updates" name="weekly_updates">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investments"
                    name="investments"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Exits"
                    name="exits"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Active Portfolio"
                    name="active_portfolio"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Total Investments"
                    name="total_investments"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Total Exits"
                    name="total_exits"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Total Active Portfolio"
                    name="total_active_portfolio"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item
                    label="Last 7 days Invstmts"
                    name="investments_in_the_last_7_days"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 7 days Total Invstmts"
                    name="total_investments_in_the_last_7_days"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 6 month Invstmts"
                    name="investments_in_the_last_6_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 6 month Total Invstmts"
                    name="total_investments_in_the_last_6_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 1 year Invstmts"
                    name="investments_in_the_last_12_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 1 year Total Invstmts"
                    name="total_investments_in_the_last_12_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 2 years Invstmts"
                    name="investments_in_the_last_2_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 2 years Total Invstmts"
                    name="total_investments_in_the_last_2_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 5 years Invstmts"
                    name="investments_in_the_last_5_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 5 years Total Invstmts"
                    name="total_investments_in_the_last_5_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel header="Fund Details" key="5" style={{ marginTop: '10px' }}>
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Most Likely Fundraising"
                    name="most_likely_fundraising"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Open Funds Count" name="count_funds_open" rules={rules.numberOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Funds Count"
                    name="count_funds_closed"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 6 month Funds Count"
                    name="count_funds_closed_in_the_last_6_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 1 year Funds Count"
                    name="count_funds_closed_in_the_last_12_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 2 years Funds Count"
                    name="count_funds_closed_in_the_last_2_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 5 years Funds Count"
                    name="count_funds_closed_in_the_last_5_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Min Fund Size" name="min_fund_size" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="Median Fund Size" name="median_fund_size" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Total Funds Open"
                    name="total_funds_open"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Total Funds"
                    name="total_funds_closed"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 6 month Total Funds"
                    name="total_funds_closed_in_the_last_6_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 1 year Total Funds"
                    name="total_funds_closed_in_the_last_12_months"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 2 years Total Funds"
                    name="total_funds_closed_in_the_last_2_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Last 5 years Total Funds"
                    name="total_funds_closed_in_the_last_5_years"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Max Fund Size" name="max_fund_size" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Preference Details"
              key="6"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Industry" name="preferred_industry">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Verticals" name="preferred_verticals">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Geography" name="preferred_geography">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Types"
                    name="preferred_investment_types"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Real Asset" name="real_asset_preferences">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Impact Category"
                    name="impact_category_preferences"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Other Stated"
                    name="other_stated_preferences"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Amount"
                    name="preferred_investment_amount" rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Amount Min"
                    name="preferred_investment_amount_min" rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Amount Max"
                    name="preferred_investment_amount_max"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Deal Size" name="preferred_deal_size" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Deal Size Min"
                    name="preferred_deal_size_min" rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Deal Size Max"
                    name="preferred_deal_size_max" rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="EBITDA Min" name="preferred_ebitda_min" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBITDA Max" name="preferred_ebitda_max" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBIT" name="preferred_ebit" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBIT Min" name="preferred_ebit_min" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBIT Max" name="preferred_ebit_max" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Revenue" name="preferred_revenue" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Revenue Min" name="preferred_revenue_min" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Revenue Max" name="preferred_revenue_max" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Horizon"
                    name="preferred_investment_horizon"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Company Valuation"
                    name="preferred_company_valuation"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Company Valuation Min"
                    name="preferred_company_valuation_min"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Company Valuation Max"
                    name="preferred_company_valuation_max"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBITDA" name="preferred_ebitda" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Investment Details"
              key="7"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item
                    label="Number of Funds"
                    name="number_of_funds"
                    rules={rules.numberOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Company"
                    name="last_investment_company"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Date"
                    name="last_investment_date"
                    rules={rules.dateOptional}
                  >
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>
                  <Form.Item
                    label="Investment Size"
                    name="last_investment_size"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Size Status"
                    name="last_investment_size_status"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Valuation"
                    name="last_investment_valuation"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Valuation Status"
                    name="last_investment_valuation_status"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Type"
                    name="last_investment_type"
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item
                    label="Investment Type 2"
                    name="last_investment_type_2"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Type 3"
                    name="last_investment_type_3"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Class"
                    name="last_investment_class"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Debt"
                    name="last_investment_debt"

                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Debt Size"
                    name="last_investment_debt_size"
                    rules={rules.amountOptional}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Investment Debt Date"
                    name="last_investment_debt_date"
                    rules={rules.dateOptional}
                  >
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>
                  <Form.Item
                    label="Investment Status"
                    name="last_investment_status"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
            <Panel
              header="Last Closed Fund Details"
              key="8"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Fund Name" name="last_closed_fund_name">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Fund Vintage"
                    name="last_closed_fund_vintage"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="Fund Size" name="last_closed_fund_size" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="Fund Type" name="last_closed_fund_type">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Fund Close Date"
                    name="last_closed_fund_close_date"
                    rules={rules.dateOptional}
                  >
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>
                  <Form.Item
                    label="Fund Open Date"
                    name="last_closed_fund_open_date"
                    rules={rules.dateOptional}
                  >
                    <DatePicker style={{ width: '100%' }} format={dateFormat} />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>

            <Panel
              header="General Details"
              key="9"
              style={{ marginTop: '10px' }}
            >
              <Row justify="start">
                <Col xs={24} sm={24} md={24} lg={12} xl={11}>
                  <Form.Item label="Trade Association" name="trade_association">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Ownership Status" name="ownership_status">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Company Financing Status"
                    name="company_financing_status"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Industry Sector"
                    name="primary_industry_sector"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Industry Group"
                    name="primary_industry_group"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Primary Industry Code"
                    name="primary_industry_code"
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item label="All Industries" name="all_industries">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Verticals" name="verticals">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Keywords" name="keywords">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Median Quartile" name="median_quartile" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={12} xl={11} offset={0}>
                  <Form.Item label="Revenue" name="revenue">
                    <Input />
                  </Form.Item>
                  <Form.Item label="Total Assets" name="total_assets" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Net Income" name="net_income" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Enterprise Value" name="enterprise_value" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBITDA" name="ebitda" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="EBIT" name="ebit" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Market Cap" name="market_cap" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Net Debt" name="net_debt" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                  <Form.Item label="Free Cash Flow" name="free_cash_flow" rules={rules.amountOptional}>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Panel>
          </Collapse>
          <Row
            justify="center"
            style={{ marginTop: '20px', marginBottom: '50px' }}
          >
            <Button
              type="primary"
              className="btn bg-darkblue text-white fw-12 fw-6"
              onClick={() => props.history.push('/home/investors')}
            >
              <CloseCircleOutlined />
              <span>Cancel</span>
            </Button>
            <Button
              type="primary"
              className="btn text-white fw-12 fw-6"
              htmlType="submit"
            >
              <CheckOutlined />
              <span>Save</span>
            </Button>
          </Row>
        </div>
      </Form>
    </div>
  );
};
const mapStateToProps = (state) => {
  if (!state.investors) {
    return { responseMessage: undefined, errors: [] };
  }
  const { responseMessage, errors } = state.investors;
  return { responseMessage, errors };
};
const mapDispatchToProps = {
  addInvestor,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRetailInvestor);
