export const GET_TASKS = 'GET_TASKS';
export const SET_TASKS_DATA = 'SET_TASKS_DATA';
export const LOADING = 'LOADING';
export const ADD_TASK = 'ADD_TASK';
export const RESPONSE_MESSAGE = 'RESPONSE_MESSAGE';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const DELETE_TASK = 'DELETE_TASK';
export const REFRESH = 'REFRESH';
export const TASK_DETAILS = 'TASK_DETAILS';
export const UPDATE_TASK_DETAILS = 'UPDATE_TASK_DETAILS';
export const TASK_UPDATED = 'TASK_UPDATED';
export const ADD_TASK_NOTE = 'ADD_TASK_NOTE';
export const TASK_NOTES = 'TASK_NOTES';
export const UPDATE_TASK_NOTES = 'UPDATE_TASK_NOTES';
