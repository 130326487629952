import React, { useState } from 'react';
import './style.css';
import 'antd/dist/antd.css';
import { Modal } from 'antd';
import moment from 'moment';

function ContactsTasks(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskDetails, setTaskDetails] = useState('');
  const tasksList = props.tasks;
  console.log('tasksList');
  console.log(tasksList);
  const plannedTasksList =
    tasksList != undefined && tasksList.length > 0
      ? tasksList.filter(function (task) {
          return task.status != 'Completed';
        })
      : [];
  const completedTasksList =
    tasksList != undefined && tasksList.length > 0
      ? tasksList.filter(function (task) {
          return task.status == 'Completed';
        })
      : [];

  const showModal = (details) => {
    setIsModalOpen(true);
    setTaskDetails(details);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="planned">Planned</div>
      {plannedTasksList.length > 0 ? (
        <>
          <p> * Click on task title to get more details</p>
          {plannedTasksList.map((details, index) => {
            return (
              <>
                <div className="task-cont" key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="circle"
                      // onClick={() => setDone(!done)}
                    ></div>
                    <h5 onClick={() => showModal(details)}> {details.title}</h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <div className="status">
                      Status <b>{details.status}</b>
                    </div>
                    <div className="due_date">
                      Due Date : <b>{details.due_date}</b>
                    </div>
                    <div className="priority">
                      Priority : <b>{details.priority}</b>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <h5 style={{ textAlign: 'center' }}> No planned tasks </h5>
      )}

      <div className="planned">Completed</div>
      {completedTasksList.length > 0 ? (
        <>
          {completedTasksList.map((details, index) => {
            return (
              <>
                <div className="task-cont" key={index}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div
                      className="circle"
                      // onClick={() => setDone(!done)}
                    ></div>
                    <h5 onClick={() => showModal(details)}> {details.title}</h5>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: 10,
                    }}
                  >
                    <div className="status">
                      Status <b>{details.status}</b>
                    </div>
                    <div className="due_date">
                      Due Date : <b>{details.due_date}</b>
                    </div>
                    <div className="priority">
                      Priority : <b>{details.priority}</b>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </>
      ) : (
        <h5 style={{ textAlign: 'center' }}> No completed tasks </h5>
      )}

      <Modal
        title="Task Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div>
          <p>
            <b>Title: </b>
            {taskDetails.title}
          </p>
          <p>
            <b>Status: </b>
            {taskDetails.status}
          </p>
          <p>
            <b>Priority: </b>
            {taskDetails.priority}
          </p>
          <p>
            <b>Due Date: </b>
            {moment(taskDetails.due_date).format('yyyy-MM-DD HH:mm:ss')}
          </p>
          <p>
            <b>Created On: </b>
            {moment(taskDetails.created_on).format('yyyy-MM-DD HH:mm:ss')}
          </p>
          <p>
            <b>Assigned To: </b>
            {taskDetails?.assigned_to != undefined
              ? taskDetails?.assigned_to[0]?.user_details?.first_name +
                ' ' +
                taskDetails?.assigned_to[0]?.user_details?.last_name
              : 'None'}
          </p>
          <p>
            <b>Created By: </b>
            {taskDetails?.created_by?.user_details?.first_name +
              ' ' +
              taskDetails?.created_by?.user_details?.last_name}
          </p>
        </div>
      </Modal>
    </>
  );
}

export default ContactsTasks;
