import {
  DeleteOutlined,
  EditOutlined,
} from '@ant-design/icons';
import { Table, Tabs } from 'antd';
import { React, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  deleteCampaign,
  getCampaigns,
  loading,
  responseMessage,
} from '../../redux/actions/Campaigns';

import moment from 'moment';
import './leads.css';
import { MainToolbar } from '../../components/ui-components/toolbar/mainToolbar/MainToolbar';

const { TabPane } = Tabs;

function EmailCampaignList(props) {
  const { campaignsData, refresh } = props;

  const [rows, setRows] = useState(10);

  const [draftCampaigns, setDraftCampaigns] = useState();
  const [scheduledCampaigns, setScheduledCampaigns] = useState();
  const [sentCampaigns, setSentCampaigns] = useState();

  const [dataSource, setDataSource] = useState();

  useEffect(() => {
    props.getCampaigns(`/api/campaigns/?limit=${rows}`, 0);
    props.loading();
  }, [rows]);

  useEffect(() => {
    if (campaignsData && campaignsData.draft_campaigns != undefined) {
      setDraftCampaigns(campaignsData.draft_campaigns.draft_campaigns);
    }
    if (campaignsData && campaignsData.scheduled_campaigns != undefined) {
      setScheduledCampaigns(
        campaignsData.scheduled_campaigns.scheduled_campaigns
      );
    }
    if (campaignsData && campaignsData.sent_campaigns != undefined) {
      setSentCampaigns(campaignsData.sent_campaigns.sent_campaigns);
      setDataSource(campaignsData.sent_campaigns.sent_campaigns);
    }
  }, [campaignsData]);

  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const routeToDetails = (id) => {
    props.history.push(`/home/campaigns/${id}/details`);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'dname',
      render: (name, row) => {
        return (
          <p onClick={() => routeToDetails(row.id)}>
            <span className="">{name}</span>
          </p>
        );
      },
    },
    {
      title: 'Contact Source',
      dataIndex: 'contacts_source',
      key: 'scontacts',
      render: (contacts_source) => {
        return (
          <p>
            {contacts_source?.user_details != undefined
              ? contacts_source['user_details']['first_name'] +
                ' ' +
                contacts_source['user_details']['last_name']
              : ''}
          </p>
        );
      },
    },
    {
      title: 'Scheduled Date Time',
      dataIndex: 'scheduled_date_time',
      key: 'dscheduleddatetime',
      render: (scheduled_date_time) => (
        <p>
          {scheduled_date_time != null
            ? moment(scheduled_date_time).format('YYYY-MM-DD hh:mm:ss')
            : ''}
        </p>
      ),
    },
    {
      title: 'Created On',
      dataIndex: 'created_on',
      key: 'dcreatedon',
      render: (created_on) => <p>{moment(created_on).format('YYYY-MM-DD')}</p>,
    },
    {
      title: 'Created By',
      dataIndex: 'created_by',
      key: 'screatedby',
      render: (created_by) => {
        return (
          <p>
            {created_by['user_details']['first_name'] +
              ' ' +
              created_by['user_details']['last_name']}
          </p>
        );
      },
    },
    {
      title: 'Actions',
      key: 'dactions',
      render: () => (
        <>
          <span className="edit">
            <EditOutlined />
          </span>
          <span className="delete">
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const tabChange = (e) => {
    if (e === 1) {
      setDataSource(draftCampaigns);
    } else if (e === 2) {
      setDataSource(scheduledCampaigns);
    } else {
      setDataSource(sentCampaigns);
    }
  };

  return (
    <>
      <MainToolbar
        offset={0}
        setOffset={() => {}}
        totalCount={0}
        rows={rows}
        updateRecordsPerPage={updateRecordsPerPage}
        module="campaigns"
        history={props.history}
        buttons={[
          { url: 'campaigns/new', label: 'Add Email Campaign'},
          { url: 'templates/list', label: 'Email Templates'},
        ]}
      />
      <div className="list-table">
        <Tabs type="card" defaultActiveKey="3" onChange={tabChange}>
          <TabPane tab="Draft" key="1"></TabPane>
          <TabPane tab="Scheduled" key="2"></TabPane>
          <TabPane tab="Sent" key="3"></TabPane>
        </Tabs>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          rowKey={(record) => record.id}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  const { isLoading, campaignsData, offset, refresh } = state.campaigns;
  return { isLoading, campaignsData, offset, refresh };
};

const mapDispatchToProps = {
  getCampaigns,
  loading,
  deleteCampaign,
  responseMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailCampaignList);
