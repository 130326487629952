import {
    GET_EVENTS,
    SET_EVENTS_DATA,
    LOADING,
    ADD_EVENT,
    RESPONSE_MESSAGE,
    UPDATE_ERRORS,
    DELETE_EVENT,
    REFRESH,
    EVENT_DETAILS,
    UPDATE_EVENT_DETAILS,
    EVENT_UPDATED,
    EVENT_DELETED
  } from '../constants/Events';
  
  export const getEvents = (url, offset, bool) => {
    return {
      type: GET_EVENTS,
      payload: {
        url,
        offset,
        bool,
      },
    };
  };
  
  export const setEventsData = (data) => {
    return {
      type: SET_EVENTS_DATA,
      payload: data,
    };
  };
  
  export const loading = (bool) => {
    return {
      type: LOADING,
      payload: bool,
    };
  };
  
  export const addEvent = (data) => {
    return {
      type: ADD_EVENT,
      payload: data,
    };
  };
  
  export const responseMessage = (msg) => {
    return {
      type: RESPONSE_MESSAGE,
      payload: msg,
    };
  };
  
  export const updateErrors = (error) => {
    return {
      type: UPDATE_ERRORS,
      payload: error,
    };
  };
  
  export const deleteEvent = (id, bool) => {
    return {
      type: DELETE_EVENT,
      payload: {
        id,
        bool,
      },
    };
  };
  
  export const refresh = (bool) => {
    return {
      type: REFRESH,
      payload: bool,
    };
  };
  
  export const getEventDetails = (url) => {
    return {
      type: EVENT_DETAILS,
      url,
    };
  };
  
  export const updateEventDetails = (data) => {
    return {
      type: UPDATE_EVENT_DETAILS,
      data,
    };
  };
  
  export const isEventUpdated = (val) => {
    return {
      type: EVENT_UPDATED,
      val
    };
  };

  export const isEventDeleted = (val) => {
    return {
      type: EVENT_DELETED,
      val
    };
  };