import { useState, useEffect } from 'react';
import { Tabs, Avatar, Table } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  FilterOutlined,
  RightOutlined,
  LeftOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import { getData } from '../../redux/actions/Fetch';
import { Button } from 'antd';
import { deleteAllUsers, isLoading } from '../../redux/actions/Users';

import { getProfiles } from '../../redux/actions/Profiles';
import { getUsersList } from '../../redux/actions/Users';
import RowCounter from '../../components/ui-components/pagination/rowcounter/RowCounter';
import { MainToolbar } from '../../components/ui-components/toolbar/mainToolbar/MainToolbar';

const { TabPane } = Tabs;

export const UsersList = (props) => {
  let { users, loading } = props;

  const [activeUsers, setActiveUsers] = useState();
  const [inactiveUsers, setInactiveUsers] = useState();

  const [rows, setRows] = useState(10);
  const [activeTab, setActiveTab] = useState(true);
  const [activePages, setActivePages] = useState(0);
  const [inactivePages, setInactivePages] = useState(0);

  const [checkedIds, setCheckedIds] = useState([]);
  const [isLoaded, setIsLoaded] = useState(loading);

  const [checkActiveUsers, setCheckActiveUsers] = useState(false);
  const [checkInactiveUsers, setCheckInactiveUsers] = useState(false);

  useEffect(() => {
    props.getProfiles();
  }, []);

  useEffect(() => {
    props.getUsersList(`/api/users/?limit=${rows}`);
    // props.getData(`/api/users/?limit=${rows}`, 'users');
    props.isLoading();
  }, [rows]);

  useEffect(() => {
    updateUsersData();
  }, [loading, isLoaded]);

  const updateUsersData = () => {
    let activeUsers =
      users &&
      users.data.active_users.active_users.map((user) => {
        return {
          id: user.id,
          profilePic: user.profile_pic,
          userName: user.username,
          email: user?.user_details?.email,
          name: `${user?.user_details?.first_name} ${user?.user_details?.last_name}`,
          role: user.role,
          isAdmin: user.is_admin,
          isActive: user.is_active,
        };
      });
    let inactiveUsers =
      users &&
      users.data.inactive_users.inactive_users.map((user) => {
        return {
          id: user.id,
          profilePic: user.profile_pic,
          name: `${user?.user_details?.first_name} ${user?.user_details?.last_name}`,
          email: user?.user_details?.email,
          role: user.role,
          is_admin: user.is_admin,
          isActive: user.is_active,
        };
      });
    setActiveUsers(activeUsers);
    setInactiveUsers(inactiveUsers);
  };

  useEffect(() => {
    setActivePages(users && users.data.active_users.active_users_count);
    setInactivePages(users && users.data.inactive_users.inactive_users_count);
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (user, row) => {
        return (
          <p
            onClick={() => routeToDetails(row.id)}
            style={{ cursor: 'pointer' }}
          >
            <Avatar className="" src={row.profile_pic} /> &nbsp;
            <span className="">{user}</span>
          </p>
        );
      },
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      render: (email) => <p>{email}</p>,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      render: (role) => <p>{role}</p>,
    },
    {
      title: 'Actions',
      render: (user, row) => (
        <>
          <span
            className="edit"
            onClick={() => routeToDetails(row.id)}
            style={{ cursor: 'pointer' }}
          >
            <EditOutlined />
          </span>
          <span className="delete" style={{ marginLeft: '10px' }}>
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const tabChange = (e) => {
    if (e == 1) {
      setActiveTab(true);
    } else if (e == 2) {
      setActiveTab(false);
    }
  };

  const getCheckedId = (e, id) => {
    if (checkedIds) {
      if (e.target.checked && !checkedIds.includes(id)) {
        setCheckedIds([...checkedIds, id]);
      } else {
        let res = checkedIds.filter((checkedId) => checkedId !== id);
        setCheckedIds(res);
      }
    }
  };

  const deleteUsers = () => {
    props.deleteAllUsers(JSON.stringify(checkedIds));
  };

  const selectAllUsers = (e, val) => {
    if (e.target.checked) {
      if (val === 'active') {
        setCheckActiveUsers(!checkActiveUsers);
        setCheckedIds(activeUsers.map((user) => user.id));
      } else {
        setCheckInactiveUsers(!checkInactiveUsers);
        setCheckedIds(inactiveUsers.map((user) => user.id));
      }
    } else {
      if (val === 'active') {
        setCheckActiveUsers(!checkActiveUsers);
      } else {
        setCheckInactiveUsers(!checkInactiveUsers);
      }
    }
  };
  const routeToDetails = (id) => {
    props.history.push(`/home/users/${id}/edit`);
  };

  useEffect(() => {
    props.getData(`/api/users/?limit=${rows}`, 'users');
    setIsLoaded(!isLoaded);
  }, [props.areUsersDeleted]);
  return (
    <>
      <MainToolbar
        offset={0}
        setOffset={() => {}}
        totalCount={
          activeTab
            ? Math.ceil(activePages / rows)
            : Math.ceil(inactivePages / rows)
        }
        rows={rows}
        updateRecordsPerPage={updateRecordsPerPage}
        module="users"
        history={props.history}
        buttons={[{ url: 'users/new', label: 'Add User'}]}
      />
      <div className="list-table">
        <Tabs onChange={tabChange} type="card" defaultActiveKey="1">
          <TabPane tab="Active" key="1" />
          <TabPane tab="Inactive" key="2"></TabPane>
        </Tabs>
        <Table
          columns={columns}
          dataSource={activeTab ? activeUsers : inactiveUsers}
          pagination={false}
        />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { users, loading, areUsersDeleted } = state.users;
  const { profiles } = state.profiles;
  return { users, loading, areUsersDeleted, profiles };
};

const mapDispatchToProps = {
  getData,
  isLoading,
  deleteAllUsers,
  getProfiles,
  getUsersList,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersList);
