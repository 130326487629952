
export const ADD_TEMPLATE = 'ADD_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

export const GET_TEMPLATE_SERVER = 'GET_TEMPLATE_SERVER';
export const SET_CAMPAIGN_TEMPLATES_STORE = 'SET_CAMPAIGN_TEMPLATES_STORE';

export const ADD_CAMPAIGN_TEMPLATE_SERVER = 'ADD_CAMPAIGN_TEMPLATE_SERVER';
export const UPDATE_CAMPAIGN_TEMPLATE_SERVER = 'UPDATE_CAMPAIGN_TEMPLATE_SERVER';
