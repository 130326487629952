export const LOGIN_CREDENTIALS = 'LOGIN_CREDENTIALS';
export const UPDATE_ERRORS = 'UPDATE_ERRORS';
export const TOKEN = 'TOKEN';
export const REGISTRATION_DETAILS = 'REGISTRATION_DETAILS';
export const ALERT_MESSAGE = 'ALERT_MESSAGE';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const ROLES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
};
