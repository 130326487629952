import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Button, Table } from 'antd';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getInvestorsList,
  isLoading,
  deleteInvestor,
} from '../../redux/actions/Investors';
import RowCounter from '../../components/ui-components/pagination/rowcounter/RowCounter';
import { PageCounter } from '../../components/ui-components/pagination/pagecounter/PageCounter';
import FilterToolbar from '../../components/ui-components/toolbar/filterToolbar/FilterToolbar';
import { getInvestorTypeLabel } from './util';
import { MainToolbar } from '../../components/ui-components/toolbar/mainToolbar/MainToolbar';

export const InvestorsList = (props) => {
  const { investors, loading, responseMessage } = props;
  const [rows, setRows] = useState(10);
  const [offset, setOffset] = useState(0);

  const [qParam, setQParam] = useState();
  const [displayFilters, setDisplayFilters] = useState(false);
  const updateRecordsPerPage = (e) => {
    setRows(e);
  };

  const deleteInvestor = (id) => {
    props.deleteInvestor(id);
  };

  useEffect(() => {
    props.getInvestorsList(rows, offset, qParam);
  }, [loading, rows, offset, qParam]);

  const routeToDetails = (id) => {
    props.history.push(`/home/investors/${id}/details`);
  };

  useEffect(() => {
    if (responseMessage === true) {
      props.getInvestorsList(rows, offset);
    }
  }, [responseMessage]);

  const filterColumn = [
    { label: 'Investor PbId', name: 'pbid', type: 'text' },
    { label: 'Investor', name: 'investor', type: 'text' },
    { label: 'Contact Email', name: 'primary_contact_email', type: 'text' },
    { label: 'Contact No', name: 'primary_contact_phone', type: 'text' },
    {
      label: 'Investor Type',
      name: 'investor_type',
      type: 'dropdown',
      options: [
        { label: getInvestorTypeLabel('RETAIL'), value: 'RETAIL' },
        {
          label: getInvestorTypeLabel('INSTITUTIONAL'),
          value: 'INSTITUTIONAL',
        },
      ],
    },
  ];
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      render: (id) => <p onClick={() => routeToDetails(id)}>{id}</p>,
    },
    {
      title: 'Investor PbId',
      dataIndex: 'pbid',
      key: 'pbid',
      render: (pbid) => <p>{pbid}</p>,
    },
    {
      title: 'Investor',
      dataIndex: 'investor',
      key: 'investor',
      render: (investors) => <p>{investors}</p>,
    },
    {
      title: 'Primary Contact Email',
      dataIndex: 'primary_contact_email',
      key: 'primary_contact_email',
      render: (primary_contact_email) => <p>{primary_contact_email}</p>,
    },
    {
      title: 'Primary Contact No',
      dataIndex: 'primary_contact_phone',
      key: 'primary_contact_phone',
      render: (primary_contact_phone) => <p>{primary_contact_phone}</p>,
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      render: (website) => <p>{website}</p>,
    },
    {
      title: 'Investor Type',
      dataIndex: 'investor_type',
      key: 'investor_type',
      render: (investor_type) => <p>{getInvestorTypeLabel(investor_type)}</p>,
    },
    {
      title: 'Actions',
      key: 'action',
      render: (investor, row) => (
        <>
          <span
            className="edit"
            style={{ cursor: 'pointer' }}
            onClick={() => routeToDetails(row.id)}
          >
            <EditOutlined />
          </span>
          <span
            className="delete"
            style={{ cursor: 'pointer', marginLeft: '10px' }}
            onClick={() => deleteInvestor(row.id)}
          >
            <DeleteOutlined />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      <MainToolbar
        setDisplayFilters={setDisplayFilters}
        displayFilters={displayFilters}
        offset={offset}
        setOffset={setOffset}
        totalCount={investors?.totalcount}
        rows={rows}
        updateRecordsPerPage={updateRecordsPerPage}
        module="investors"
        history={props.history}
        hasBulkUpload
        buttons={[
          {
            url: 'investors/instNew',
            label: 'Add Institutional Investor',
          },
          { url: 'investors/indNew', label: 'Add Retail Investor' },
        ]}
      />
      <FilterToolbar
        columns={filterColumn}
        isVisible={displayFilters}
        onSearch={(qParam) => {
          setQParam(qParam);
        }}
        onClear={() => {
          setQParam(undefined);
        }}
      />
      <Table
        rowKey={(investor) => investor.id}
        columns={columns}
        dataSource={investors.data}
        pagination={false}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  if (!state?.investors) {
    return { investors: {}, loading: true };
  }
  const {
    investors,
    investorsLoading: loading,
    responseMessage,
  } = state?.investors;
  return { investors, loading, responseMessage };
};

const mapDispatchToProps = { getInvestorsList, isLoading, deleteInvestor };

export default connect(mapStateToProps, mapDispatchToProps)(InvestorsList);
